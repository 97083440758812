import {
  faChild,
  faChildReaching,
  faEllipsis,
  faInfo,
  faX,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { User } from './OverviewUsersList'
import { ListOfActiveUsers } from './ListOfActiveUsers'
import { useTranslation } from 'react-i18next'
import { HelpScection } from './HelpSection'

export interface IProps {
  presentParticipants: {
    users: User[]
    header: string
  }[]
  eventTimeId?: string
  refreshDetail: () => void
  toggleMenu: (e: any) => void
  departments?: string[]
  isOpen?: boolean
  openHelpSection: boolean
  setOpenHelpSection: (ishelpSectionOpen: boolean) => void
}
export const CircleMenuReqResEvents: React.FC<IProps> = ({
  presentParticipants,
  eventTimeId,
  refreshDetail,
  toggleMenu,
  departments,
  isOpen,
  openHelpSection,
  setOpenHelpSection,
}) => {
  const [chooseMany, setChooseMany] = useState(false)
  const [actionOnActiveUsers, setActionOnActiveUsers] = useState<
    string | null
  >()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const toggleChooseMany = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setActionOnActiveUsers(event.currentTarget.getAttribute('data-action'))
    setChooseMany(!chooseMany)
  }

  return (
    <div
      className={`flex flex-col-reverse items-start justify-start p-4 w-full h-full`}
    >
      {!isOpen && (
        <div className="gap-2 flex flex-col-reverse items-center">
          <button
            onClick={toggleMenu}
            className="w-14 h-14 border border-blue-400 rounded-full bg-white shadow-lg flex items-center justify-center mx-2 transition ease-in-out duration-700 hover:scale-110"
          >
            <FontAwesomeIcon
              icon={faEllipsis}
              size="lg"
              className="text-blue-300"
            />
          </button>
        </div>
      )}
      {isOpen && (
        <div className="flex flex-col-reverse items-start justify-start  w-full gap-4 h-full">
          <button onClick={toggleMenu} className="flex items-center gap-2">
            <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
              <FontAwesomeIcon
                icon={faXmark}
                size="lg"
                className="font-semibold"
              />
            </div>
            <span className=" font-semibold">{t('general.close')}</span>
          </button>
          <button
            className="flex items-center gap-2 group"
            onClick={() => setOpenHelpSection(true)}
          >
            <div className="w-12 h-12 rounded-full  bg-white flex items-center justify-center ">
              <FontAwesomeIcon
                icon={faInfo}
                size="lg"
                className="text-[#FFDC01] group-hover:animate-ping"
              />
            </div>
            <span className="text-[#FFDC01] font-semibold">
              {t('events.helpSection')}
            </span>
          </button>

          {presentParticipants?.map((dep) => dep.users).flat().length > 0 && (
            <>
              <button
                className="flex items-center gap-2 cursor-pointer group"
                onClick={toggleChooseMany}
              >
                <div className="w-12 h-12 rounded-full  bg-white flex items-center justify-center ">
                  <FontAwesomeIcon
                    icon={faChildReaching}
                    size="lg"
                    className="text-[#6BBD73] group-hover:animate-fadeIn"
                  />
                </div>
                <span className="text-[#6BBD73] font-semibold">
                  {t('events.setAsPresent')}
                </span>
              </button>
              <button
                className="flex items-center gap-2 cursor-pointer group"
                onClick={toggleChooseMany}
                data-action="leaving-activity"
              >
                <div className="w-12 h-12 rounded-full  bg-white flex items-center justify-center ">
                  <FontAwesomeIcon
                    icon={faChild}
                    size="lg"
                    className="text-[#FF8E8D] group-hover:animate-bounce"
                  />
                </div>
                <span className="text-[#FF8E8D] font-semibold">
                  {t('events.leavingActivity')}
                </span>
              </button>
            </>
          )}
          {chooseMany && (
            <ListOfActiveUsers
              presentKids={presentParticipants}
              onClose={() => setChooseMany(false)}
              refreshDetail={() => refreshDetail()}
              eventTimeId={eventTimeId}
              dataAction={actionOnActiveUsers}
              departmentIds={departments}
              toggleMenu={toggleMenu}
            />
          )}
          {
            <HelpScection
              openHelpSection={openHelpSection}
              setOpenHelpSection={setOpenHelpSection}
            />
          }
        </div>
      )}
    </div>
  )
}
