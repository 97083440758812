import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { EventListItemData } from '../Events3'
import { EventDetailPage } from '../EventDetailPage'
import { EVENT3_TYPE, Event3StoreContext } from '../../../store/event3Store'
import { LoadingBar } from '../../../components/LoadingBar'
import { EventTagLabel } from './EventTagLabel'
import { Department } from '../../../Models/Department'
import { TagStoreContext } from '../../../store/tagStore'
import { useParams } from 'react-router-dom'

interface EventDetailCardProps {
  event: EventListItemData
  loading?: boolean
  onOpenSetting?: () => void
  triggerHasResponded?: () => void
  openSetting?: boolean
  isExpanded?: boolean
  onClick?: () => void
}

export const getImageFromUrl = (imageUrl: string | undefined) => {
  if (imageUrl) {
    return imageUrl
  }
  return process.env.PUBLIC_URL + '/images/placeholder-image.png'
}

export const EventDetailCard: React.FC<EventDetailCardProps> = ({ event }) => {
  const { t } = useTranslation()
  const isFirstRender = useRef(true)
  const [isExpanded, setIsExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleCardClick = () => {
    if (!isExpanded) {
      setLoading(true)
      fetchEventDetail().finally(() => {
        setLoading(false)
      })
    }
    setIsExpanded(!isExpanded) // Toggle expansion on click
  }
  const { getDetailedEvent } = useContext(Event3StoreContext)
  const [fetchedEventDetail, setFetchedEventDetail] =
    useState<EventListItemData>()

  const [hasResponded, setHasResponded] = useState(false)

  const fetchEventDetail = useCallback(async () => {
    let isMounted = true
    try {
      const eventDetail = await getDetailedEvent(event.eventTimeId)
      if (isMounted) {
        setFetchedEventDetail(eventDetail)
      }
    } catch (error) {
      console.error('Error fetching event detail: ', error)
    }

    return () => {
      isMounted = false
    }
  }, [event.eventTimeId])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    fetchEventDetail()
  }, [hasResponded, fetchEventDetail])

  const triggerHasResponded = () => {
    setHasResponded((prev) => !prev)
  }

  return (
    <div
      className={`w-full mb-3 flex rounded-2xl overflow-y-auto  cursor-pointer bg-white ${
        !isExpanded || (loading && 'cursor-pointer')
      } ${event.type === 'important' ? 'bg-[#FFE6E6]' : ''} ${
        isExpanded && !loading && 'h-[400px]'
      } `}
      //onClick={handleCardClick}
    >
      {!isExpanded || loading ? (
        <EventExpendableCardHeader
          event={event}
          loading={loading}
          triggerHasResponded={triggerHasResponded}
          onClick={() => handleCardClick()}
        />
      ) : (
        <EventDetailPage
          eventTimeId={event.eventTimeId}
          fetchedEventDetail={fetchedEventDetail}
          triggerHasResponded={triggerHasResponded}
          hasResponded={hasResponded}
          isExpanded={isExpanded}
          handleExpaneded={handleCardClick}
          setFetchedEventDetail={(value: EventListItemData) =>
            setFetchedEventDetail(value)
          }
        />
      )}
    </div>
  )
}

export const EventExpendableCardHeader: React.FC<EventDetailCardProps> = ({
  event,
  loading,
  onOpenSetting,
  openSetting,
  isExpanded,
  onClick,
}) => {
  const { t } = useTranslation()
  const eventTypeColor = (type: string) => {
    switch (type) {
      case 'activities':
        return 'border-greenForrest'
      case 'meeting':
        return 'border-groupsMain'
      case 'info':
        return 'border-eventsMain'
      case 'important':
        return 'border-absenceMain'
      case 'booking':
        return 'border-purpleLight'
      default:
        return 'border-[#57d945]'
    }
  }
  const { allTagsForEvent } = useContext(TagStoreContext)
  const { schoolId } = useParams()

  const eventDate = moment(event?.eventDate)
  const eventDay = eventDate.format('DD')
  const eventMoment = eventDate.format('MM')

  const tagLable = event.tags
    ?.map(
      (eventTag) =>
        allTagsForEvent?.find((tag) => tag.name === eventTag.name)?.name,
    )
    .filter(Boolean)

  return (
    <div
      className="flex flex-col w-full p-3"
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      <div className="flex w-full">
        <div
          className={`w-1/12 flex flex-col justify-around py-1 items-center text-xs`}
        >
          <div className="font-semibold text-black text-xs flex flex-col gap-y-0.25">
            <div className="font-normal">{eventDay + '/' + eventMoment}</div>
            {isExpanded ? (
              <>
                <div>
                  {event.times.length && event.times[0].start_time
                    ? `${event.times[0].start_time}`
                    : ''}
                </div>
                <div>
                  {event.times.length && event.times[0].end_time
                    ? `${event.times[0].end_time}`
                    : ''}
                </div>
              </>
            ) : (
              <>
                <div>{event.endTime ? `${event.times[0].start}` : ''}</div>
                <div>{event.endTime ? `${event.times[0].end}` : ''}</div>
              </>
            )}
          </div>
          {event.isFileAttached && (
            <FontAwesomeIcon icon={faPaperclip} className="ml-2" />
          )}
        </div>
        <div
          className={`w-11/12  border-l-4 ${eventTypeColor(
            event.type,
          )} pl-2 flex justify-center flex-col `}
        >
          <div className="flex items-center w-full justify-end">
            <div className="pt-2 h-full">
              {event.type === EVENT3_TYPE.BOOKING && (
                <div className="w-10 h-10">
                  <img
                    src={getImageFromUrl(event.kidImageUrl)}
                    className="w-10 h-10  rounded-full"
                  />
                </div>
              )}
            </div>
            <div
              className={`flex w-full items-center justify-center ${
                !event.content ? 'py-1' : ''
              }`}
            >
              {!isExpanded && !event.isRead && (
                <div className="w-3 h-3 bg-[#7eb5f4] rounded-full mr-2"></div>
              )}
              <div
                className={`flex font-semibold pl-2 w-full justify-between items-center ${
                  !event.content ? 'flex justify-center' : ''
                }`}
              >
                {event.type === EVENT3_TYPE.BOOKING ? (
                  <div>
                    <div className="text-xs font-light">
                      {event.tags && event.tags.length ? (
                        <EventTagLabel
                          thinText
                          color={event.tags[0].color}
                          label={event.tags[0].name ?? ''}
                        />
                      ) : (
                        event.topic
                      )}
                    </div>
                    <h3>{event.kidFullName ?? event.kidName}</h3>
                    {event.eventResponsible ? (
                      <div className="mt-2">
                        <div className="text-xs font-semibold">
                          {t('events.responsible')}:
                        </div>
                        <div className="text-xs font-light">
                          {event.eventResponsible
                            ?.map((eventResp) => {
                              return eventResp.title
                            })
                            .join(', ')}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <>{console.log(event.tags)}</>
                    <div className="text-xs font-light">
                      {event.tags && event.tags.length ? (
                        <EventTagLabel
                          thinText
                          color={event.tags[0].color}
                          label={(tagLable && tagLable[0]) ?? ''}
                        />
                      ) : null}
                    </div>
                    <h3>{event.topic}</h3>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center whitespace-nowrap">
              <span className="flex justify-end font-normal items-center  w-[80px]">
                {event.onlyStaff && (
                  <div className="text-blueDark flex justify-start w-full font-semibold">
                    {t('documents.staff')}
                  </div>
                )}
                {/* This will be used later dont remove */}
                {(process.env.REACT_APP_PUBLIC_URL?.includes('localhost') ||
                  process.env.REACT_APP_PUBLIC_URL?.includes('alfa')) &&
                  isExpanded && (
                    <div
                      className={`flex items-center justify-center mx-2 rounded-full w-[25px] h-[25px] hover:bg-gray-200 animate-fadeIn ${
                        openSetting && ' bg-gray-200 '
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={faEllipsisV}
                        size="lg"
                        className={`text-gray-400 cursor-pointer px-4`}
                        onClick={(e) => {
                          e.stopPropagation()
                          if (onOpenSetting) onOpenSetting()
                        }}
                      />
                    </div>
                  )}
              </span>
            </div>
          </div>

          {event.bookingStatus ? (
            <p className="text-gray-700 font-bold pt-2 pl-3 animate-pulse duration-700">
              {event.bookingStatus === 'not_replied'
                ? t('events.notReplied')
                : event.bookingStatus === 'not_attending'
                ? t('events.notComing')
                : event.bookingStatus === 'attending'
                ? t('events.attending')
                : event.bookingStatus}
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/*<div className="px-2 overflow-hidden whitespace-nowrap w-[95%]">
            {event.content}
              </div>*/}
      {loading && (
        <div className="w-full mt-2">
          <LoadingBar speed={2} />
        </div>
      )}
    </div>
  )
}
