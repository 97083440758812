import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  BookingOverviewObject,
  BookingOverviewObjectType,
  EVENT3_TYPE,
  Event3StoreContext,
  event3Store,
} from '../../../store/event3Store'
import moment from 'moment'
import { CalendarDateIcon } from '../../../components/CalendarDateIcon'
import { Button } from '../../../components/Button'
import { getDaysFullForTranslatation } from '../../events/components/EventsInfo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSplit } from '@fortawesome/pro-solid-svg-icons'
import { getDateForTranslatation } from '../CreateEvent3'
import ModalRegular from '../../../components/ModalRegular'
import { CommentDetailCard, IEventComment } from './CommentDetailCard'
import { CommentInput } from './CommentInput'

interface Props {
  object: BookingOverviewObject
  onBook?: () => void
  onRemind?: () => void
  reminderSent?: boolean
  separateBooking?: boolean
}

export const KidBookingCard: React.FC<Props> = ({
  object,
  onBook,
  onRemind,
  reminderSent,
  separateBooking,
}) => {
  const { t } = useTranslation()
  const [comentsAllowed, setCommentsAllowed] = useState<boolean>(false)
  const [eventDetail, setEventDetail] = useState<any>()
  const { getDetailedEvent } = useContext(Event3StoreContext)
  const getImage = () => {
    if (object.imageUrl) {
      return object.imageUrl
    }
    return process.env.PUBLIC_URL + '/images/placeholder-image.png'
  }
  useEffect(() => {
    if (object.type === BookingOverviewObjectType.HAS_MESSAGE) {
      const fetchedDetail = async () => {
        const result = await getDetailedEvent(object.eventTimeId)
        setEventDetail(result)
      }
      fetchedDetail()
    }
  }, [object])

  const commentsDate = `${object.startTime} - ${
    object.endTime
  }, ${getDaysFullForTranslatation(moment(object.date).day())} ${moment(
    object.date,
  ).format('DD/MM YYYY')}`

  if (object.type === BookingOverviewObjectType.BOOKED) {
    return (
      <div className="flex justify-between w-full border border-gray-300 rounded-lg p-2">
        <div className="flex items-center gap-x-2">
          <img src={getImage()} className="w-[40px] h-[40px] rounded-full" />
          <div className="flex flex-col items-center justify-start text-left w-full">
            <div className="font-bold text-xs w-full">
              {separateBooking && (
                <>
                  <FontAwesomeIcon
                    className="text-purpleChildren"
                    icon={faSplit}
                    rotation={270}
                  />{' '}
                </>
              )}
              {object.name}
            </div>
            <div className="text-2xs w-full">{object.departmentName}</div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-start text-left">
          <div className="text-2xs w-full">
            {moment(object.date).format('DD/MM')}
          </div>
          <div className="font-bold text-2xs w-full">{object.startTime}</div>
          <div className="font-bold text-2xs w-full">{object.endTime}</div>
        </div>
      </div>
    )
  }
  if (object.type === BookingOverviewObjectType.FREE) {
    return (
      <div className="grid grid-cols-3 w-full border border-gray-300 rounded-lg p-2">
        <div className="flex items-center gap-x-2">
          <CalendarDateIcon date={object.date!} />
          <div className="flex flex-col items-center justify-start text-left w-full">
            <div className="font-bold text-xs w-full">
              {separateBooking && (
                <>
                  <FontAwesomeIcon
                    className="text-purpleChildren"
                    icon={faSplit}
                    rotation={270}
                  />{' '}
                </>
              )}
              {object.name}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center text-center">
          <div className="font-semibold text-sm w-full">
            {object.startTime} - {object.endTime}
          </div>
        </div>
        <div className="flex flex-col items-end justify-center text-right">
          <Button
            label={t('events.book')}
            variant="fullBlue"
            size="xs"
            onClick={() => {
              if (onBook) {
                onBook()
              }
            }}
          />
        </div>
      </div>
    )
  }
  if (object.type === BookingOverviewObjectType.NOT_BOOKED) {
    return (
      <div className="flex justify-between w-full border border-gray-300 rounded-lg p-2">
        <div className="flex items-center gap-x-2">
          <img src={getImage()} className="w-[40px] h-[40px] rounded-full" />
          <div className="flex flex-col items-center justify-start text-left w-full">
            <div className="font-bold text-xs w-full">
              {separateBooking && (
                <>
                  <FontAwesomeIcon
                    className="text-purpleChildren"
                    icon={faSplit}
                    rotation={270}
                  />{' '}
                </>
              )}
              {object.name}
            </div>
            <div className="text-2xs w-full">{object.departmentName}</div>
          </div>
        </div>
        <div className="flex gap-2 items-center justify-center text-right">
          <Button
            label={reminderSent ? '!!Skickat' : t('events.remind')}
            onClick={() => {
              if (onRemind && !reminderSent) {
                onRemind()
              }
            }}
            variant="lightPurple"
            size="xs"
          />
          <Button
            label={t('events.book')}
            size="xs"
            variant="fullBlue"
            onClick={() => {
              if (onBook) {
                onBook()
              }
            }}
          />
        </div>
      </div>
    )
  }
  if (object.type === BookingOverviewObjectType.HAS_MESSAGE) {
    return (
      <div
        className="flex flex-col justify-between w-full border border-gray-300 rounded-lg p-2 cursor-pointer"
        onClick={() => {
          setCommentsAllowed(true)
        }}
      >
        <div className="flex w-full items-center gap-x-2">
          <img src={getImage()} className="w-[40px] h-[40px] rounded-full" />
          <div className="flex flex-col items-center justify-start text-left w-full">
            <div className="font-bold text-xs w-full">
              {separateBooking && (
                <>
                  <FontAwesomeIcon
                    className="text-purpleChildren"
                    icon={faSplit}
                    rotation={270}
                  />{' '}
                </>
              )}
              {object.name}
            </div>
            <div className="text-2xs w-full">{object.departmentName}</div>
          </div>
        </div>
        <div className="flex w-full items-center text-xs mt-3">
          {object.message}
        </div>
        <div className="flex w-full items-center text-2xs mt-3 text-right justify-end text-blueDark font-semibold">
          {object.startTime}-{object.endTime},{' '}
          {getDaysFullForTranslatation(moment(object.date).day())}{' '}
          {moment(object.date).format('DD/MM YYYY')}
        </div>
        <div className="flex w-full items-center text-2xs text-right justify-end font-semibold">
          {object.messageBy}, {moment(object.messageAt).format('DD')}{' '}
          {getDateForTranslatation(
            moment(object.messageAt).format('MM'),
          )?.toLowerCase()}
          . {moment(object.messageAt).format('YYYY')}
        </div>

        {eventDetail?.comments.length > 0 && (
          <ModalRegular
            hideCloseButton
            title={`${object.name}\n${commentsDate}`}
            isOpen={comentsAllowed}
            onClose={() => setCommentsAllowed(false)}
            classNameTitle="text-black whitespace-pre-line text-center flex flex-col h-14 justify-center items-center w-full p-4"
          >
            <div className="pt-4">
              {eventDetail?.comments.map((comment: IEventComment) => (
                <CommentDetailCard {...comment} key={comment.id} />
              ))}
            </div>
            {eventDetail?.allowComments === true && (
              <div
                className={`flex items-center justify-center  w-[90%] mx-auto h-14 sticky bottom-0 -mb-2 bg-gradient-to-t from-white/80`}
              >
                <CommentInput
                  eventTimeId={eventDetail.eventTimeId}
                  eventGroupInfoId={eventDetail.eventGroupInfoId}
                  refreshDetail={() => console.log('hellow')}
                />
              </div>
            )}
          </ModalRegular>
        )}
      </div>
    )
  }
  return <></>
}
