import {
  faPersonWalkingArrowLoopLeft,
  faPersonWalkingArrowRight,
  faTrash,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button } from '../../../components/Button'
import { observer } from 'mobx-react-lite'
import { User } from './OverviewUsersList'
import moment from 'moment'
import { ContactStoreContext } from '../../../store/contactStore'
import OverviewUserContactDetailsPopUp from './OverviewUserContactDetailsPopUp'
import { Event3StoreContext } from '../../../store/event3Store'
import ModalRegular from '../../../components/ModalRegular'

interface PopupProps {
  eventTimeId?: string
  user: User | undefined
  isUserInfoPopUpVisible: boolean
  onClose: () => void
  refreshDetail: () => void
}

const OverviewUserPopUp: React.FC<PopupProps> = observer(
  ({ eventTimeId, user, isUserInfoPopUpVisible, onClose, refreshDetail }) => {
    const popupRef = useRef<HTMLDivElement>(null)
    const { fetchContact, contact } = useContext(ContactStoreContext)
    const { sendReminder, isPresentOnEventTime, declineParticipatingInEvent } =
      useContext(Event3StoreContext)
    const [
      isUserContactDetailsPopUpVisible,
      setUserContactDetailsPopUpVisible,
    ] = useState<boolean>(false)

    const handleClosePopup = () => {
      setUserContactDetailsPopUpVisible(false)
    }

    const handleClickOutside = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      event.stopPropagation()
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose()
      }
    }

    const readAt = moment(user?.readAt).format('YYYY-MM-DD')

    useEffect(() => {
      const fetchContactDetails = async () => {
        if (user?.userRoleId) await fetchContact(user?.userRoleId)
      }
      fetchContactDetails()
    }, [user])

    const lastItemRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      if (lastItemRef.current) {
        lastItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    }, [user?.kids])

    return (
      <>
        <div onClick={(e) => e.stopPropagation()}>
          <ModalRegular
            isOpen={isUserInfoPopUpVisible!!}
            /* bgColor="bg-eventsMain p-4" */
            closeColor="white"
            className={'h-[400px] !w-[450px] px-4 !overflow-hidden'}
            classNameTitle="mb-4 w-full text-black !important flex justify-center font-semibold"
            onClose={onClose}
          >
            <div className="flex flex-col p-4 h-full overflow-y-auto">
              <div className="flex items-center border-b-2 py-2">
                <img
                  className={`w-10 h-10 rounded-full object-cover `}
                  src={user?.thumbnail || '/images/placeholder-image.png'}
                  alt=""
                />
                <div className="flex w-[90%] items-center justify-between">
                  <div className="flex flex-col font-bold p-2 text-sm">
                    <p className=" text-gray-900 ">{user?.name}</p>
                    {!!user?.kidId === false ? (
                      <p
                        className=" text-blue-600 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation()
                          setUserContactDetailsPopUpVisible(
                            !isUserContactDetailsPopUpVisible,
                          )
                        }}
                      >
                        See contact details
                      </p>
                    ) : (
                      <p className="text-gray-500">
                        {user?.departmentNames && user?.departmentNames[0]}
                      </p>
                    )}
                  </div>
                  {!!user?.kidId === false && (
                    <div>
                      {!user?.hasRead ? (
                        <p className="font-bold text-red-500 text-sm">Unread</p>
                      ) : (
                        <div className="flex flex-col font-bold text-gray-500 items-center text-sm">
                          <p>Read</p>
                          <p>{readAt}</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {!!user?.kidId === true && (
                <div className="flex justify-center items-center h-[80%]">
                  <div className=" flex flex-col m-4 gap-4">
                    <Button
                      size="sm"
                      fontSize="font-bold"
                      icon={
                        user?.isPresent === true
                          ? faPersonWalkingArrowLoopLeft
                          : faPersonWalkingArrowRight
                      }
                      variant={
                        user?.isPresent === false ||
                        user?.isPresent === undefined
                          ? 'fullGreen'
                          : 'fullRed'
                      }
                      label={
                        user?.isPresent === false ||
                        user?.isPresent === undefined
                          ? 'Present'
                          : 'Leaving'
                      }
                      onClick={(e) => {
                        e.stopPropagation()
                        try {
                          const presentStatus = !!!user?.isPresent
                          isPresentOnEventTime(
                            eventTimeId,
                            [user?.id ?? ''],
                            presentStatus,
                          )
                          refreshDetail()
                          onClose()
                        } catch (error) {
                          console.error(error)
                        }
                      }}
                    />
                    {user?.isPresent === false ||
                    user?.isPresent === undefined ? (
                      <p className="text-gray-400 font-semibold">
                        !!Confirm present at the event
                      </p>
                    ) : (
                      <p className="text-gray-400 font-semibold">
                        !!Confirm leaving the event
                      </p>
                    )}
                    <Button
                      size="sm"
                      icon={faTrash}
                      variant="red"
                      fontSize="font-bold"
                      label="Remove"
                      onClick={(e) => {
                        e.stopPropagation()
                        try {
                          declineParticipatingInEvent(
                            eventTimeId,
                            [user?.id ?? ''],
                            [user?.kidId ?? ''],
                          )
                          refreshDetail()
                          onClose()
                        } catch (error) {
                          console.error(error)
                        }
                      }}
                    />
                  </div>
                </div>
              )}
              <div className=" h-full overflow-y-auto">
                {user?.kids?.map((child, index) => (
                  <div
                    className="flex items-center border-b-2 last:border-b-0 py-2"
                    ref={
                      index === user?.kids?.length!! - 1 ? lastItemRef : null
                    }
                  >
                    <img
                      className={`w-10 h-10 rounded-full object-cover `}
                      src={child?.thumbnail || '/images/placeholder-image.png'}
                      alt=""
                    />
                    <div className="flex flex-col items-start p-2 text-sm">
                      <p
                        key={child.kidId}
                        className="font-semibold text-gray-900 line-clamp-1"
                      >
                        {child.name}
                      </p>
                      <p key={index} className="font-semibold text-gray-500">
                        {child.departmentName}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {!!user?.kidId === false && (
              <div>
                {!user?.hasRead && (
                  <div className="flex justify-center">
                    <Button
                      className="w-[50%]"
                      size="sm"
                      fontSize="font-bold"
                      variant="borderMain"
                      label="Send remider"
                      onClick={(e) => {
                        e.stopPropagation()
                        sendReminder(eventTimeId, [user?.userId ?? ''])
                      }}
                    />
                  </div>
                )}{' '}
              </div>
            )}
            {
              <OverviewUserContactDetailsPopUp
                user={user}
                isUserContactDetailsPopUpVisible={
                  isUserContactDetailsPopUpVisible
                }
                contact={contact}
                onClose={handleClosePopup}
              />
            }
          </ModalRegular>
        </div>
      </>
    )
  },
)

export default OverviewUserPopUp
