import React, { useContext, useEffect, useState } from 'react'
import {
  faBars,
  faTimes,
  faCheckCircle,
  faArrowLeft,
  faHouse,
  faCog,
  faArrowUp,
  faArrowTurnUp,
  faReply,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useBoolean } from 'react-hanger'
import { useClickOutside } from '../hooks/useClickoutside'
import { AnimatePresence, motion } from 'framer-motion'
import { UserStoreContext } from '../store/userStore'
import { PortfolioBlogStoreContext } from '../store/portfolioBlogStore'
import { EventStoreContext } from '../store/eventStore'
import { Spinner } from '../components/Spinner'
import {
  getImageFromRole,
  getSubTitleFromRole,
  getTitleFromRole,
} from '../utils/parseUtils'
import { observer } from 'mobx-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { UserProfile } from './components/UserProfile'
import { DashboardButton } from './components/DashboardButton'
import { NotificationButton } from './components/AlertButton'
import { NavigationStoreContext } from '../store/navigationStore'
import { DocumentStoreContext } from '../store/documentStore'
import { KidsStoreContext } from '../store/kidsStore'
import { DepartmentStoreContext } from '../store/departmentStore'
import { USER_ROLE_STATUS, USER_ROLE_TYPE } from '../Enums/TyraEnums'
import { LanguageGlobe } from './components/LanguageGlobe'
import ScrollButton from '../components/ScrollButton'
import { t } from 'i18next'
import { UserRole } from '../Models/UserRole'
import { Notifcation } from './components/Notification'

export const screenHeightNavOffset = {
  height: `calc(100vh - 60px)`,
}

const RoleList: React.FC<{ onSelected: any }> = observer(({ onSelected }) => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const { userRoles, fetchUserRoles, savedUserRoleId, setCurrentUserRole } =
    useContext(UserStoreContext)

  const { setEvents } = useContext(EventStoreContext)
  const { setMainDocs, setDocs, setExternalLinks } =
    useContext(DocumentStoreContext)
  const { setBloggDocs, setPortfolioDocs } = useContext(
    PortfolioBlogStoreContext,
  )
  const { setKids, setUserRoles } = useContext(KidsStoreContext)
  const { setDepartments } = useContext(DepartmentStoreContext)

  const kids = userRoles
    ?.filter((role) => role?.attributes?.kid)
    .sort((a, b) => {
      return a?.attributes?.kid?.attributes.kidName.localeCompare(
        b?.attributes?.kid?.attributes.kidName,
      )
    })

  const others = userRoles
    ?.filter((role) => !role?.attributes?.kid)
    .sort((a, b) => {
      return getTitleFromRole(a).localeCompare(getTitleFromRole(b))
    })

  useEffect(() => {
    setLoading(true)
    fetchUserRoles().finally(() => {
      setLoading(false)
    })
  }, [])

  return loading ? (
    <div className={'flex w-full h-12 items-center justify-center'}>
      <Spinner />
    </div>
  ) : (
    <div className={'flex flex-col'}>
      {[...(others ?? []), ...(kids ?? [])]?.map((role, index) => {
        return (
          <div
            key={index}
            className={
              'flex w-full h-[60px] items-center border-b space-x-3 px-3 cursor-pointer'
            }
            onClick={() => {
              if (role.status !== USER_ROLE_STATUS.PAUSED) {
                setCurrentUserRole(role)
                // reset state in stores
                setEvents([])
                setBloggDocs([])
                setPortfolioDocs([])
                setMainDocs([])
                setExternalLinks([])
                setDocs([])
                setKids([])
                setUserRoles([])
                setDepartments([])
                navigate('/dashboard/' + role.id)
                onSelected()
              }
            }}
          >
            {role?.attributes?.role_type === USER_ROLE_TYPE.PARENT ? (
              <img
                src={getImageFromRole(role)}
                className="h-[40px] w-[40px] rounded-full object-cover"
                alt=""
              />
            ) : (
              <div className="h-[40px] w-[40px] bg-gray-200 rounded-full object-cover flex items-center justify-center">
                <p className={'text-gray-500'}>
                  {getTitleFromRole(role).slice(0, 2)?.toUpperCase()}
                </p>
              </div>
            )}
            <div className={'flex flex-col flex-1'}>
              <p className={'text-black text-sm'}>{getTitleFromRole(role)}</p>
              <p className={'text-gray-700 text-sm'}>
                {getSubTitleFromRole(role)}
              </p>
            </div>
            {savedUserRoleId === role?.id && (
              <FontAwesomeIcon
                className="text-xl text-eventsMain"
                icon={faCheckCircle}
              />
            )}
          </div>
        )
      })}
    </div>
  )
})

const NavTitle = observer(() => {
  const { title } = useContext(NavigationStoreContext)
  return (
    <p
      className={
        'flex-1 pl-3 md:pl-0 md:text-center text-sm md:text-lg font-bold '
      }
    >
      {title}
    </p>
  )
})

export const Navigation: React.FC<any> = ({ children }) => {
  const { currentUser } = useContext(UserStoreContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { userRoles } = useContext(UserStoreContext)
  const isOpen = useBoolean(false)
  const [menuRef, toggleRef] = useClickOutside(isOpen.setFalse)

  return (
    <div className={'z-50 '}>
      <nav className="w-full h-[60px] text-white bg-eventsMain relative">
        <div className="flex items-center w-full justify-between px-3 lg:px-6 text-lg h-full shadow-[0px_2px_12px_2px_rgba(0,0,0,0.10)]">
          {userRoles && userRoles?.length < 1 ? (
            <div className="flex space-x-3 md:space-x-6 md:flex-1 items-center">
              {' '}
              {currentUser?.attributes?.firstName + ' '}
              {currentUser?.attributes?.lastName}
            </div>
          ) : (
            <div
              className={'flex space-x-3 md:space-x-6 md:flex-1 items-center'}
            >
              <div
                onClick={isOpen.toggle}
                ref={toggleRef}
                aria-label="Open Menu"
                className=""
              >
                <FontAwesomeIcon
                  className="text-lg cursor-pointer hover:text-blue-50"
                  icon={faBars}
                />
              </div>
              {/* {!location?.pathname.includes('dashboard') && (
                <FontAwesomeIcon
                  className="text-lg cursor-pointer hover:text-blue-50"
                  icon={faArrowLeft}
                  onClick={() => {
                    navigate(-1)
                  }}
                />
              )} */}
              <FontAwesomeIcon
                className="text-lg cursor-pointer hover:text-blue-50"
                icon={faHouse}
                onClick={() => {
                  navigate('/')
                }}
              />
            </div>
          )}

          <NavTitle />

          <div
            className={
              'flex justify-end space-x-3 md:space-x-6 flex-1 items-center'
            }
          >
            <DashboardButton />
            {/* <FontAwesomeIcon
              className="text-lg cursor-pointer hover:text-blue-50"
              icon={faHouse}
              onClick={() => {
                navigate('/')
              }}
            /> */}
            {process.env.REACT_APP_PUBLIC_URL?.includes('localhost') ||
            process.env.REACT_APP_PUBLIC_URL?.includes('alfa') ? (
              <NotificationButton />
            ) : null}
            <UserProfile />
            <Notifcation />
            <LanguageGlobe></LanguageGlobe>
          </div>
        </div>

        <AnimatePresence>
          {isOpen.value && (
            <motion.div
              initial="closed"
              animate="open"
              exit="closed"
              variants={overlayVariants}
              className="z-50"
            >
              <div className="fixed inset-0 flex z-50">
                <motion.div
                  variants={overlayVariants}
                  className="fixed inset-0 "
                >
                  <div className="absolute inset-0 bg-gray-600 opacity-40 z-50"></div>
                </motion.div>
                <motion.div
                  className="relative flex flex-col flex-1 w-full max-w-[320px] transition duration-700 pl-10 -ml-10 bg-white"
                  ref={menuRef}
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={menuVariants}
                >
                  <div className={'flex flex-col'}>
                    <div
                      className={
                        'h-[60px] bg-eventsMain border-b flex justify-center items-center w-full'
                      }
                    >
                      <p className={'text-white font-medium'}>
                        <>{t('general.chooseUser')}</>
                      </p>
                    </div>

                    <div
                      className={
                        'flex items-center bg-eventsMain px-3 h-[80px]'
                      }
                    >
                      <img
                        src={currentUser?.attributes?.myPic_thumb?._url}
                        className="h-[55px] w-[55px] rounded-full object-cover"
                        alt=""
                      />
                      <div
                        className={
                          'h-[80px] bg-eventsMain flex justify-center pl-3 w-full flex-col'
                        }
                      >
                        <p className={'text-white text-sm '}>
                          {currentUser?.attributes?.firstName + ' '}
                          {currentUser?.attributes?.lastName}
                        </p>
                        <p className={'text-white text-sm'}>
                          {currentUser?.attributes?.username}
                        </p>
                      </div>
                    </div>
                    <div
                      className={
                        'overflow-y-auto h-[calc(100vh-140px)] overflow-hidden scrollbar-thin'
                      }
                    >
                      <RoleList
                        onSelected={() => {
                          isOpen.setFalse()
                        }}
                      />
                    </div>
                  </div>

                  <div className="absolute top-0 right-0 p-1 -mr-14">
                    <button
                      className="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:bg-gray-600"
                      aria-label="Close sidebar"
                      onClick={isOpen.setFalse}
                    >
                      <FontAwesomeIcon
                        className="text-white"
                        icon={faTimes}
                        size="lg"
                      />
                    </button>
                  </div>
                </motion.div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
      <div className="flex w-full ">
        <main
          className="flex-grow max-w-full overflow-y-auto scrollbar bg-gray-50"
          style={{ ...screenHeightNavOffset }}
        >
          {children}
        </main>
      </div>
    </div>
  )
}

const overlayVariants = {
  open: { opacity: 1 },
  closed: { opacity: 1 },
}

const menuVariants = {
  open: {
    x: '0',
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.05,
    },
  },
  closed: { x: '-100%' },
}

const menuVariants2 = {
  open: {
    x: '0',
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.05,
    },
  },
  closed: { x: '100vh' },
}

const navigationItemVariants = {
  open: { x: 0, opacity: 1 },
  closed: { x: '-20px', opacity: 0 },
}
