import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import CustomInput from '../../../components/CustomInput'
import { Button } from '../../../components/Button'
import {
  CreateEventParams,
  EVENT3_TYPE,
  EVENT3_USER_FOR,
} from '../../../store/event3Store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDown,
  faArrowUp,
  faLocationDot,
  faLocationPin,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { faFilePlus, faLocationPlus } from '@fortawesome/pro-solid-svg-icons'

export enum LOCATION_TYPE {
  FREE_TEXT = 'freetext',
  COORD = 'coord',
  ADDRESS = 'address',
  SAVED = 'saved',
}

interface Props {
  newEvent: CreateEventParams
  onChange: (e: CreateEventParams) => void
  onDone: () => void // hämta värdena och visa från riktig db
  onSave: () => void // spara värdena
  onClick: () => void
}

export const LocationToEvent: React.FC<Props> = observer((props: Props) => {
  const [openSelections, setOpenSelections] = useState(false)
  const { t } = useTranslation()
  return (
    <div
      onClick={() => {
        if (!props.newEvent.location) {
          setOpenSelections(!openSelections)
        }
      }}
      className="w-full"
    >
      <div className="flex justify-between gap-y-1">
        <div className="font-semibold flex items-center">
          {/*<>{t('events.addPlace')}</>*/}
          <FontAwesomeIcon
            icon={faLocationDot}
            className="text-[#6BBD73] text-xl p-1"
          />
          {t('events.place') + ': '}
        </div>
        {props.newEvent.location ? (
          <Button
            variant="fullRed"
            size="sm"
            icon={faTrashCan}
            onClick={() => {
              props.onChange({
                ...props.newEvent,
                location: undefined,
                locationType: LOCATION_TYPE.SAVED,
              })
            }}
          />
        ) : (
          <Button
            label={t('events.add')}
            variant="remindStyle"
            size="xs"
            onClick={() => props.onClick()}
          />
        )}
      </div>
      <div className="flex pl-2 mt-1">
        {props.newEvent.location ? (
          <div className="flex flex-row gap-x-2 items-center">
            {props.newEvent.location.name}{' '}
          </div>
        ) : (
          <div className="">{t('events.noPlaceChoosen')}</div>
        )}
      </div>
    </div>
  )
})
