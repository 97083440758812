import React from 'react'
import Parse from 'parse'
import { makeAutoObservable, toJS, values } from 'mobx'
import { Kid } from '../Models/Kid'
import { School } from '../Models/School'
import { UserRole } from '../Models/UserRole'
import { USER_ROLE_STATUS, USER_ROLE_TYPE } from '../Enums/TyraEnums'
import { User } from '../Models/User'
import { userStore } from './userStore'
import { string } from 'yup'
import { Department } from '../Models/Department'

class KidsStore {
  kids?: Kid[]
  userRoles?: UserRole[]
  search?: string
  school?: School
  guardians?: UserRole[]
  constructor() {
    makeAutoObservable(this)
  }

  setSearch = (search: string) => {
    this.search = search
  }

  setKids = (kids: Kid[]) => {
    this.kids = kids
  }
  setUserRoles = (userRoles: UserRole[]) => {
    this.userRoles = userRoles
  }
  setGuardians = (guardians: UserRole[]) => {
    this.guardians = guardians
  }

  fetchGuardiansForKids = async (kid: Kid, returnGuardians?: boolean) => {
    const userRoleQuery = new Parse.Query(UserRole)

    userRoleQuery
      //.equalTo('school_pointer', kid.school_pointer)
      .equalTo('kid', kid)
      .doesNotExist('archive_state')
      //.greaterThanOrEqualTo('status', USER_ROLE_STATUS.PAUSED)
      .include('user.user_extra')
      .include('alternative_contact')
      .include('person')

    const userRoles = await userRoleQuery.findAll()

    this.setGuardians(
      userRoles.filter(
        (uR) => uR.status > USER_ROLE_STATUS.PAUSED || !uR.status,
      ),
    )
    if (returnGuardians) {
      return userRoles.filter(
        (uR) => uR.status > USER_ROLE_STATUS.PAUSED || !uR.status,
      )
    }
  }

  fetchUserRoles = async (userRole: UserRole) => {
    const innerQuery = new Parse.Query(User)
    innerQuery.equalTo('user_setting', 10)
    const userRoleQuery = new Parse.Query(UserRole)
      .equalTo('school_pointer', userRole?.attributes?.school_pointer)
      .include('kid')
      .include('kid.kid_extra')
      .include('alternative_contact')
      .include('user')
      .include('person')
      .include('user.user_extra')
      .doesNotExist('archive_state')
      .exists('kid')
      //.equalTo('status', 40)
      .limit(10000)

    /*
    const userRoleQuery2 = new Parse.Query(UserRole)
      .equalTo('school_pointer', userRole?.attributes?.school_pointer)
      .include('kid')
      .include('kid.kid_extra')
      .include('alternative_contact')
      //.include('user')
      .select('user.firstName')
      .include('person')
      //.include('user.user_extra')
      .doesNotExist('archive_state')
      .exists('kid')
      //.equalTo('status', 40)
      .limit(10000)

    const hideContactInformation = new Parse.Query(User)
    hideContactInformation.lessThanOrEqualTo('user_setting', 20)
    hideContactInformation.select('firstName', 'lastName')
    userRoleQuery2.matchesQuery('user', hideContactInformation)

    const testing = await userRoleQuery2.find()

    console.log('TESTING', testing)*/

    let moreRoles: any[] = []
    let evenMoreRoles: any[] = []
    let kidsRelativesHiddenContactInfo: any[] = []
    if (userRole.attributes?.role_type === USER_ROLE_TYPE.PARENT) {
      userRoleQuery.greaterThan('status', 10)

      const below = new Parse.Query(User)
      below.lessThanOrEqualTo('user_setting', 10)
      const notExist = new Parse.Query(User)
      notExist.doesNotExist('user_setting')
      const innerQuery = Parse.Query.or(below, notExist)
      userRoleQuery.matchesQuery('user', innerQuery)

      kidsRelativesHiddenContactInfo = await Parse.Cloud.run(
        'kidsRelativesHiddenContactInfo',
        {
          schoolId: userRole?.attributes?.school_pointer.id,
        },
      )

      moreRoles = await new Parse.Query(UserRole)
        .equalTo('school_pointer', userRole?.attributes?.school_pointer)
        .doesNotExist('alternative_contact')
        .doesNotExist('user')
        .include('kid')
        //.include('kid.kid_extra')
        .include('alternative_contact')
        .doesNotExist('archive_state')
        .exists('kid')
        //.equalTo('status', 40)
        //.greaterThan('status', 10)
        .find()

      // Behöver denna för att VH ska få ut alternativa kontakter till sitt eget barn.
      evenMoreRoles = await new Parse.Query(UserRole)
        .equalTo('school_pointer', userRole?.attributes?.school_pointer)
        .equalTo('kid', userRole?.attributes?.kid)
        .doesNotExist('user')
        .include('kid')
        //.include('kid.kid_extra')
        .exists('alternative_contact')
        .include('alternative_contact')
        .doesNotExist('archive_state')
        .exists('kid')
        //.equalTo('status', 40)
        //.greaterThan('status', 10)
        .find()
    }
    const roles = await userRoleQuery.find()
    if (
      [...roles, ...moreRoles, ...evenMoreRoles].find(
        (uR) => uR.id === userRole.id,
      )
    ) {
      this.setUserRoles([
        ...roles,
        ...moreRoles,
        ...evenMoreRoles,
        ...kidsRelativesHiddenContactInfo,
      ])
    } else {
      // adding own userRole - to show all information about yourself, for yourself.
      this.setUserRoles([
        ...roles,
        ...moreRoles,
        ...evenMoreRoles,
        ...kidsRelativesHiddenContactInfo,
        ...[userRole],
      ])
    }
  }

  fetchKids = async (userRole: UserRole) => {
    if (userRole?.attributes?.role_type > USER_ROLE_TYPE.PARENT) {
      const kidsQuery = new Parse.Query(Kid)
        .equalTo('school_pointer', userRole?.attributes?.school_pointer)
        .include('kid_extra')
        .include('absenceLog')
        .include('person')
        .ascending('kidName')
        .include('placement')
        .limit(10000)

      if (!userRole?.attributes?.staff_sensetive_info_bool) {
        kidsQuery.exclude('kid_pers_id_num')
      }
      const kids = await kidsQuery.find()

      this.setKids(kids)
    } else {
      const kids = await Parse.Cloud.run('kidsGetStrippedData', {
        schoolId: userRole?.attributes?.school_pointer?.id,
        schoolName: userRole?.attributes?.school_pointer?.schoolName,
        kidIdsToExclude: [userRole?.attributes?.kid?.id],
      })

      this.setKids([userRole?.attributes?.kid, ...kids])
    }
  }

  /* saveKidTariff = async (
    kid: Kid,
    tariff: Number,
    fetchTheKidById?: string,
  ) => {
    if (tariff) {
      if (fetchTheKidById) {
        const kidToSave = await new Parse.Query(Kid).get(fetchTheKidById)
        kidToSave.set('tariff', tariff)
        await kidToSave.save()
      } else {
        kid.set('tariff', tariff)
        await kid.save()
      }
    }
  }*/

  saveKidTariff = async (
    kid: Kid,
    tariff: Number | null,
    fetchTheKidById?: string,
  ) => {
    if (tariff !== undefined && tariff !== null) {
      if (fetchTheKidById) {
        const kidToSave = await new Parse.Query(Kid).get(fetchTheKidById)
        kidToSave.set('tariff', tariff)
        await kidToSave.save()
      } else {
        kid.set('tariff', tariff)
        await kid.save()
      }
    } else {
      if (fetchTheKidById) {
        const kidToSave = await new Parse.Query(Kid).get(fetchTheKidById)
        kidToSave.set('tariff', null)
        await kidToSave.save()
      } else {
        kid.set('tariff', null)
        await kid.save()
      }
    }
  }

  saveKidInfo = async ({
    kid,
    allergies,
    other,
  }: {
    kid: Kid
    allergies?: string
    other?: string
  }) => {
    const currentUserRole = userStore.currentUserRole
    if (kid?.attributes?.kid_extra) {
      kid?.attributes?.kid_extra.set('allergies', allergies)
      kid?.attributes?.kid_extra.set('other_info', other)
      if (currentUserRole) {
        kid?.attributes?.kid_extra.set('userRoleId', currentUserRole.id)
      }

      await kid?.attributes?.kid_extra.save()
    } else {
      const kidExtra = new Parse.Object('kids_extra')
      kidExtra.set('allergies', allergies)
      kidExtra.set('other_info', other)
      kidExtra.set('kid_object_id', kid?.id)
      kidExtra.set('school_pointer', kid?.attributes?.school_pointer)
      kidExtra.set('school_name', kid?.attributes?.school)
      if (currentUserRole) {
        kidExtra.set('userRoleId', currentUserRole.id)
      }
      await kidExtra.save()
      kid.set('kid_extra', kidExtra)
      await kid.save()
    }
  }

  updateKidDepartment(kidId: string, schoolId: string, departmentId: string) {
    return Parse.Cloud.run('changeDepartmentForKids', {
      kidId,
      schoolId,
      departmentId,
    })
  }
  /*updateKidInfo = async ({
    kidId,
    kidName,
    kid_pers_id_num,
    firstName,
    lastName,
    birth_date,
    start_date,
  }: {
    kidId: string
    kidName: string
    kid_pers_id_num: string | undefined
    firstName: string
    lastName: string
    birth_date: Date | undefined
    start_date: string
  }) => {
    const Kid = Parse.Object.extend('kid')
    const query = new Parse.Query(Kid)

    try {
      const kidObject = await query.get(kidId)

      kidObject.set('kidName', kidName)
      kidObject.set('kid_pers_id_num', kid_pers_id_num)
      kidObject.set('firstName', firstName)
      kidObject.set('lastName', lastName)
      kidObject.set('birth_date', birth_date)
      kidObject.set('start_date', start_date)

      await kidObject.save()
    } catch (error) {
      console.error(error)
    }
  }*/
  editKidInfo = async (kid: Kid) => {
    await kid.save()
  }
  createNewKid = async (values: any, schoolId: string) => {
    const kid = await Parse.Cloud.run('createKid', {
      schoolId: schoolId,
      departmentId: values.department.id,
      start_date: values.start_date,
      addKidsExtra: true,
      kidName: values.kidName,
      firstName: values.firstName,
      lastName: values.lastName,
      nin: values.kid_pers_id_num,
      birth_date: values.birth_date,
    })
    if (kid) {
      return kid
    }
    /*console.log('hejhej')
    const createKid = new Kid()
    const currentUserRole = userStore.currentUserRole

    createKid.firstName = values.firstName
    createKid.lastName = values.lastName
    createKid.department = values.department
    createKid.kidName = values.kidName
    createKid.kid_pers_id_num = values.kid_pers_id_num
    createKid.birth_date = values.birth_date
    //createKid.kidImage = values.kidImage (*** readOnly ***)
    //createKid.kidImage_thumb = values.kidImage_thumb (*** readOnly ***)
    createKid.departmentPointer = values.departmentPointer
    createKid.start_date = values.start_date
    createKid.school_pointer = values.school_pointer
    createKid.school = values.school

    await createKid.save()*/
  }
  hideKidParent = async (value: boolean, kid: Kid) => {
    kid.set('hidden_in_dep_list', value)
    await kid.save()
  }
  kidNoBlog = async (values: boolean, kid: Kid) => {
    kid.set('canBeTagged', values)
    await kid.save()
  }
  changeDepartment = async (
    kidIds: string[],
    schoolId: string,
    department: Department,
  ) => {
    await Parse.Cloud.run('changeDepartmentForKids', {
      schoolId: schoolId,
      departmentId: department.id,
      kidObjectIds: kidIds,
    })
  }
  deleteKid = async (kidIds: string[], schoolId: string) => {
    await Parse.Cloud.run('softDeleteKids', {
      schoolId: schoolId,
      kidIdsArray: kidIds,
    })
  }
}

export const kidsStore = (() => {
  return new KidsStore()
})()
export const KidsStoreContext: React.Context<KidsStore> =
  React.createContext(kidsStore)
