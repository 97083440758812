/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { NavigationStoreContext } from '../../store/navigationStore'
import Page from '../../components/Page'
import { DocumentStoreContext } from '../../store/documentStore'
import { UserStoreContext } from '../../store/userStore'
import { DocumentItem } from './components/DocumentItem'
import { getDocsData } from './getDocsData'
import { ExternalLinkItem } from './components/ExternalLinkItem'
import { useTranslation } from 'react-i18next'
import ColorCard from '../../components/ColorCard'
import { Loading } from '../../components/Loading'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const TabHeaderSlider: React.FC<{
  tabs: {
    label: string
    value: string
    icon?: IconDefinition
    color?: string
  }[]
  onClick: (tab: {
    label: string
    value: string
    icon?: IconDefinition
    color?: string
  }) => void
  activeTab: {
    label: string
    value: string
    icon?: IconDefinition
    color?: string
  }
}> = ({ tabs, onClick, activeTab }) => {
  const { t } = useTranslation()
  const tabsRef = useRef<(HTMLDivElement | null)[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showFirstGroup, setShowFirstGroup] = useState(true)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 450)
  const [highlightStyle, setHighlightStyle] = useState({})

  useEffect(() => {
    const visibleTabs = tabs.filter((_, index) =>
      isNarrowScreen ? (showFirstGroup ? index < 3 : index >= 3) : true,
    )

    const visibleActiveTabIndex = visibleTabs.findIndex(
      (tab) => tab.value === activeTab.value,
    )

    if (visibleActiveTabIndex !== -1) {
      const currentTab = tabsRef.current[visibleActiveTabIndex]
      if (currentTab) {
        setHighlightStyle({
          width: `${currentTab.offsetWidth}px`,
          transform: `translateX(${currentTab.offsetLeft}px)`,
        })
      }
    }
  }, [isNarrowScreen, activeTab, activeTab, showFirstGroup])
  return (
    <>
      <div className="w-full rounded p-1 relative">
        <div className="flex items-center justify-start w-full overflow-x-auto">
          {tabs
            .filter((tab, index) =>
              isNarrowScreen ? (showFirstGroup ? index < 3 : index >= 3) : true,
            )
            .map((tab, index) => (
              <div
                key={tab.value}
                ref={(el) => (tabsRef.current[index] = el)}
                onClick={() => {
                  onClick(tab)
                  //setActiveTab(tab)
                }}
                className={`flex flex-1 flex-row text-center py-1 cursor-pointer text-sm justify-center ${
                  activeTab.value === tab.value
                    ? 'text-blue-500'
                    : 'text-gray-600'
                }`}
              >
                {tab.icon && (
                  <div
                    className={`w-[20px] h-[20px] rounded-full mr-1 flex items-center justify-center`}
                    style={{ backgroundColor: tab.color }}
                  >
                    <FontAwesomeIcon icon={tab.icon} className="text-white" />
                  </div>
                )}{' '}
                {tab.label}
              </div>
            ))}
        </div>
        <div
          className="absolute bottom-0 h-1 rounded-full bg-blue-500 transition-transform duration-300 ease-in-out"
          style={highlightStyle}
        />
      </div>
    </>
  )
}

export const DocumentMain: React.FC = observer(() => {
  const { t } = useTranslation()
  let { schoolId } = useParams()
  const navigate = useNavigate()
  const { setTitle } = useContext(NavigationStoreContext)
  const { savedUserRoleId, currentUserRole, fetchUserRole } =
    useContext(UserStoreContext)
  const { getMainDocs, mainDocs, docsLoading, externalLinks } =
    useContext(DocumentStoreContext)
  useEffect(() => {
    setTitle(t('documents.documentTitle'))
  }, [t])
  const tabs = [
    { label: t('menu.documents'), value: 'documents' },
    { label: t('documents.externalLinks'), value: 'external' } /*, 'Mallar'*/,
  ]
  const [activeTab, setActiveTab] = useState<{ label: string; value: string }>(
    tabs[0],
  )
  const [highlightStyle, setHighlightStyle] = useState({})
  const tabsRef = useRef<(HTMLDivElement | null)[]>([])
  const [showFirstGroup, setShowFirstGroup] = useState(true)
  const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 450)
  const [cardColor, setCardColor] = useState('')
  const [cardTitle, setCardTitle] = useState('')
  useEffect(() => {
    const visibleTabs = tabs.filter((_, index) =>
      isNarrowScreen ? (showFirstGroup ? index < 3 : index >= 3) : true,
    )

    const activeTabIndex = visibleTabs.indexOf(activeTab)

    const currentTab = tabsRef.current[activeTabIndex]
    if (currentTab) {
      setHighlightStyle({
        width: `${currentTab.offsetWidth}px`,
        transform: `translateX(${currentTab.offsetLeft}px)`,
      })
    }
  }, [isNarrowScreen, activeTab])
  useEffect(() => {
    switch (activeTab.value) {
      case 'documents':
        setCardColor('bg-documentsMain')
        setCardTitle(t('menu.documents'))
        break
      case 'external':
        setCardColor('bg-[#f08ad4]')
        setCardTitle(t('documents.externalLinks'))
        break
      case 'Mallar':
        setCardColor('bg-blue-300') // vad för färgkod ska det vara här? kolla med ove
        setCardTitle('Mallar')
        break
      default: // Standardfärg
        setCardColor('')
        setCardTitle('')
    }
  }, [activeTab, t])

  useEffect(() => {
    const handleResize = () => {
      setIsNarrowScreen(window.innerWidth < 450)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (savedUserRoleId) {
      fetchUserRole(savedUserRoleId).finally(() => {})
    }
  }, [savedUserRoleId])

  useEffect(() => {
    getMainDocs(savedUserRoleId ?? '')
  }, [])

  /*const anyUnread = (documents: any) => {
    let unreadExists = false
    documents.map((doc: any) => {
      if (doc.unSignedDocsCount > 0) {
        unreadExists = true
      }
    })
    return unreadExists
  }
  const showMarkAsRead = anyUnread(mainDocs)*/

  return (
    <Page
      naked
      loading={docsLoading}
      topBar={
        <>
          <div className="w-full rounded p-1 relative">
            <TabHeaderSlider
              tabs={tabs}
              onClick={(value) => {
                setActiveTab(value)
              }}
              activeTab={activeTab}
            />
            {/*<div className="flex items-center justify-start w-full overflow-x-auto">
              {tabs
                .filter((tab, index) =>
                  isNarrowScreen
                    ? showFirstGroup
                      ? index < 3
                      : index >= 3
                    : true,
                )
                .map((tab, index) => (
                  <div
                    key={tab}
                    ref={(el) => (tabsRef.current[index] = el)}
                    onClick={() => setActiveTab(tab)}
                    className={`flex-1 text-center py-3 cursor-pointer text-xs ${
                      activeTab === tab ? 'text-blue-500' : 'text-gray-600'
                    }`}
                  >
                    {tab}
                  </div>
                ))}
            </div>
            <div
              className="absolute bottom-0 h-1 rounded-full bg-blue-500 transition-transform duration-300 ease-in-out"
              style={highlightStyle}
                  />*/}
          </div>
        </>
      }
    >
      {docsLoading && <Loading />}

      <ColorCard title={cardTitle} bgColor={cardColor}>
        {activeTab.value === 'documents' && (
          <div>
            {' '}
            <div
              className={
                'pt-3 md:pt-10 grid gap-3 md:grid-cols-2 lg:gap-6 lg:grid-cols-3 max-w-7xl px-3 font-medium  '
              }
            >
              {mainDocs?.map((doc: any, index: number) => {
                const data = getDocsData(doc.type, currentUserRole)
                return (
                  <DocumentItem
                    id={doc?.id}
                    key={index}
                    title={data?.title ?? ''}
                    icon={data?.icon}
                    hideSettings={true}
                    className={data?.color ?? ''}
                    count={doc.docCount}
                    countSign={doc.unSignedDocsCount}
                    hasUnRead={doc.hasUnReadCount > 0}
                    onClick={() => {
                      navigate(
                        '/documents-type/' + schoolId + '?type=' + doc.type,
                      )
                    }}
                  />
                )
              })}
            </div>
            <div className={'pb-12'} />
          </div>
        )}
        {activeTab.value === 'external' && (
          <div>
            {' '}
            <div
              className={
                ' mt-4 grid gap-3 md:grid-cols-2 lg:gap-6 lg:grid-cols-3 max-w-7xl px-3 mb-3 font-medium text-gray-800 '
              }
            >
              {externalLinks?.map((link: any, index: number) => {
                return (
                  <ExternalLinkItem
                    key={index}
                    link={link}
                    role={currentUserRole?.attributes?.role_type}
                  />
                )
              })}
            </div>
            <div className={'pb-12'} />
          </div>
        )}
        {/*activeTab === 'Mallar' && <div>Mallar</div>*/}
      </ColorCard>
    </Page>
  )
})
