import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import { Button } from '../../../components/Button'
import { CreateEventParams } from '../../../store/event3Store'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Department } from '../../../Models/Department'
import { KidStoreContext } from '../../../store/kidStore'
import { Kid } from '../../../Models/Kid'
import ColorCard from '../../../components/ColorCard'
import { getImageOrPlaceHolder } from '../../../utils/parseUtils'
import { RandomColors } from '../../../components/RandomColors'
import classNames from 'classnames'
import { createDepTupletForKids } from './AddParticipatingChildren'

interface Props2 {
  onChange: (kidId: string) => void
  selectedKidIds: string[]
  departments: Department[]
  kids?: Kid[]
}

export const ChooseKids: React.FC<Props2> = observer((props: Props2) => {
  const fetchedkidssss = createDepTupletForKids(
    props.kids,
    props.departments?.map((department) => department.klassName),
  )
  return (
    <>
      <div className="flex flex-col overflow-auto scrollbar-hide h-[90%] bg-[#F6F6F6]">
        {fetchedkidssss?.map((group, index) => (
          <>
            <h2 className="pl-4 font-bold">
              {group.header} ({group.users.length})
            </h2>
            <div key={group.header} className="mb-2 py-2 w-full last:mb-40">
              <ul className={`flex flex-wrap gap-3 pl-3 gap-x-4 `}>
                {group.users.map((user) => {
                  return (
                    <li
                      key={user.id}
                      className={`p-1 w-[48%] flex items-center rounded-lg shadow-md bg-[#FFFFFF] transition-transform duration-300 active:scale-90 ${
                        props.selectedKidIds.includes(user.id) &&
                        'border-1 outline outline-2 outline-blue-500 '
                      } `}
                      onClick={() => {
                        if (props.selectedKidIds.includes(user.id)) {
                          props.onChange(user.id)
                        } else {
                          props.onChange(user.id)
                        }
                      }}
                    >
                      <div className="ml-1 w-full cursor-pointer flex items-center">
                        <img
                          className={`w-10 h-10 rounded-full object-cover`}
                          alt=""
                          key={user?.id}
                          src={
                            user?.kidImage_thumb?._url ??
                            '/images/placeholder-image.png'
                          }
                        />
                        <div className=" flex flex-col pl-2 justify-center">
                          <p className="font-semibold text-md w-full">
                            {user?.kidName}
                          </p>{' '}
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </>
        ))}
      </div>
    </>
  )
})
