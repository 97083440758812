import React from 'react'
import { makeAutoObservable } from 'mobx'
import Parse from 'parse'
import { UserRole } from '../Models/UserRole'
import { Kid } from '../Models/Kid'
import { ActionStates } from '../Models/ActionStates'
import { User } from '../Models/User'

type MainDocument = {
  documents: any[]
  external: any[]
}

export type DocumentOverview = {
  signed: User[]
  read: User[]
  unRead: User[]
  actionStates: ActionStates[]
}

export type ActiveUsers = {
  userRoles: UserRole[]
  kids?: { kid: Kid; id: string; userRoleId: string; userRoles: UserRole[] }[]
}

class DocumentStore {
  mainDocs: any
  externalLinks: any
  activeDocument: any
  documentOverview?: DocumentOverview
  schoolsActiveUsers?: ActiveUsers
  schoolFolders?: any
  selectedUserUserRoles?: UserRole[]
  docs: any
  docsLoading?: boolean
  search?: string
  sort?: string
  overviewLoading?: boolean
  constructor() {
    makeAutoObservable(this)
  }

  setSort = (sort: string) => {
    this.sort = sort
  }

  setSearch = (search: string) => {
    this.search = search
  }

  setDocsLoading = (loading: boolean) => {
    this.docsLoading = loading
  }
  setOverviewLoading = (loading: boolean) => {
    this.overviewLoading = loading
  }
  setMainDocs = (docs: any) => {
    this.mainDocs = docs
  }

  setExternalLinks = (links: any) => {
    this.externalLinks = links
  }

  setSelectedUserUserRoles = (roles: UserRole[]) => {
    this.selectedUserUserRoles = roles
  }

  setSchoolsActiveUsers = (users: ActiveUsers) => {
    this.schoolsActiveUsers = users
  }

  setDocs = (docs: any) => {
    this.docs = docs
  }

  setDocumentOverview = (overview: any) => {
    this.documentOverview = overview
  }

  setSchoolFolders = (folders: any) => {
    this.schoolFolders = folders
  }

  setActiveDocument = (doc: any) => {
    this.activeDocument = doc
  }

  shareDocument = async (userIds: string[], documentId: string) => {
    await Parse.Cloud.run('shareDocument', {
      userIds,
      documentId,
    })
  }

  editDocument = async ({
    name,
    documentId,
  }: {
    name: string
    documentId: string
  }) => {
    await Parse.Cloud.run('editDocument', {
      name,
      documentId,
    })
  }

  editFolder = async ({
    name,
    folderId,
  }: {
    name: string
    folderId: string
  }) => {
    await Parse.Cloud.run('editFolder', {
      name,
      folderId,
    })
  }

  addDocumentToFavorite = async ({
    documentId,
    userRoleId,
    isFavorite,
  }: {
    documentId: string
    userRoleId: string
    isFavorite: boolean
  }) => {
    const actionState = await Parse.Cloud.run('setFavorite', {
      userRoleId,
      isFavorite,
      documentId,
    })

    return actionState
  }

  test = async () => {
    await Parse.Cloud.run('inviteTeacher', {})
  }

  getMainDocs = async (userRoleId: string) => {
    this.setDocsLoading(true)
    const mainDocs: MainDocument = await Parse.Cloud.run('getMainDocs', {
      userRoleId,
    })
    this.setMainDocs(mainDocs?.documents ?? [])
    this.setExternalLinks(mainDocs?.external ?? [])
    this.setDocsLoading(false)
  }

  getDocsFromFolderType = async (userRoleId: string, type: string) => {
    this.setDocsLoading(true)
    const docs = await Parse.Cloud.run('getDocsFromFolderType', {
      userRoleId,
      folderType: type,
    })
    this.setDocs(docs)
    this.setDocsLoading(false)
  }

  getDocsFromFolderId = async (
    userRoleId: string,
    id: string,
    type?: string,
    kidId?: string,
  ) => {
    this.setDocsLoading(true)
    const docs = await Parse.Cloud.run('getDocsFromFolderId', {
      userRoleId,
      id: id,
      type: type ?? '',
      kidId: kidId ?? '',
    })
    this.setDocs(docs)
    this.setDocsLoading(false)
  }

  getDoc = async (id: string) => {
    this.setDocsLoading(true)
    const document = await new Parse.Query('documents').get(id)
    this.setActiveDocument(document)
    this.setDocsLoading(false)
  }

  getSchoolActiveUsers = async (userRoleId: string) => {
    const users: ActiveUsers = await Parse.Cloud.run('getSchoolActiveUsers', {
      userRoleId,
    })
    this.setSchoolsActiveUsers(users)
  }

  getUserRolesFromUser = async ({
    userId,
    userRoleId,
  }: {
    userId: string
    userRoleId: string
  }) => {
    const roles: UserRole[] = await Parse.Cloud.run('getUserRolesFromUser', {
      userRoleId,
      userId,
    })

    this.setSelectedUserUserRoles(roles)
  }

  addFolder = async ({
    name,
    userRoleId,
    kidId,
    depId,
    type,
    folderId,
    withParent,
  }: {
    name: string
    userRoleId: string
    type: string
    folderId?: string
    kidId?: string
    depId?: string
    withParent: boolean
  }) => {
    await Parse.Cloud.run('addFolder', {
      userRoleId,
      name,
      folderId,
      kidId,
      withParent,
      depId,
      type,
    })
  }

  addFile = async ({
    file,
    name,
    userRoleId,
    type,
    sign,
    mimeType,
    withParent,
    kidId,
    depId,
    folderId,
    sendPush,
  }: {
    file: any
    userRoleId: string
    type: string
    name: string
    sign: boolean
    withParent: boolean
    mimeType: string
    folderId?: string
    kidId?: string
    depId?: string
    sendPush?: boolean
  }) => {
    await Parse.Cloud.run('addFile', {
      userRoleId,
      file,
      folderId,
      name,
      sign,
      withParent,
      kidId,
      depId,
      mimeType,
      type,
      sendPush,
    })
  }

  removeFolder = async ({ folderId }: { folderId: string }) => {
    await Parse.Cloud.run('removeFolder', {
      folderId,
    })
  }

  removeFile = async ({ documentId }: { documentId: string }) => {
    await Parse.Cloud.run('removeFile', {
      documentId,
    })
  }

  getDocumentOverview = async ({
    documentId,
    type,
    departmentId,
  }: {
    documentId: string
    type: string
    departmentId?: string
  }) => {
    this.setOverviewLoading(true)
    const overview = await Parse.Cloud.run('getDocumentOverview', {
      documentId,
      type,
      departmentId,
    })
    this.setDocumentOverview(overview)
    this.setOverviewLoading(false)
  }

  getSchoolDocumentFolders = async (schoolId: string) => {
    const folders = await Parse.Cloud.run('getSchoolDocumentFolders', {
      schoolId,
    })
    this.setSchoolFolders(folders)
  }

  moveDocument = async ({
    folderId,
    documentId,
    type,
    kidId,
    depId,
  }: {
    folderId?: string
    kidId?: string
    depId?: string
    documentId: string
    type: string
  }) => {
    await Parse.Cloud.run('moveDocument', {
      folderId,
      documentId,
      type,
      kidId,
      depId,
    })
  }

  copyDocument = async ({
    folderId,
    documentId,
    type,
    kidId,
    depId,
  }: {
    folderId?: string
    kidId?: string
    depId?: string
    documentId: string
    type: string
  }) => {
    await Parse.Cloud.run('copyDocument', {
      folderId,
      documentId,
      type,
      kidId,
      depId,
    })
  }

  setDocumentViewed = async ({
    documentId,
    userRoleId,
  }: {
    documentId: string
    userRoleId: string
  }) => {
    await Parse.Cloud.run('setDocumentViewed', {
      documentId,
      userRoleId,
    })
  }

  readAll = async ({
    type,
    userRoleId,
    folderId,
    kidId,
    depId,
  }: {
    type: string
    userRoleId: string
    folderId?: string
    kidId?: string
    depId?: string
  }) => {
    await Parse.Cloud.run('readAll', {
      type,
      userRoleId,
      folderId,
      kidId,
      depId,
    })
  }

  requestSignDocument = async (documentId: string, requireSign: boolean) => {
    await Parse.Cloud.run('requestSignDocument', {
      documentId,
      requireSign,
    })
  }

  signDocument = async (documentId: string, userRoleId: string) => {
    await Parse.Cloud.run('signDocument', {
      documentId,
      userRoleId,
    })
  }
}

export const documentStore = (() => {
  return new DocumentStore()
})()
export const DocumentStoreContext: React.Context<DocumentStore> =
  React.createContext(documentStore)
