import { useContext, useEffect, useRef, useState } from 'react'
import { User } from './OverviewUsersList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChild,
  faChildReaching,
  faTrash,
  faXmark,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../../components/Button'
import { Event3StoreContext } from '../../../store/event3Store'
import { KidStoreContext } from '../../../store/kidStore'
import { UserStoreContext } from '../../../store/userStore'
import { DepartmentStoreContext } from '../../../store/departmentStore'
import { observer } from 'mobx-react'
import { Kid } from '../../../Models/Kid'
import ModalRegular from '../../../components/ModalRegular'
import { useTranslation } from 'react-i18next'

interface IProps {
  presentKids: {
    users: User[]
    header: string
  }[]
  onClose: () => void
  refreshDetail: () => void
  eventTimeId?: string
  dataAction?: string | null
  departmentIds?: string[]
  toggleMenu: (e: any) => void
  chooseMany?: boolean
}

interface DepartmentGroupForKids {
  header: string
  users: Kid[]
}
const createDepTupletForKids = (
  kids?: Kid[],
  departmentsFilter?: (string | undefined)[],
): DepartmentGroupForKids[] => {
  const target: DepartmentGroupForKids[] = []
  const depDict: Record<string, Kid[]> = {}
  const missing: Kid[] = []

  kids?.forEach((kid) => {
    if (kid.departmentPointer?.attributes.klassName === null) {
      missing.push(kid)
    } else if (kid.departmentPointer?.attributes.klassName) {
      const klassName = kid.departmentPointer?.attributes.klassName
      if (klassName) {
        if (!depDict[klassName]) {
          depDict[klassName] = []
        }
        depDict[klassName].push(kid)
      }
    }
  })

  for (const [key, value] of Object.entries(depDict)) {
    if (departmentsFilter?.includes(key) || departmentsFilter?.length === 0)
      target.push({ header: key, users: value })
  }

  target.sort((a, b) => a.header.localeCompare(b.header))

  if (missing.length > 0) {
    const missingDep = 'Department is missing'
    target.push({ header: missingDep, users: missing })
  }

  return target
}
export const ListOfActiveUsers: React.FC<IProps> = observer(
  ({
    presentKids,
    onClose,
    refreshDetail,
    eventTimeId,
    dataAction,
    departmentIds,
    toggleMenu,
    chooseMany,
  }) => {
    const popupRef = useRef<HTMLDivElement>(null)
    const [choosenIds, setChoosedIds] = useState<string[]>([])
    const [choosenKidsId, setChoosenKidsId] = useState<string[]>([])
    const {
      isPresentOnEventTime,
      declineParticipatingInEvent,
      acceptParticipatingInEvent,
    } = useContext(Event3StoreContext)
    const { kids, fetchKids } = useContext(KidStoreContext)
    const { currentUserRole } = useContext(UserStoreContext)
    const { fetchDepartments, departments } = useContext(DepartmentStoreContext)
    const { t } = useTranslation()

    const schoolId = currentUserRole && currentUserRole.school_pointer.id

    const fetchedkidssss = createDepTupletForKids(
      kids,
      departments?.map((department) => department.klassName),
    )

    const handleClickOutside = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      event.stopPropagation()
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose()
      }
    }

    const handleChoosedIdsToBeProccessed = (userId: string, kidId?: string) => {
      setChoosedIds((prevSelectedUserIds) =>
        prevSelectedUserIds.includes(userId)
          ? prevSelectedUserIds.filter((id) => id !== userId)
          : [...prevSelectedUserIds, userId],
      )
      kidId &&
        setChoosenKidsId((prevSelectedUserIds) =>
          prevSelectedUserIds.includes(kidId)
            ? prevSelectedUserIds.filter((id) => id !== kidId)
            : [...prevSelectedUserIds, kidId],
        )
    }

    useEffect(() => {
      if (schoolId) {
        const fetchDepartmentss = async () => fetchDepartments(schoolId)
        const fetchKidss = async () => {
          await fetchKids(
            schoolId,
            departments?.filter((department) =>
              departmentIds?.includes(department.id),
            ),
          )
        }
        fetchDepartmentss()
        fetchKidss()
      }
    }, [schoolId])
    const usersToShow =
      dataAction !== 'add-participant' ? presentKids : fetchedkidssss

    return (
      <ModalRegular
        title={t('events.addParticipants')}
        isOpen={chooseMany!!}
        /* bgColor="bg-eventsMain p-4" */
        closeColor="white"
        className={'h-[600px] pt-4 !overflow-hidden'}
        classNameTitle="mb-4 w-full text-black !important flex justify-center font-semibold"
        onClose={onClose}
      >
        <div className="relative h-full overflow-auto">
          <div className="flex flex-col mb-20">
            {usersToShow?.map((group) => (
              <>
                <div className="flex justify-between" key={group.users.length}>
                  <h2 className="pl-2  font-bold">
                    {group.header} ({group.users.length})
                  </h2>
                  {choosenIds.length !== group.users.length &&
                  choosenKidsId.length !== group.users.length ? (
                    <h2
                      className="pr-2 font-bold text-blue-500 cursor-pointer"
                      key={group.header}
                      onClick={(e) => {
                        e.stopPropagation()
                        setChoosedIds([])
                        setChoosenKidsId([])
                        // eslint-disable-next-line array-callback-return
                        group.users.map((user) => {
                          handleChoosedIdsToBeProccessed(
                            user instanceof Kid ? '' : user.id,
                            user instanceof Kid
                              ? (user as Kid).id
                              : (user as User).kidId,
                          )
                        })
                      }}
                    >
                      select all
                    </h2>
                  ) : (
                    <h2
                      className="pr-2 font-bold text-blue-500 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation()
                        setChoosedIds([])
                        setChoosenKidsId([])
                      }}
                    >
                      Deselect all
                    </h2>
                  )}
                </div>
                <div
                  key={group.header}
                  className="mb-4 pl-2 w-full overflow-y-auto"
                >
                  <ul className="flex flex-wrap gap-3 px-3 gap-x-4 py-2">
                    {group.users.map((user) => {
                      return (
                        <li
                          key={user.id}
                          className={`p-1 w-[48%] h-full flex items-center rounded-lg shadow-md transition-transform duration-300 active:scale-90 ${
                            (choosenIds.includes(user.id) ||
                              choosenKidsId.includes(
                                (user instanceof Kid && user.id) || '',
                              )) &&
                            `border-1 outline outline-2  ${
                              dataAction === 'leaving-activity' ||
                              dataAction === 'remove-participant'
                                ? 'outline-red-600'
                                : dataAction === 'add-participant'
                                ? 'outline-blue-400'
                                : 'outline-green-600'
                            }`
                          }`}
                          onClick={(e) => {
                            e.stopPropagation()
                            handleChoosedIdsToBeProccessed(
                              user instanceof Kid ? '' : user.id,
                              user instanceof Kid
                                ? (user as Kid).id
                                : (user as User).kidId,
                            )
                          }}
                        >
                          <div
                            className="ml-1 w-full cursor-pointer flex overflow-y-scroll"
                            onClick={(e) => {
                              e.stopPropagation()
                              handleChoosedIdsToBeProccessed(
                                user instanceof Kid ? '' : user.id,
                                user instanceof Kid
                                  ? (user as Kid).id
                                  : (user as User).kidId,
                              )
                            }}
                          >
                            <img
                              className={`w-10 h-10 rounded-full object-cover `}
                              src={
                                ((user as Kid) &&
                                  (user as Kid)?.kidImage_thumb?._url) ||
                                '/images/placeholder-image.png'
                              }
                              alt=""
                              key={user?.id}
                            />
                            <div className=" flex flex-col pl-2 justify-center">
                              <p className="font-bold text-gray-900 w-full text-xs break-words">
                                {(user as User)?.name || (user as Kid)?.kidName}
                              </p>{' '}
                            </div>
                          </div>
                          {choosenIds.includes(user.id) && (
                            <FontAwesomeIcon
                              icon={
                                dataAction === 'leaving-activity'
                                  ? faChild
                                  : dataAction === 'remove-participant'
                                  ? faTrash
                                  : dataAction === 'add-participant'
                                  ? faCircleCheck
                                  : faChildReaching
                              }
                              size="lg"
                              className={`${
                                dataAction === 'leaving-activity' ||
                                dataAction === 'remove-participant'
                                  ? 'text-red-400'
                                  : dataAction === 'add-participant'
                                  ? 'text-blue-400'
                                  : 'text-green-600'
                              }`}
                            />
                          )}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </>
            ))}
          </div>
          <div
            className={`flex w-full justify-center fixed  bottom-0
             left-0 bg-gradient-to-t from-white pb-2`}
          >
            <Button
              size="sm"
              className="w-[90%] "
              variant="fullBlue"
              fontSize="font-bold"
              label={`${
                dataAction === 'leaving-activity'
                  ? 'Leav'
                  : dataAction === 'remove-participant'
                  ? 'Remove'
                  : dataAction === 'add-participant'
                  ? 'Add'
                  : 'Save'
              }`}
              onClick={(e) => {
                e.stopPropagation()

                if (choosenIds.length === 0 && choosenKidsId.length === 0)
                  return
                try {
                  if (
                    dataAction !== 'remove-participant' &&
                    dataAction !== 'add-participant'
                  ) {
                    isPresentOnEventTime(
                      eventTimeId,
                      choosenIds,
                      dataAction === 'leaving-activity' ? false : true,
                    )
                  } else if (dataAction === 'remove-participant') {
                    declineParticipatingInEvent(
                      eventTimeId,
                      choosenIds,
                      choosenKidsId,
                    )
                  } else if (dataAction === 'add-participant') {
                    acceptParticipatingInEvent(
                      eventTimeId,
                      choosenIds,
                      choosenKidsId,
                    )
                  }
                  refreshDetail()
                  onClose()
                  toggleMenu(e)
                } catch (error) {
                  console.error(error)
                }
              }}
            />
          </div>
        </div>
      </ModalRegular>
    )
  },
)
