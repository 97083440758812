import { Field, FieldProps, Form, Formik } from 'formik'
import Parse from 'parse'
import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components/Button'
import { Input } from '../../components/formik/Input'
import * as Yup from 'yup'
import { UserStoreContext } from '../../store/userStore'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import {
  CHANGE_TYPES,
  ChangeTranslation,
} from '../../components/ChangeTranslation'
import {
  faArrowLeft,
  faSignOutAlt,
  faUser,
  faArrowRight,
  faChevronDown,
  faUserCircle,
  faNewspaper,
  faChildReaching,
  faCommentMedical,
  faFolder,
  faRotate,
  faChartPie,
} from '@fortawesome/free-solid-svg-icons'
import {
  NotificationContext,
  NotificationVariant,
} from '../../App/notification/notificationManger'
import ModalRegular from '../../components/ModalRegular'
import moment from 'moment'
import classNames from 'classnames'
import { useClickOutside } from '../../hooks/useClickoutside'
import { ButtonDropdown } from '../../components/Dropdown'
import Flags from 'country-flag-icons/react/3x2'
import { DashboardItem } from '../dashboard/components/DashboardItem'
import { faMegaphone } from '@fortawesome/pro-solid-svg-icons'
import { Spinner } from '../../components/Spinner'
import { useParams } from 'react-router-dom'
import { PropTypes } from 'mobx-react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { LoginAction, LOGIN_METHOD, TERRITORY } from '../../Enums/TyraEnums'
import { Loading } from '../../components/Loading'
import BackButton from '../../components/BackButton'
import Swal from 'sweetalert2'
import CustomInput from '../../components/CustomInput'
import { ImageUploader } from '../../components/ImageUploader'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { Slider } from '../../components/Slider'
import { MyConnectionsCard } from '../settings/components/MyConnectionsCard'
import { InviteAuthCard } from './componentes/InviteAuthCard'
import Base64Image from './componentes/Base64Image'
//import 'react-phone-input-2/lib/material.css'
var myTimeout: string | number | NodeJS.Timer | undefined

export const Login: React.FC<{
  onClick?: any
  loginArea?: number
}> = ({ onClick, loginArea }) => {
  const {
    loginUser,
    checkUser,
    loginWithNin,
    pollLoginWithNIN,
    pollIdpLogin,
    createLoginRequest,
    loginNew,
    pollLoginSession,
  } = useContext(UserStoreContext)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [fakeState, setFakeState] = useState('')
  const [newInvite, setNewInvite] = useState(false)

  const [openMyConnections, setOpenMyConnections] = useState(false)
  const [openUserInfo, setOpenUserInfo] = useState(false)
  const [termsOfUse, setTermsOfUse] = useState(false)
  const [freeText, setFreeText] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loginChoices, setLoginChoices] = useState<any>(null)
  const [chosenLogin, setChosenLogin] = useState<string | null>(null)
  const [viewState, setViewState] = useState('country')
  //const [viewState, setViewState] = useState('enterCode')
  const [usernameMode, setUsernameMode] = useState('mobile')
  const [showModal, setShowModal] = useState(false)
  const [enterVerificationCode, setEnterVerificationCode] = useState(true)
  const [verificationCode, setVerificationCode] = useState('')
  const [countryCode, setCountryCode] = useState('SE')
  const [bankIdWaitingForSign, setBankIdWaitingForSign] = useState(false)
  const [qrCode, setQrCode] = useState<null | string>(null)
  const [verifying, setVerifying] = useState<boolean>(false)
  const [idpWaitingToSignIn, setIdpWaitingToSignIn] = useState(false)
  let [thisDevice, setThisDevice] = useState(true)
  const { bankidSessionId, id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [loginSessionId, setLoginSessionId] = useState<string | null>(null)
  const bankIdRef = useRef<HTMLInputElement>(null)
  const getPlaceholder = () => {
    if (usernameMode === 'mobile') {
      return t('login.placeholderMobile')
    } else if (usernameMode === 'email') {
      return t('login.placeholderEmail')
    } else if (usernameMode === 'bankid') {
      return t('login.placeholderBankid')
    } else if (usernameMode === 'idp') {
      return t('login.enterUsername')
    }
  }

  const isUserInvitedNotRegistered = async (
    username: string,
  ): Promise<boolean> => {
    // Implementera logiken här för hur själva v
    // true ller false beroende på statet på användaren
    return true
  }
  const onSubmit = async (values: any) => {
    // ...existeranade logik om det behövs ...

    //
    const userInvitedNotRegistered = await isUserInvitedNotRegistered(
      values.username,
    )
    if (userInvitedNotRegistered) {
      Swal.fire({
        title: 'Invitation Found',
        text: 'användaren är inbjuden men inte registrerad',
        icon: 'info',
        confirmButtonText: 'Register',
      })
      // navigering kasnke?
    } else {
    }
  }

  const bankIdAuthorizing = (sessionId: string | null) => {
    if (sessionId) {
      setInterval(() => {
        pollLoginWithNIN(sessionId).then(async (result) => {
          if (result) {
            try {
              await Parse.User.become(result).then((response) => {
                if (response) {
                  //navigate('/')
                  //setBankIdWaitingForSign(false)

                  window.location.reload()
                }
              })
            } catch (e) {
              console.log('error', e)

              setBankIdWaitingForSign(false)

              Swal.fire({
                text: t('login.login_fail_message'),
                icon: 'error',
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonColor: '#7eb5f4',
                cancelButtonColor: '#d33',
                confirmButtonText: t('general.ok'),
              })
            }
          }
        })
      }, 5000)
    }
  }

  useEffect(() => {
    if (searchParams.get('bankidSessionId')) {
      setViewState('username')
      setUsernameMode('bankid')
      setBankIdWaitingForSign(true)
      bankIdAuthorizing(searchParams.get('bankidSessionId'))
    }
  }, [searchParams.get('bankidSessionId')])

  useEffect(() => {
    if (searchParams.get('loginSessionId')) {
      setViewState('username')
      setUsernameMode('bankid')
      setIdpWaitingToSignIn(true)
      myTimeout = setInterval(function () {
        pollLoginSession(
          searchParams.get('loginSessionId')!,
          LoginAction.Idp,
          undefined,
          undefined,
          false,
        )
          .then(async (response) => {
            if (
              response.action == LoginAction.Become &&
              response.parseSessionId
            ) {
              await Parse.User.become(response.parseSessionId)
                .then((response) => {
                  if (response) {
                    //navigate('/')
                    //setBankIdWaitingForSign(false)
                    window.location.reload()
                  }
                })
                .catch((e) => {
                  clearInterval(myTimeout)
                  throw e
                })
            }
          })
          .catch((e) => {
            clearInterval(myTimeout)
            throw e
          })
      }, 5000)
    }
  }, [searchParams.get('loginSessionId')])
  const getBackButtonClassName = () => {
    const baseClass =
      'hover:underline hover:text-eventsMain cursor-pointer pl-2 mt-1 w-fit text-gray-500'

    if (viewState === 'password') {
      return `${baseClass} mt-8`
    } else if (viewState === 'username') {
      return `${baseClass} mt-1`
    } else if (viewState === 'enterCode') {
      return `${baseClass} -mt-1`
    }

    return baseClass
  }
  const loginTerritory = (countryCode: string) => {
    if (countryCode === 'SE') {
      return 752
    }
    if (countryCode === 'ES') {
      return 724
    }
    return undefined
  }
  const { currentUserRole } = useContext(UserStoreContext)
  const territory =
    currentUserRole?.school_pointer.attributes.territory ?? loginTerritory

  const handleNewImage = (file: File) => {}
  return (
    <>
      <div className="fixed top-0 left-0 -z-10 h-full w-full ">
        <div
          className={
            'flex w-full h-screen justify-center items-center  flex-col'
          }
        >
          <div className="blur-lg align-middle items-center m-auto">
            <div
              className={
                'text-center w-full relative left-[50%] ml-[-75px] mb-12'
              }
            >
              <img
                className={
                  'w-[150px] h-[150px] object-cover rounded-full text-center'
                }
                src={'/images/placeholder-image.png'}
                alt=""
              />
            </div>

            <div
              className={
                'grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3 md:gap-6 place-items-center'
              }
            >
              <DashboardItem
                title={''}
                className={'bg-portfolioMain'}
                icon={faUserCircle}
              />
              <DashboardItem
                title={''}
                className={'bg-eventsMain'}
                icon={faNewspaper}
              />

              <DashboardItem
                title=""
                className={'bg-classlistMain'}
                icon={faChildReaching}
              />
              <DashboardItem
                title={''}
                className={'bg-blogMain'}
                icon={faMegaphone}
              />

              <DashboardItem
                title={''}
                className={'bg-absenceMain'}
                icon={faCommentMedical}
              />

              <DashboardItem
                title={''}
                className={'bg-documentsMain'}
                icon={faFolder}
              />

              <DashboardItem
                title={''}
                className={'bg-documentationMain'}
                icon={faRotate}
              />
              <DashboardItem
                title={''}
                className={'bg-statistics'}
                icon={faChartPie}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          ' flex w-full h-full justify-center items-center overflow-auto flex-col relative bg-[rgba(230,230,230,0.7)]'
        }
      >
        <div
          className={
            'absolute flex w-full justify-between top-0 p-3 items-center'
          }
        >
          <ChangeTranslation changeType={CHANGE_TYPES.FLAGS} />
        </div>
        <div className={'min-w-[250px] w-full max-w-[550px] p-3 sm:mb-32'}>
          <div className={'flex flex-col w-full items-center'}>
            <p className={'text-gray-500 text-2xl font-bold'}>
              {t('login.welcome')} TYRA
            </p>
            <div className="flex flex-row">
              <img
                className={'w-full max-w-[230px] object-contain mt-12'}
                src={process.env.PUBLIC_URL + '/images/TYRA_logo_text.png'}
              />

              <img
                className={
                  'w-full animate-wave mt-2 max-w-[110px] object-contain'
                }
                src={process.env.PUBLIC_URL + '/images/hand.png'}
                alt="tyra logo"
              />
            </div>
          </div>
          {viewState == 'enterCode' && (
            <div className="w-full bg-[rgba(255,255,255,0.7)] rounded-2xl p-4 text-center grid grid-cols-1 gap-4 mb-4">
              <p className="w-full text-lg">
                {t('login.verificationRequired')}
              </p>
              <p className="w-full text-sm">
                {usernameMode == 'mobile' && t('login.verCodeSentToPhone')}
                {usernameMode == 'email' && t('login.verCodeSentToEmail')}
              </p>
            </div>
          )}
          <Formik
            enableReinitialize
            initialValues={{
              username: '',
              password: '',
              thisDevice: 1,
              verification_code: '',
            }}
            validationSchema={LoginScheme}
            onSubmit={async (values, helpers) => {
              if (viewState === 'country') {
                setViewState('username')
              } else if (viewState === 'enterCode') {
                await pollLoginSession(
                  loginSessionId!,
                  LoginAction.Verify,
                  undefined,
                  values.verification_code,
                  false,
                )
                  .then((response) => {
                    if (response.action == LoginAction.Password) {
                      Swal.fire({
                        //title: 'Are you sure?',
                        text: t('login.verified'),
                        icon: 'success',
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      })
                      setViewState('password')
                      setTermsOfUse(true)
                    }
                  })

                  .catch((e) => {
                    Swal.fire({
                      //title: 'Are you sure?',
                      text: t('login.wrongCode'),
                      icon: 'error',
                      showCancelButton: false,
                      focusConfirm: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                    })

                    throw e
                  })
              } else if (viewState === 'password') {
                await pollLoginSession(
                  loginSessionId!,
                  LoginAction.Password,
                  values.password,
                  undefined,
                  false,
                )
                  .then(async (response) => {
                    // Gör lite swal här

                    if (
                      response.action == LoginAction.Become &&
                      response.parseSessionId
                    ) {
                      await Parse.User.become(response.parseSessionId).then(
                        (response) => {
                          if (response) {
                            //navigate('/')
                            //setBankIdWaitingForSign(false)

                            window.location.reload()
                          }
                        },
                      )
                    } else if (response.retry) {
                      Swal.fire({
                        title: t('login.login_fail_title'),
                        text: t('login.login_fail_message'),
                        icon: 'error',
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      })
                    } else if (response.action == LoginAction.MultiWeb) {
                      Swal.fire({
                        title: t('login.login_fail_title'),
                        text: t('login.schoolAccountError'),
                        icon: 'error',
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      })
                    }
                  })
                  .catch((e) => {
                    Swal.fire({
                      title: t('login.login_fail_title'),
                      text: t('login.login_fail_message'),
                      icon: 'error',
                      showCancelButton: false,
                      focusConfirm: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                    })
                  })
              } else {
                let loginMethod = 0
                if (usernameMode == 'mobile') {
                  loginMethod = LOGIN_METHOD.PHONE
                } else if (usernameMode == 'bankid') {
                  loginMethod = LOGIN_METHOD.GRANDID
                } else if (usernameMode == 'email') {
                  loginMethod = LOGIN_METHOD.EMAIL
                } else if (loginChoices) {
                  loginMethod = LOGIN_METHOD.GRANDID
                }
                await loginNew(
                  usernameMode === 'bankid'
                    ? values.username.replace('-', '')
                    : values.username,
                  loginMethod,
                  undefined,
                  chosenLogin ?? undefined,
                )
                  .then(async (response) => {
                    if (response.loginSessionId) {
                      setLoginSessionId(response.loginSessionId)
                    }
                    if (response.noUser) {
                      // Swal att det inte finns någon användare på detta användarnamnet.
                      Swal.fire({
                        title: t('login.not_found_user_name_title'),
                        text: t('login.not_found_user_name_message'),
                        icon: 'error',
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonColor: '#7eb5f4',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t('general.ok'),
                      })
                    }
                    if (response.action == LoginAction.Verify) {
                      // Måste verifieras
                    }
                    if (response.action == LoginAction.OtherLoginMethod) {
                      // Swal för att guida rätt
                      if (response.otherLoginMethod == LOGIN_METHOD.EMAIL) {
                        // email
                        Swal.fire({
                          //title: t('login.not_found_user_name_title'),
                          text: t('login.try_with_email_message'),
                          icon: 'warning',
                          showCancelButton: false,
                          focusConfirm: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                        })
                      } else if (
                        response.otherLoginMethod == LOGIN_METHOD.GRANDID
                      ) {
                        // bankId
                        Swal.fire({
                          //title: t('login.not_found_user_name_title'),
                          text: t('login.try_with_grandid_message'),
                          icon: 'warning',
                          showCancelButton: false,
                          focusConfirm: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                        })
                      } else if (
                        response.otherLoginMethod == LOGIN_METHOD.PHONE
                      ) {
                        // telefon
                        Swal.fire({
                          //title: t('login.not_found_user_name_title'),
                          text: t('login.try_with_phone_message'),
                          icon: 'warning',
                          showCancelButton: false,
                          focusConfirm: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                        })
                      } else if (
                        response.otherLoginMethod == LOGIN_METHOD.UNKNOWN
                      ) {
                        // unknown
                        Swal.fire({
                          title: t('login.not_found_user_name_title'),
                          text: t('login.not_found_user_name_message'),
                          icon: 'error',
                          showCancelButton: false,
                          focusConfirm: false,
                          confirmButtonColor: '#7eb5f4',
                          cancelButtonColor: '#d33',
                          confirmButtonText: t('general.ok'),
                        })
                      }
                    }
                    if (response.action == LoginAction.BankID) {
                      await pollLoginSession(
                        response.loginSessionId!,
                        LoginAction.BankID,
                        undefined,
                        undefined,
                        false,
                      )
                        .then((response) => {
                          setBankIdWaitingForSign(true)
                          if (response.action == LoginAction.PollBankId) {
                            myTimeout = setInterval(async function () {
                              await pollLoginSession(
                                response.loginSessionId!,
                                LoginAction.PollBankId,
                                undefined,
                                undefined,
                                false,
                              )
                                .then(async (response) => {
                                  if (response.qrCode) {
                                    setQrCode(response.qrCode)
                                  }
                                  if (response.hintCode === 'userSign') {
                                    setVerifying(true)
                                  }
                                  if (
                                    response.action == LoginAction.Become &&
                                    response.parseSessionId
                                  ) {
                                    await Parse.User.become(
                                      response.parseSessionId,
                                    ).then((response) => {
                                      if (response) {
                                        //navigate('/')
                                        //setBankIdWaitingForSign(false)

                                        window.location.reload()
                                      }
                                    })
                                  }
                                })
                                .catch((e) => {
                                  console.log('ERROR', e)
                                  console.log('ERROR message', e.message)
                                  if (
                                    e.message.message === 'No pending BankID'
                                  ) {
                                    console.log('Hej')
                                    /*  await pollLoginSession(
                                      loginSessionId!,
                                      LoginAction.PollBankId,
                                      undefined,
                                      undefined,
                                      false,
                                      true,
                                    )
                                      .then(async (response) => {})
                                      .catch((e) => {
                                        throw e
                                      })*/
                                    setBankIdWaitingForSign(false)
                                    setQrCode(null)
                                    clearInterval(myTimeout)
                                    Swal.fire({
                                      //title: 'Are you sure?',
                                      text: t('login.bankIDerror'),
                                      icon: 'warning',
                                      showCancelButton: false,
                                      focusConfirm: false,
                                      confirmButtonColor: '#7eb5f4',
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: t('general.ok'),
                                    })
                                  }
                                })
                            }, 2000)
                          }
                        })
                        .catch((e) => {
                          throw e
                        })
                    } else if (response.action === LoginAction.Password) {
                      setViewState('password')
                    } else if (response.action === LoginAction.Verify) {
                      setViewState('enterCode')
                    } else if (response.action === LoginAction.Choice) {
                      setLoginChoices(response.loginChoice)
                    } else if (response.action === LoginAction.Idp) {
                      if (response.idp_url) {
                        window.location.href = response.idp_url
                      }
                    }
                  })
                  .catch((e) => {
                    throw e
                  })
              }
            }}
            validateOnChange={false}
          >
            {({ isSubmitting, submitForm, dirty, isValid, setFieldValue }) => {
              return (
                <Form
                  className={
                    'space-y-2 w-full max-w-lg absolute grid grid-cols-1'
                  }
                >
                  {viewState === 'username' && (
                    <div className="pb-3">
                      <SelectWhatUsername
                        onSelect={(mode: string) => {
                          setUsernameMode(mode)
                          setFieldValue('username', '')
                        }}
                        selected={usernameMode}
                        countryCode={countryCode}
                      />
                    </div>
                  )}
                  <div className="flex justify-between">
                    {viewState === 'country' && (
                      <div className="w-full mt-2">
                        <SelectCountryDropdown
                          onSelected={(code: string) => {
                            setCountryCode(code)
                          }}
                          code={countryCode}
                        />
                      </div>
                    )}
                    {viewState === 'username' && (
                      <div className="grow font-normal font-sans">
                        {usernameMode == 'mobile' ? (
                          <div className="flex -mt-[1px] cursor-pointer">
                            <PhoneInput
                              searchClass=""
                              country={'se'}
                              value={''}
                              autoFormat={false}
                              onChange={(phone, xx, e) => {
                                var s = phone
                                if (s.charAt(2) === '0') {
                                  e.preventDefault()
                                  var position = 3 // its 1 based
                                  s =
                                    s.substring(0, position - 1) +
                                    s.substring(position, s.length)
                                }
                                setFieldValue('username', '+' + s)
                              }}
                            />
                          </div>
                        ) : (
                          <Field name="username" className="grow">
                            {(props: FieldProps<LoginFormValues>) => (
                              <>
                                {usernameMode === 'bankid' ? (
                                  <CustomInput
                                    ref={bankIdRef}
                                    onChange={(value) => {
                                      props.form.setFieldValue(
                                        'username',
                                        value,
                                      )
                                    }}
                                    loginTerritory={loginTerritory(countryCode)}
                                    value={props.form.values.username}
                                    icon="None"
                                    roundedFull
                                    format={'YYYYMMDD-LLXX'}
                                    className="grow flex-1 block mt-[4px] w-full px-4 py-3 placeholder-gray-400 border border-white rounded-full shadow-xl appearance-none focus:outline-none focus:ring-white focus:border-white sm:text-sm"
                                  />
                                ) : (
                                  <Input
                                    customInputClass="grow flex-1 block cursor-pointer mb-[px] w-full px-4 py-3 placeholder-gray-400 border border-white rounded-full shadow-xl appearance-none focus:outline-none focus:ring-white focus:border-white sm:text-sm"
                                    label=""
                                    placeholder={getPlaceholder()}
                                    autoFocus
                                    {...props}
                                  />
                                )}
                              </>
                            )}
                          </Field>
                        )}
                      </div>
                    )}
                    {viewState === 'password' && (
                      <div className="grow">
                        <Field name="password">
                          {(props: FieldProps<LoginFormValues>) => (
                            <Input
                              label=""
                              customInputClass="grow flex-1 block w-full px-4 py-3 placeholder-gray-400 border border-white rounded-xl shadow-xl appearance-none focus:outline-none focus:ring-white focus:border-white sm:text-sm"
                              inputType={'password'}
                              placeholder={t('login.login_password')}
                              autoFocus
                              {...props}
                            />
                          )}
                        </Field>
                      </div>
                    )}
                    {viewState === 'enterCode' && (
                      <div className="grow">
                        <Field name="verification_code">
                          {(props: FieldProps<LoginFormValues>) => (
                            <Input
                              label=""
                              customInputClass="grow flex-1 block w-full px-4 py-3 placeholder-gray-400 border border-white rounded-xl shadow-xl appearance-none focus:outline-none focus:ring-white focus:border-white sm:text-sm"
                              inputType={'verification_code'}
                              placeholder={t('login.verificationCode')}
                              autoFocus
                              {...props}
                            />
                          )}
                        </Field>
                      </div>
                    )}
                    {viewState === 'password' && usernameMode !== 'bankid' && (
                      <>
                        <Button
                          className="flex-none h-[45px] w-[45px] mt-[5px] ml-4"
                          loading={loading}
                          type="submit"
                          variant="primary"
                          //label={t('login.loginTitle')}
                          icon={faArrowRight}
                          onClick={submitForm}
                          disabled={isSubmitting}
                        />
                      </>
                    )}
                    {viewState === 'enterCode' && usernameMode !== 'bankid' && (
                      <Button
                        className="flex-none h-[45px] w-[45px] mt-[5px] ml-4"
                        loading={loading}
                        type="submit"
                        variant="primary"
                        //label={t('login.loginTitle')}
                        icon={faArrowRight}
                        onClick={submitForm}
                        disabled={isSubmitting}
                      />
                    )}
                    {((viewState === 'username' && usernameMode !== 'bankid') ||
                      (viewState === 'country' &&
                        usernameMode !== 'bankid')) && (
                      <Button
                        className="flex-none h-[45px] w-[45px] mt-[5px] ml-4"
                        loading={loading}
                        type="button"
                        variant="primary"
                        size="lg"
                        //label={t('login.nextTitle')}
                        icon={faArrowRight}
                        onClick={submitForm}
                        disabled={isSubmitting}
                      />
                    )}
                    {viewState === 'username' && usernameMode === 'bankid' && (
                      <>
                        <Button
                          className="flex-none h-[45px] w-[45px] mt-[5px] ml-4"
                          loading={loading}
                          type="button"
                          variant="primary"
                          size="lg"
                          //label={t('login.nextTitle')}
                          icon={faArrowRight}
                          onClick={() => {
                            setThisDevice(false)
                            thisDevice = false
                            submitForm()
                          }}
                          disabled={isSubmitting}
                        />
                      </>
                    )}
                  </div>
                  {viewState === 'password' && (
                    <div className={'w-full flex pt-3 justify-end'}>
                      <a
                        className={
                          'text-eventsMain pr-16 cursor-pointer hover:underline'
                        }
                        onClick={() => {
                          setShowModal(true)
                        }}
                      >
                        {t('login.forgot_password')}
                      </a>
                    </div>
                  )}
                  {viewState !== 'country' && (
                    <div>
                      <BackButton
                        className={getBackButtonClassName()}
                        text={t('general.goBack')}
                        onClick={() => {
                          if (viewState == 'username') {
                            setViewState('country')
                            setUsernameMode('mobile')
                          } else if (viewState == 'password') {
                            setViewState('username')
                          } else if (viewState == 'enterCode') {
                            setViewState('username')
                          }
                        }}
                      />
                    </div>
                  )}

                  {loginChoices && (
                    <ModalRegular
                      onClose={() => setLoginChoices(null)}
                      isOpen={loginChoices ? true : false}
                      bgColor={'bg-eventsMain'}
                      closeColor="white"
                    >
                      <div className="p-4 bg-eventsMain text-white text-center">
                        {t('login.whereDoYouWantLogin')}
                      </div>
                      <div className="p-8 grid grid-cols-1 gap-4">
                        {loginChoices.map((choice: any) => {
                          return (
                            <div
                              className="flex-1 w-full p-4 border  hover:bg-blue-50 shadow-md cursor-pointer border-gray-400 rounded-2xl"
                              onClick={async () => {
                                await setChosenLogin(choice.id)
                                submitForm()
                                setLoginChoices(null)
                              }}
                            >
                              {choice.name}
                            </div>
                          )
                        })}
                      </div>
                    </ModalRegular>
                  )}
                </Form>
              )
            }}
          </Formik>
        </div>

        {idpWaitingToSignIn && <Loading />}
        {bankIdWaitingForSign && (
          <>
            <div className="fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.6)] z-10 flex justify-center">
              <div className="w-full max-w-screen-md m-auto bg-white align-middle items-center text-center p-10 rounded-xl">
                <div className="w-full justify-center text-center">
                  <img
                    className={
                      'w-full max-w-[200px] object-contain mb-8 ml-auto mr-auto'
                    }
                    src={process.env.PUBLIC_URL + '/images/hand.png'}
                    alt="tyra logo"
                  />
                  <p className="text-2xl font-bold mb-5">
                    {t('login.signInToTyra')}
                  </p>
                  <div className="w-full max-w-[200px] object-contain mb-8 ml-auto mr-auto">
                    {qrCode ? (
                      <>
                        {verifying ? (
                          <>
                            <p className="text-eventsMain mb-5">
                              {t('login.verifying')}
                            </p>
                            <Spinner />
                          </>
                        ) : (
                          <Base64Image
                            base64={qrCode ? qrCode : ''}
                            altText=""
                          />
                        )}
                      </>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                  <p className="mb-5 text-gray-600">{t('login.openBankId')}</p>

                  <Button
                    variant="fullRed"
                    className="mt-5"
                    label={t('general.cancel')}
                    onClick={async () => {
                      await pollLoginSession(
                        loginSessionId!,
                        LoginAction.PollBankId,
                        undefined,
                        undefined,
                        false,
                        true,
                      )
                        .then(async (response) => {})
                        .catch((e) => {
                          throw e
                        })
                      setBankIdWaitingForSign(false)
                      setQrCode(null)
                      clearInterval(myTimeout)
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {/*bankIdWaitingForSign && (
          <>
            <div className="fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.6)] z-10 flex justify-center">
              <div className="w-full max-w-screen-md m-auto bg-white align-middle items-center text-center p-10 rounded-xl">
                <div className="w-full justify-center">
                  <img
                    className={
                      'w-full max-w-[200px] object-contain mb-8 ml-auto mr-auto'
                    }
                    src={process.env.PUBLIC_URL + '/images/hand.png'}
                    alt="tyra logo"
                  />
                  <p className="text-2xl font-bold mb-5">
                    {t('login.signInToTyra')}
                  </p>
                  <p className="mb-5 text-gray-600">{t('login.openBankId')}</p>
                  <p className="text-eventsMain mb-5">{t('login.verifying')}</p>
                  <Spinner />
                  <Button
                    variant="fullRed"
                    className="mt-5"
                    label={t('general.cancel')}
                    onClick={async () => {
                      await pollLoginSession(
                        loginSessionId!,
                        LoginAction.PollBankId,
                        undefined,
                        undefined,
                        false,
                        true,
                      )
                        .then(async (response) => {})
                        .catch((e) => {
                          throw e
                        })
                      setBankIdWaitingForSign(false)
                      clearInterval(myTimeout)
                    }}
                  />
                </div>
              </div>
            </div>
          </>
                  )*/}

        <ResetPasswordModal
          showModal={showModal}
          onClose={() => {
            setShowModal(false)
          }}
        />
      </div>

      {process.env.REACT_APP_PUBLIC_URL?.includes('localhost') &&
        termsOfUse && (
          <ModalRegular
            title="Användaravtal"
            isOpen={termsOfUse}
            onClose={() => setTermsOfUse(false)}
            bgColor="bg-eventsMain p-4"
            closeColor="white"
          >
            <div className="p-4">
              {loginArea === TERRITORY.SPAIN ? (
                <>
                  <p>
                    <strong>
                      Términos de uso y condiciones para el uso de TYRA
                    </strong>
                  </p>
                  <p>
                    <strong>1. General</strong>
                  </p>
                  <p>
                    Bayou AB (&ldquo;Bayou&rdquo;) provee del servicio Tyra
                    (&ldquo;Tyra&rdquo;). Tyra es una herramienta diseñada para
                    el uso de escuelas infantiles (la &ldquo;Escuela&rdquo;) y
                    padres cuyos hijos asistan a esa escuela. La
                    Escuela&nbsp;puede informar a los padres a través de la
                    aplicación y almacenar información necesaria sobre los niños
                    (el &ldquo;Servicio&rdquo;). Desde que la Escuela comienza
                    el acuerdo con Bayou acerca del uso de Tyra, usted como
                    padre ya tiene la oportunidad de acceder al Servicio.
                  </p>
                  <p>
                    Al instalar o utilizar el Servicio, como padre, usted entra
                    en un contrato legal vinculante con Bayou, en lo que
                    respecta al uso de Tyra. Los términos generales y
                    condiciones fijados abajo (los &ldquo;Términos&rdquo;) se
                    aplican en el momento de la instalación y uso del Servicio.
                    Por favor, lea atentamente los Términos antes de aceptarlos
                    y empezar a utilizar el Servicio.
                  </p>
                  <p>
                    Al aceptar los Términos, usted obtiene el derecho a usar el
                    software del que la aplicación consta en conformidad con
                    estos Términos. Si usted no cumple con dichos Términos,
                    Bayou se reserva el derecho a retirarle el uso del Servicio.
                  </p>
                  <p>
                    <strong>2. Licencia</strong>
                  </p>
                  <p>
                    2.1&nbsp;&nbsp;&nbsp; Todos los derechos, no explícitamente
                    concedidos bajo estos Términos en su forma actual o
                    adicional, están retenidos por Bayou. El material que
                    contiene la aplicación puede estar protegido por propiedad
                    intelectual y eventualmente pertenecer a una tercera
                    entidad. Estos Términos no autorizan a nadie a utilizar
                    dicho material.
                  </p>
                  <p>
                    2.2&nbsp;&nbsp;&nbsp; Su derecho a usar Tyra requiere que la
                    Escuela haya llegado a un acuerdo con Bayou. Si el acuerdo
                    entre la Escuela y Bayou se rompe, usted dejará de tener
                    derecho a utilizar Tyra.
                  </p>
                  <p>
                    <strong>3. Uso permitido</strong>
                  </p>
                  <p>
                    3.1&nbsp;&nbsp;&nbsp; El contrato firmado por la Escuela
                    implica el derecho a descargar, instalar, crear un usuario,
                    y usar Tyra en teléfono móvil, tablet, o cualquier otro
                    dispositivo preparado para ello (a partir de ahora referidos
                    como &ldquo;Dispositivos&rdquo;). Usted no debe intentar
                    copiar, descompilar, deconstruir, desarmar, derivar el
                    código, descifrar, modificar, o crear productos derivados
                    basados total o parcialmente en el software de la aplicación
                    o de los servicios dentro de ella, exceptuando lo
                    establecido en la legislación obligatoria, aquellos Términos
                    y/o términos contractuales respecto a cualquier componente
                    basado en un código abierto.
                  </p>
                  <p>
                    3.2&nbsp;&nbsp;&nbsp; Usted no debe usar el Servicio de
                    manera ilegal, así que usted se compromete a cumplir con
                    todas las leyes, normas y guías aplicables.&nbsp;Por ejemplo
                    la Ley de protección de datos personales, RGPD y la ley
                    sobre la propiedad intelectual.
                  </p>
                  <p>
                    3.3&nbsp;&nbsp;&nbsp; Usted no debe publicar contenido
                    discriminatorio, ilegal, humillante o que constituya una
                    infracción de los derechos de otras personas. Tyra no debe
                    ser usada de manera que implique difamación, bullying,
                    persecución o violación de la dignidad de otra persona.
                  </p>
                  <p>
                    3.4&nbsp;&nbsp;&nbsp; Los padres del niño, guardianes
                    legales u otros usuarios tienen que vigilar el uso del niño
                    de la aplicación.
                  </p>
                  <p>
                    3.5&nbsp;&nbsp;&nbsp; Si usted usa Tyra de manera que viole
                    alguno de estos Términos, o le da a Tyra cualquier otro uso
                    indebido, Bayou tiene el derecho de cancelar su cuenta de
                    manera unilateral y de bloquearle frente a futuros usos de
                    Tyra. Además, Bayou puede elegir retirar, editar, bloquear o
                    manejar de otra manera cualquier contenido, si Bayou
                    considera que puede estar violando estos Términos.
                  </p>
                  <p>
                    <strong>4. Limitación de la responsabilidad etc.</strong>
                  </p>
                  <p>
                    4.1&nbsp;&nbsp;&nbsp; Usted es consciente y está de acuerdo
                    en que Tyra se provee &ldquo;tal cual&rdquo;. A no ser que
                    sea requerido por legislación obligatoria, Bayou no se
                    responsabiliza de ninguna lesión personal, daño directo o
                    indirecto, o pérdidas consecuentes no relacionadas con Bayou
                    o cualquier otra circunstancia dentro del control de Bayou.
                  </p>
                  <p>
                    4.2&nbsp;&nbsp;&nbsp; Usted accede a defender y a mantener a
                    Bayou indemne frente a cualquier reclamación de terceras
                    partes (incluyendo pero no limitado a costes&nbsp;legales
                    razonables) que surjan de, estén conectadas con su
                    inclumplimiento de los Términos o de cualquier ley,
                    regulación o derecho de terceras partes.
                  </p>
                  <p>
                    <strong>5. Tratamiento de los datos personales</strong>
                  </p>
                  <p>
                    5.1&nbsp;&nbsp;&nbsp; La Escuela es responsable del
                    tratamiento tanto de sus datos personales como de los de su
                    hijo, cuando estos datos son recogidos por la Escuela, o
                    cuando usted como padre envía la información a través de
                    Tyra a la Escuela. Como responsable del tratamiento, la
                    Escuela es responsable de la protección de la integridad de
                    sus datos y los de su hijo, de acuerdo con Reglamento (UE)
                    2016/679 del Parlamento Europeo y del Consejo, de 27 de
                    abril de 2016, relativo a la protección de las personas
                    físicas en lo que respecta al tratamiento de sus datos
                    personales y a la libre circulación de estos datos y por el
                    que se deroga la Directiva 95/46/CE (Reglamento general de
                    protección de datos), y de la Ley Orgánica 3/2018, 5
                    Diciembre, sobre protección de datos personales y garantía
                    de derechos digitales, la Escuela en su capacidad de
                    responsable del tratamiento, está obligado a informarle a
                    usted como padre que tanto sus datos como los de su hijo son
                    recogidos, qué datos son almacenados, el motivo del
                    almacenaje y para qué son utilizados esos&nbsp;datos.
                  </p>
                  <p>
                    5.2&nbsp;&nbsp;&nbsp; Bayou es el procesador de datos
                    personales de la Escuela. Como procesador, Bayou solamente
                    puede procesar los datos de la manera especificada en el
                    contrato establecido entre Bayou y la Escuela, y solamente
                    de acuerdo con las instrucciones de procesamiento de
                    personal provistas por La Escuela a Bayou. Los datos
                    personales procesados o almacenados relacionados con el
                    registro y uso de Tyra son: nombre, dirección, número
                    personal, alergias, ausencias, imágenes y otros datos
                    personales provistos en Tyra&nbsp;en relación con los niños
                    de La Escuela, sus padres, otros parientes y personal de la
                    Escuela.
                  </p>
                  <p>
                    5.3&nbsp;&nbsp;&nbsp; El propósito de la recolección y
                    procesamiento de datos personales en Tyra es para que usted,
                    como padre, pueda registrarse como usuario y ser capaz de
                    utilizar el Servicio de manera adecuada. Bayou solamente
                    puede utilizar sus datos y los de su hijo para este
                    propósito y solamente durante el tiempo necesario que le
                    permita a usted usar el Servicio.
                  </p>
                  <p>
                    5.4&nbsp;&nbsp;&nbsp; Bayou deberá proveer la documentación
                    acerca de sus datos personales si la Escuela o usted como
                    padre la solicitas. Usted como padre tiene derecho a
                    solicitar que se elimine su información, o rectifique
                    información incorrecta directamente a Bayou. Si usted como
                    padre desea recibir información acerca de los datos
                    personales procesados por Bayou en nombre de la Escuela, o
                    solicitar la rectificación de datos, dicha solicitud se debe
                    hacer por escrito a La Escuela o directamente a Bayou y debe
                    contener su nombre, número personal, dirección y debe estar
                    firmada por usted. Si quiere transmitir la solicitud
                    directamente a Bayou, esta debe ser enviada a: Bayou, Paseo
                    de la Castellana 121, 28046 Madrid o por email a
                    hello@tyra.io
                  </p>
                  <p>
                    5.5&nbsp;&nbsp;&nbsp; Bayou le ha dado a la Escuela
                    suficientes garantías para implementar las medidas técnicas
                    y organizativas apropiadas para que el procesamiento de
                    datos cumpla con los requerimientos de la Regulación General
                    de Protección de Datos o cualquier otra regulación aplicable
                    y para asegurar que tanto sus derechos como los de su hijo
                    están protegidos.
                  </p>
                  <p>
                    5.6&nbsp;&nbsp;&nbsp; Bayou almacena los datos relativos a
                    usted como usuario de manera que asegura que se cumplen los
                    reglamentos presentes acerca del procesamiento de datos, y
                    que los datos se almacenan de manera segura. Bayou usa
                    Elastx&nbsp;para las operaciones técnicas de Tyra.
                    Elastx&nbsp;cumple los requisitos de la UE para el
                    procesamiento de datos personales. Si Elastx&nbsp;falla a la
                    hora de cumplir con los requisitos de la UE para el
                    procesamiento de datos, Bayou tiene el derecho de contratar
                    a otro subcontratista que asegure que los datos personales
                    se manejan con el nivel de protección adecuado y de acuerdo
                    con la ley.
                  </p>
                  <p>
                    5.7&nbsp;&nbsp;&nbsp; Bayou se compromete a asegurar que
                    todas las personas que trabajan para Bayou y que tengan
                    acceso a datos personales solo los procesan bajo las
                    instrucciones de la Escuela, salvo en caso que esas
                    instrucciones vayan contra la ley. Bayou no tiene derecho a
                    usar los datos personales de Tyra para sus propósitos
                    particulares. Bayou debe cumplir con las instrucciones de la
                    Escuela respecto a los datos personales de Tyra, siempre y
                    cuando estas no entren en conflicto con la ley. Bayou debe
                    informar inmediatamente a la Escuela si considera que alguna
                    de sus instrucciones puede ir en contra de la ley, y esperar
                    nuevas instrucciones por parte de La Escuela. Bayou debe
                    asistir a la Escuela si ésta así lo solicita cuando la
                    Escuela vaya a cumplir sus obligaciones legales, ej:
                    aquellas obligaciones que surgen del Capítulo 3, y Artículos
                    32-36 de la regulación de protección de datos.
                  </p>
                  <p>
                    5.8&nbsp;&nbsp;&nbsp; Bayou se compromete, teniendo en
                    cuenta el estado de la técnica, el coste de implementación y
                    la naturaleza, alcance, contexto y propósitos del
                    procesamiento, además de los riesgos de variar la
                    probabilidad y gravedad de los derechos y libertades de
                    personas naturales planteados por el procesamiento, a
                    implementar las medidas técnicas y organizativas adecuadas
                    para asegurar un nivel de seguridad acorde al riesgo,
                    incluyendo, cuando se deba:
                  </p>
                  <ul>
                    <li>
                      &nbsp;&nbsp;&nbsp;pseudonimización&nbsp;y encriptación de
                      datos personales (ej. sus datos personales serán
                      anonimizados o pseudonimizados&nbsp;cuando borre su
                      cuenta, pero los datos tienen que seguir siendo procesados
                      por la conexión con su hijo).
                    </li>
                    <li>
                      &nbsp;&nbsp;&nbsp;la capacidad de asegurar la constante
                      confidencialidad, integridad, disponibilidad y resiliencia
                      de los dispositivos y sistemas de procesamiento.
                    </li>
                    <li>
                      &nbsp;&nbsp;&nbsp;la capacidad de restaurar la
                      disponibilidad y acceso a datos personales revirtiendo
                      Tyra a una versión anterior, en el caso de un problema
                      físico o técnico fuera del control de Bayou.
                    </li>
                    <li>
                      &nbsp;&nbsp;&nbsp;un proceso de comprobación regular,
                      evaluando la efectividad de las medidas técnicas y
                      organizativas para asegurar la seguridad del proceso.
                    </li>
                  </ul>
                  <p>
                    5.9&nbsp;&nbsp;&nbsp; Si un niño, y/o usuario vinculado al
                    niño, no se conecta en un periodo mayor a seis meses, Bayou
                    se reserva el derecho a borrar el perfil.
                  </p>
                  <p>
                    5.10&nbsp;&nbsp;&nbsp; En el caso de que una Escuela
                    finalice un acuerdo, Bayou puede exportar todos los datos de
                    Tyra para La Escuela. Cuando Bayou deja de procesar los
                    datos personales de su hijo y suyos en nombre de La Escuela,
                    Bayou debe destruir y borrar cualquier dato relacionado con
                    el acuerdo iniciado entre Bayou y La Escuela. En el caso de
                    que un niño sea borrado en Tyra, existe la posibilidad de
                    exportar los datos a través de funciones de Tyra antes de
                    borrar el perfil.
                  </p>
                  <p>
                    5.11&nbsp;&nbsp;&nbsp; Después de que el contrato entre
                    Bayou y La Escuela se termine, por cualquiera de las partes
                    o por cualquier otra causa, este deja de ser válido. Los
                    derechos de Bayou de procesar o utilizar datos personales
                    pertenecientes a La Escuela también cesan. Bayou borrará
                    todos los datos personales procesados para la cuenta
                    pertenecientes a La Escuela, antes de que pasen 30 días
                    desde la finalización del contrato. Así, Bayou no tiene el
                    derecho de guardar ningún dato personal sobre usted o su
                    hijo (a menos que se requiera el almacenamiento de datos
                    personales de acuerdo con la legislación nacional o de la
                    Unión o el proveedor tenga una base legal para procesar los
                    datos personales relevantes).
                  </p>
                  <p>
                    <strong>6. Datos</strong>
                  </p>
                  <p>
                    Al aceptar estos Términos, usted está de acuerdo con que
                    Bayou pueda usar los datos registrados, direcciones, y mails
                    para la distribución de información relevante para el uso de
                    productos y servicios bajo estos Términos o el uso de otros
                    productos de Bayou. Bayou también puede utilizar la
                    información para enviar cuestionarios con el objetivo de
                    desarrollar Tyra de la mejor manera posible.
                  </p>
                  <p>
                    <strong>7. Otros</strong>
                  </p>
                  <p>
                    7.1&nbsp;&nbsp;&nbsp; Bayou se reserva el derecho de, en
                    cualquier momento, sin responsabilidad por daños y
                    perjuicios, de actualizar, mejorar, modificar, restringir
                    temporalmente o cerrar Tyra o su acceso a Tyra. Esto puede
                    ocurrir, por ejemplo, si usted retira su consentimiento para
                    procesar datos personales.
                  </p>
                  <p>
                    7.2&nbsp;&nbsp;&nbsp; Estos Términos pueden ser cambiados de
                    manera unilateral por Bayou si se le manda un mensaje claro
                    y conciso a través de Tyra. En el caso de que usted esté de
                    acuerdo con los nuevos Términos o continúe usando Tyra
                    después de que se hayan aplicado estos Términos, se
                    considerará que usted los ha aceptado. En caso contrario,
                    deberá desinstalar Tyra de todos sus dispositivos.
                  </p>
                  <p>
                    7.3&nbsp;&nbsp;&nbsp; Los Términos también se aplican a las
                    futuras actualizaciones de Tyra provistas por Bayou y que
                    reemplaza y/o constituye una adición a Tyra, siempre y
                    cuando esto no esté cubierto por un acuerdo por separado.
                  </p>
                  <p>
                    7.4&nbsp;&nbsp;&nbsp; Cualquier disputa surgida entre las
                    partes deberá ser resuelta en un tribunal general con Malmö
                    District Court como primera instancia y con la aplicación de
                    la ley sueca.
                  </p>
                  <p>
                    7.5&nbsp;&nbsp;&nbsp; Al aceptar estos Términos usted acepta
                    también el uso regular de la firma electrónica a distancia
                    entre usted y la Escuela para las comunicaciones y
                    consentimientos que esta última pueda necesitar.
                  </p>
                  <p>
                    <strong>8. Información de contacto</strong>
                  </p>
                  <p>
                    Bayou AB, 556983-2867
                    <br />
                    Paseo de la Castellana 121
                    <br />
                    28046 Madrid
                    <br />
                    España
                    <br />
                    <a href="mailto:hello@tyra.io">hello@tyra.io</a>
                  </p>
                </>
              ) : (
                <>
                  {' '}
                  <strong>
                    <p>
                      <>{t('settings.infoUserHeader')}</>
                    </p>
                  </strong>
                  <strong>
                    <p>
                      1. <>{t('settings.infoUserHeader1')}</>
                    </p>
                  </strong>
                  <p>
                    <>{t('settings.infoUserText1')}</>
                  </p>
                  <p>
                    <>{t('settings.infoUserText2')}</>
                  </p>
                  <p>
                    <>{t('settings.infoUserText3')}</>
                  </p>
                  <strong>
                    <p>
                      2. <>{t('settings.infoUserHeader2')}</>
                    </p>
                  </strong>
                  <p>
                    2.1 <>{t('settings.infoUserText4')}</>
                  </p>
                  <p>
                    2.2 <>{t('settings.infoUserText5')}</>
                  </p>
                  <strong>
                    <p>
                      3. <>{t('settings.infoUserHeader3')}</>
                    </p>
                  </strong>
                  <p>
                    3.1 <>{t('settings.infoUserText6')}</>
                  </p>
                  <p>
                    3.2 <>{t('settings.infoUserText7')}</>
                  </p>
                  <p>
                    3.3 <>{t('settings.infoUserText8')}</>
                  </p>
                  <p>
                    3.4 <>{t('settings.infoUserText9')}</>
                  </p>
                  <p>
                    3.5 <>{t('settings.infoUserText10')}</>
                  </p>
                  <strong>
                    <p>
                      4. <>{t('settings.infoUserHeader4')}</>
                    </p>
                  </strong>
                  <p>
                    4.1 <>{t('settings.infoUserText11')}</>
                  </p>
                  <p>
                    4.2 <>{t('settings.infoUserText12')}</>
                  </p>
                  <strong>
                    <p>
                      5. <>{t('settings.infoUserHeader5')}</>
                    </p>
                  </strong>
                  <p>
                    5.1 <>{t('settings.infoUserText13')}</>
                    <a href="mailto:hello@tyra.io">hello@tyra.io</a>{' '}
                    <>{t('settings.infoUserText14')}</>
                  </p>
                  <p>
                    5.2 <>{t('settings.infoUserText15')}</>
                  </p>
                  <strong>
                    <p>
                      6. <>{t('settings.infoUserHeader6')}</>
                    </p>
                  </strong>
                  <p>
                    6.1 <>{t('settings.infoUserText16')}</>
                  </p>
                  <p>
                    6.2 <>{t('settings.infoUserText17')}</>
                  </p>
                  <p>
                    6.3 <>{t('settings.infoUserText18')}</>
                  </p>
                  <strong>
                    <p>
                      7. <>{t('settings.infoUserHeader7')}</>
                    </p>
                  </strong>
                  Bayou AB, 556983-2867
                  <br />
                  Grisslevägen 19
                  <br />
                  227 32 Lund
                  <br />
                  <>{t('settings.infoUserCountry')}</>
                  <br />
                  <a href="mailto:hello@tyra.io">hello@tyra.io</a>
                </>
              )}
              <div className="flex justify-center gap-x-4">
                <Button
                  size="customSize"
                  variant="fullRed"
                  label={'Neka'}
                  onClick={() => {
                    setTermsOfUse(false)
                  }}
                />
                <Button
                  size="customSize"
                  variant="fullBlue"
                  label={'Acceptera'}
                  onClick={() => {
                    //Acceptera användaravtalet bool triggas
                    setTermsOfUse(false)
                    setFreeText(true)
                  }}
                />
              </div>
            </div>
          </ModalRegular>
        )}
      {process.env.REACT_APP_PUBLIC_URL?.includes('localhost') && freeText && (
        <ModalRegular
          isOpen={freeText}
          onClose={() => {
            setFreeText(false)
          }}
          bgColor="bg-eventsMain p-4"
          closeColor="white"
          title="Fri textfält"
        >
          <div className="p-4">
            <>{t('settings.freeTextFieldText')}</>
            <div className="flex justify-center gap-x-4 mt-4">
              <Button size="customSize" variant="fullRed" label={'Neka'} />
              <Button
                size="customSize"
                variant="fullBlue"
                label={'Acceptera'}
                onClick={() => {
                  //Acceptera fritextavtalet bool triggas
                  setFreeText(false)
                  setOpenUserInfo(true)
                }}
              />
            </div>
          </div>
        </ModalRegular>
      )}
      {process.env.REACT_APP_PUBLIC_URL?.includes('localhost') &&
        openUserInfo && (
          <ModalRegular
            isOpen={openUserInfo}
            onClose={() => {
              setOpenUserInfo(false)
            }}
            bgColor="bg-eventsMain p-4"
            closeColor="white"
            title="Användarinformation"
          >
            <div>
              {' '}
              <div className="flex flex-col p-4 bg-gray-50">
                <div className="flex flex-row w-full justify-center gap-y-4">
                  <ImageUploader size="lg" onImageChange={handleNewImage} />
                </div>
                <CustomInput
                  label={t('admin.firstName')}
                  required
                  onChange={(value) => {
                    setFakeState(value)
                  }}
                  placeholder={t('admin.firstName')}
                  //   ref={nameInputRef}
                />
                <CustomInput
                  required
                  label={t('admin.surName')}
                  onChange={(value) => {
                    setFakeState(value)
                  }}
                  placeholder={t('admin.surName')}
                  //    ref={surNameInputRef}
                />
                <CustomInput
                  label={t('login.email')}
                  onChange={(value) => {
                    setFakeState(value)
                  }}
                  format="default"
                  required={true}
                  placeholder={t('login.email')}
                  //ref={emailInputRef}
                />
                <CustomInput
                  label={t('ourKids.phone')}
                  onChange={(value) => {
                    setFakeState(value)
                  }}
                  format="default"
                  onlyNumbers={true}
                  placeholder={t('ourKids.phone')}
                  //    ref={numberInputRef}
                />
                <CustomInput
                  label={t('admin.otherNumber')}
                  onChange={(value) => {
                    setFakeState(value)
                  }}
                  format="default"
                  onlyNumbers={true}
                  placeholder={t('admin.otherNumber')}
                  //    ref={otherNumberInputRef}
                />
                <CustomInput
                  label={t('contactList.adress')}
                  onChange={(value) => {
                    setFakeState(value)
                  }}
                  format="default"
                  required
                  placeholder={t('contactList.adress')}
                  //      ref={adressInputRef}
                />
                <CustomInput
                  label={t('admin.areaCode')}
                  onChange={(value) => {
                    setFakeState(value)
                  }}
                  onlyNumbers={true}
                  format="default"
                  required
                  placeholder={t('admin.areaCode')}
                  //      ref={areaCodeInputRef}
                />
                <CustomInput
                  label={t('admin.city')}
                  onChange={(value) => {
                    setFakeState(value)
                  }}
                  format="default"
                  required
                  placeholder={t('admin.city')}
                  //      ref={cityInputRef}
                />
                <div className="flex flex-row mb-6">
                  <p className="mt-2">
                    <>{t('login.iAcceptTermsSwitch')}</>
                  </p>
                  <div className="flex flex-col items-end gap-y-2">
                    <FontAwesomeIcon
                      className="text-blue-400 hover:text-blue-500 cursor-pointer w-7 h-7"
                      icon={faInfoCircle}
                      onClick={() => {
                        setTermsOfUse(true)
                      }}
                    />
                    <Slider
                      id={0}
                      onSliderClick={function (id: number): void {
                        throw new Error('Function not implemented.')
                      }}
                      onBoxClick={function (id: number): void {
                        throw new Error('Function not implemented.')
                      }}
                    />
                  </div>
                </div>
                <div className="flex w-full justify-between mt-1">
                  <Button
                    size="sm"
                    variant="fullRed"
                    label={t('general.cancel')}
                    onClick={() => {
                      //setShowAddContact(false)
                    }}
                  />
                  <Button
                    size="sm"
                    variant="fullBlue"
                    label={t('general.save')}
                    onClick={() => {
                      setOpenUserInfo(false)
                      setOpenMyConnections(true)
                    }}
                  />
                </div>
              </div>
            </div>
          </ModalRegular>
        )}
      {process.env.REACT_APP_PUBLIC_URL?.includes('localhost') &&
        openMyConnections && (
          <ModalRegular
            isOpen={openMyConnections}
            onClose={() => {
              setOpenMyConnections(false)
            }}
            title={t('settings.myConnections')}
            bgColor="bg-eventsMain p-4"
            closeColor="white"
            className="overflow-auto"
          >
            {newInvite ? (
              <div className="flex w-full p-2 bg-yellow-400 justify-center font-semibold">
                <>{t('admin.waitingAccept')}</>
              </div>
            ) : (
              <div className="flex w-full p-2 bg-blue-400 justify-center font-semibold">
                <>{t('settings.newInvite')}</>
              </div>
            )}
            <div className="p-10">
              <div className="flex flex-wrap justify-center">
                {newInvite ? (
                  <InviteAuthCard
                    showMenuHeader={true}
                    hideSchedule={true}
                    active={false}
                  ></InviteAuthCard>
                ) : (
                  <InviteAuthCard
                    showMenuHeader={true}
                    hideSchedule={true}
                    active={false}
                    showButtons
                  ></InviteAuthCard>
                )}
              </div>
            </div>
          </ModalRegular>
        )}
    </>
  )
}

const SelectWhatUsername = ({
  onSelect,
  selected,
  countryCode,
}: {
  onSelect: any
  selected: string
  countryCode: string
}) => {
  const { t } = useTranslation()

  return (
    <div className={'flex flex-wrap mt-6  gap-x-2 mb-2 w-screen'}>
      <Button
        className={'w-24 sm:w-36 h-10 sm:h-12'}
        variant={selected === 'mobile' ? 'borderMain' : 'disableGray'}
        size="custom"
        label={t('login.mobile')}
        onClick={() => {
          onSelect('mobile')
        }}
      />
      <Button
        className={'w-24 sm:w-36 h-10 sm:h-12'}
        variant={selected === 'email' ? 'borderMain' : 'disableGray'}
        size="custom"
        label={t('login.email')}
        onClick={() => {
          onSelect('email')
        }}
      />
      {countryCode === 'SE' && (
        <Button
          className={'w-auto sm:w-auto h-10 sm:h-12'}
          variant={selected === 'bankid' ? 'borderMain' : 'disableGray'}
          size="custom"
          fontSize="text-sm"
          label={t('general.socialNumr')}
          onClick={() => {
            onSelect('bankid')
          }}
        />
      )}
    </div>
  )
}

const ResetPasswordModal = ({
  showModal,
  onClose,
}: {
  showModal: boolean
  onClose: any
}) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [successText, setSuccessText] = useState('')
  return (
    <div>
      <ModalRegular
        title={t('login.forgot_password')}
        bgColor="bg-red-300 p-4 justify-center"
        className={'max-w-xl'}
        isOpen={showModal}
        onClose={() => {
          onClose()
        }}
        closeColor="white"
      >
        <div className={'pt-10 flex-col p-4'}>
          <p className="flex pb-4">{successText}</p>
          {successText == '' && (
            <div className="flex flex-col gap-y-4">
              <div className="text-gray-800 text-sm">
                {t('login.forgot_password_txt')}
              </div>
              <input
                className={classNames(
                  'block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-full shadow appearance-none focus:outline-none focus:ring-eventsMain focus:border-eventsMain sm:text-sm',
                )}
                type={'text'}
                value={email}
                placeholder={t('login.email')}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />

              <div className="flex justify-center">
                <Button
                  size="custom"
                  variant="primary"
                  label={t('login.reset')}
                  onClick={async () => {
                    try {
                      await Parse.User.requestPasswordReset(email)
                      setSuccessText(
                        t('login.success_reset_password').replace('%@', email),
                      )
                      setEmail('')
                    } catch (error: any) {
                      alert('Error: ' + error.code + ' ' + error.message)
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </ModalRegular>
    </div>
  )
}

const SelectCountryDropdown: React.FC<{ onSelected: any; code: string }> = ({
  onSelected,
  code,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [menuRef, togglerRef] = useClickOutside(() => setIsOpen(false))
  const { t } = useTranslation()

  const getCountry = (code: string) => {
    if (code === 'SE') {
      return (
        <>
          <div className="w-[25px] rounded-full h-[25px] hover:scale-110 overflow-hidden text-center justify-center align-middle">
            <Flags.SE
              title="Svenska"
              className={classNames(
                'languageFlags',
                ' cursor-pointer w-[38px] h-[38px] mt-[-6px] ml-[-2.5px]',
                'hover:scale-110',
              )}
            />
          </div>
          <p className={'text-sm'}>Sweden</p>
        </>
      )
    } else {
      return (
        <>
          <div className="w-[25px] rounded-full h-[25px] hover:scale-110 overflow-hidden text-center justify-center align-middle">
            <Flags.ES
              title="Espanyol"
              className={classNames(
                'languageFlags',
                ' cursor-pointer w-[38px] h-[38px] mt-[-6px] ml-[-2.5px]',
                'hover:scale-125',
              )}
            />
          </div>
          <p className={'text-sm'}>Spain</p>
        </>
      )
    }
  }

  return (
    <div className="relative flex items-end ">
      <button
        ref={togglerRef}
        type="button"
        className="w-full group focus:outline-none rounded-full border bg-white shadow-xl border-none"
        id="options-menu-2"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="flex items-center justify-between w-full px-6 py-2">
          <div className={'flex space-x-2 items-center text-gray-500'}>
            {getCountry(code)}
          </div>
          <span className="flex items-center justify-between min-w-0">
            <div className=" group-hover:text-blue-50">
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </span>
        </div>
      </button>

      {isOpen && (
        <div
          ref={menuRef as RefObject<HTMLDivElement>}
          className="absolute left-0 z-50 origin-top divide-y w-full top-12 border-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu-2"
        >
          <ButtonDropdown.Menu>
            <ButtonDropdown.Section>
              <div
                onClick={() => {
                  onSelected('SE')
                  setIsOpen(false)
                }}
                className={
                  'flex space-x-2 items-center text-gray-500 p-3 hover:bg-blue-50 cursor-pointer'
                }
              >
                {getCountry('SE')}
              </div>
              <div
                onClick={() => {
                  onSelected('ES')
                  setIsOpen(false)
                }}
                className={
                  'flex space-x-2 items-center text-gray-500 p-3 hover:bg-blue-50 cursor-pointer'
                }
              >
                {getCountry('ES')}
              </div>
            </ButtonDropdown.Section>
          </ButtonDropdown.Menu>
        </div>
      )}
    </div>
  )
}

export type LoginFormValues = {
  username: string
  password: string
}

const LoginScheme = Yup.object().shape({})
