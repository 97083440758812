import {
  faChild,
  faChildReaching,
  faEllipsis,
  faInfo,
  faPlus,
  faTrash,
  faX,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { User } from './components/OverviewUsersList'
import { ListOfActiveUsers } from './components/ListOfActiveUsers'
import { useTranslation } from 'react-i18next'
import { HelpScection } from './components/HelpSection'

export interface IProps {
  presentKids: {
    users: User[]
    header: string
  }[]
  eventTimeId?: string
  refreshDetail: () => void
  departments?: string[]
  toggleMenu: (e: any) => void
  isOpen?: boolean
  openHelpSection: boolean
  setOpenHelpSection: (ishelpSectionOpen: boolean) => void
}
export const CircleMenu: React.FC<IProps> = ({
  presentKids,
  eventTimeId,
  refreshDetail,
  departments,
  toggleMenu,
  isOpen,
  openHelpSection,
  setOpenHelpSection,
}) => {
  const [chooseMany, setChooseMany] = useState(false)
  const [actionOnActiveUsers, setActionOnActiveUsers] = useState<
    string | null
  >()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const toggleChooseMany = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setActionOnActiveUsers(event.currentTarget.getAttribute('data-action'))
    setChooseMany(!chooseMany)
  }

  return (
    <div
      className={`flex flex-col-reverse items-start justify-center w-full h-full p-4`}
    >
      {!isOpen && (
        <div className="gap-4 flex flex-col-reverse items-center">
          <button
            onClick={toggleMenu}
            className={`w-14 h-14  rounded-full border border-blue-400 bg-white shadow-lg flex items-center justify-center mx-2 transition ease-in-out duration-700 hover:scale-110`}
          >
            <FontAwesomeIcon
              icon={faEllipsis}
              size="lg"
              className="text-blue-300"
            />
          </button>
        </div>
      )}
      {isOpen && (
        <div className="flex flex-col-reverse items-start justify-start  w-full gap-2 h-full animate-fadeIn">
          <button onClick={toggleMenu} className="flex items-center gap-2 ">
            <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
              <FontAwesomeIcon icon={faXmark} size="lg" />
            </div>
            <span className=" font-semibold">{t('general.close')}</span>
          </button>
          <button
            className="flex items-center gap-2 group"
            onClick={(e) => {
              e.stopPropagation()
              setOpenHelpSection(true)
            }}
          >
            <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
              <FontAwesomeIcon
                icon={faInfo}
                size="lg"
                className="text-yellowFav group-hover:animate-ping"
              />
            </div>
            <span className="text-yellowFav font-semibold">
              {t('events.helpSection')}
            </span>
          </button>
          <button
            className="flex items-center gap-2 group"
            onClick={toggleChooseMany}
            data-action="add-participant"
          >
            <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center ">
              <FontAwesomeIcon
                icon={faPlus}
                size="lg"
                className="text-[#1398F7] group-hover:animate-slideIn"
              />
            </div>
            <span className="text-[#1398F7] font-semibold">
              {t('events.add')}
            </span>
          </button>
          {presentKids?.map((dep) => dep.users).flat().length > 0 && (
            <>
              <button
                className="flex items-center gap-2 group"
                onClick={toggleChooseMany}
                data-action="remove-participant"
              >
                <div className="w-12 h-12 rounded-full  bg-white flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faTrash}
                    size="lg"
                    className="text-[#FF7171] group-hover:animate-wave"
                  />
                </div>
                <span className="text-[#FF7171] font-semibold">
                  {t('events.removeParticipants')}
                </span>
              </button>

              <button
                className="flex items-center gap-2 cursor-pointer group"
                onClick={toggleChooseMany}
                data-action="leaving-activity"
              >
                <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faChild}
                    size="lg"
                    className="text-[#FF8E8D] group-hover:animate-bounce"
                  />
                </div>
                <span className="text-[#FF8E8D] font-semibold">
                  {t('events.leavingActivity')}
                </span>
              </button>
              <button
                className="flex items-center gap-2 cursor-pointer group"
                onClick={toggleChooseMany}
              >
                <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faChildReaching}
                    size="lg"
                    className="text-[#6BBD73] group-hover:animate-fadeIn"
                  />
                </div>
                <span className="text-[#6BBD73] font-semibold">
                  {t('events.setAsPresent')}
                </span>
              </button>
            </>
          )}
        </div>
      )}
      {chooseMany && (
        <ListOfActiveUsers
          presentKids={presentKids}
          onClose={() => setChooseMany(false)}
          refreshDetail={() => refreshDetail()}
          eventTimeId={eventTimeId}
          dataAction={actionOnActiveUsers}
          departmentIds={departments}
          toggleMenu={toggleMenu}
          chooseMany={chooseMany}
        />
      )}
      {
        <HelpScection
          openHelpSection={openHelpSection}
          setOpenHelpSection={setOpenHelpSection}
        />
      }
    </div>
  )
}
