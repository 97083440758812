import React, { useContext, useEffect, useState } from 'react'
import {
  IEventActivitiesOverviewUser,
  IEventDetails,
  IEventInfoOverviewUser,
} from '../EventDetailPage'
import OverviewUserCard from './OverviewUserCard'
import { Button } from '../../../components/Button'
import { Event3StoreContext } from '../../../store/event3Store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { getFullMonthForTranslatation } from '../CreateEvent3'
import { EventListItemData } from '../Events3'
import { CircleMenu } from '../CircleMenu'
import { Department } from '../../../Models/Department'
import { DepartmentStoreContext } from '../../../store/departmentStore'

export interface IKidRelation {
  name: string
  thumbnail?: string
  relation?: string
  departmentId?: string
  departmentName?: string
  kidId?: string
  userId?: string
  roleId?: string
  isUser: boolean
}

export interface User
  extends IEventInfoOverviewUser,
    IEventActivitiesOverviewUser {
  isPresent?: boolean | undefined
  userRoleId?: string
  acceptedAt?: string
  acceptedBy?: string
  cancelledBy?: string
  missingDep?: boolean
  multipleDep?: boolean
}

interface DepartmentGroup {
  header: string
  users: User[]
}

const createDepTuplet = (
  departments?: Department[],
  users?: User[],
  departmentsFilter?: (string | undefined)[],
): DepartmentGroup[] => {
  const target: DepartmentGroup[] = []
  const depDict: Record<string, User[]> = {}
  const multiple: User[] = []
  const missing: User[] = []

  users?.forEach((user) => {
    if (user.departmentIds?.every((element) => element === null)) {
      missing.push(user)
    } else if (user.departmentIds.length > 1) {
      multiple.push(user)
    } else if (user.departmentIds.length > 0) {
      const klassName = departments?.find(
        (dep) => user.departmentIds[0] === dep.id,
      )?.klassName
      if (klassName) {
        if (!depDict[klassName]) {
          depDict[klassName] = []
        }
        depDict[klassName].push(user)
      }
    }
  })

  for (const [key, value] of Object.entries(depDict)) {
    if (departmentsFilter?.includes(key) || departmentsFilter?.length === 0)
      target.push({ header: key, users: value })
  }

  target.sort((a, b) => a.header.localeCompare(b.header))

  if (multiple.length > 0) {
    const multipleDeps = '!! Connected to several departments'
    if (departmentsFilter?.length === 0)
      target.push({ header: multipleDeps, users: multiple })

    const filteredUsers = multiple.filter((user) =>
      user.departmentIds?.some((department) =>
        departmentsFilter?.includes(department),
      ),
    )

    if (filteredUsers.length > 0) {
      target.push({ header: multipleDeps, users: filteredUsers })
    }
  }

  if (missing.length > 0) {
    const missingDep = 'Department is missing'
    target.push({ header: missingDep, users: missing })
  }

  return target
}

export const OverviewUsersList: React.FC<{
  filterDepartments?: (string | undefined)[]
  eventOverview?: IEventDetails
  eventDetail?: EventListItemData
  refreshDetail: () => void
  openHelpSection: boolean
  setOpenHelpSection: (ishelpSectionOpen: boolean) => void
}> = ({
  filterDepartments,
  eventOverview,
  eventDetail,
  refreshDetail,
  openHelpSection,
  setOpenHelpSection,
}) => {
  const [selected, setSelected] = useState<string>('allCount')
  const [usersList, setUsersList] = useState<DepartmentGroup[]>()
  const [groupedUsers, setGroupedUsers] = useState<
    DepartmentGroup[] | undefined
  >()
  const [isDisabled, setIsDisabled] = useState(false)

  const { sendReminder } = useContext(Event3StoreContext)
  const { departments } = useContext(DepartmentStoreContext)

  const { t } = useTranslation()

  const handleClick = (type: string) => {
    setSelected(type)
  }

  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = (e: any) => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  const getClassNames = (type: string) => {
    const isSelected = selected === type
    return `
    text-sm 
    font-bold 
    transition-colors  duration-300 ease-in-out
    ${
      isSelected && (selected === 'allCount' || selected === 'added')
        ? 'text-black border-b-4 border-[#7eb5f4]'
        : isSelected && selected === 'readCount'
        ? 'text-black border-b-4 border-[#6BBD73]'
        : isSelected && selected === 'unreadCount'
        ? 'text-black border-b-4 border-[#E1A6F0]'
        : 'text-gray-400'
    }  
    cursor-pointer
  `
  }

  const lastReminderDate = moment(eventOverview?.lastReminderSent)

  const month = getFullMonthForTranslatation(lastReminderDate.format('MM'))
  const formattedDateTime = lastReminderDate.format(
    `[Reminder sent] DD [${month}] YYYY [at] HH:mm`,
  )
  const unReadUsers =
    groupedUsers &&
    groupedUsers
      .map((department) => ({
        ...department,
        users: department.users.filter((user) => user.hasRead === false),
      }))
      .filter((users) => users.users.length > 0)
  const readUsers =
    groupedUsers &&
    groupedUsers
      .map((department) => ({
        ...department,
        users: department.users.filter((user) => user.hasRead !== false),
      }))
      .filter((users) => users.users.length > 0)

  const presentKids = createDepTuplet(
    departments,
    eventOverview?.presentKids,
    filterDepartments,
  )
    .map((department) => ({
      ...department,
      users: department.users.filter((user) => user.hasRead !== false),
    }))
    .filter((users) => users.users.length > 0)
  useEffect(() => {
    if (eventOverview?.lastReminderSent) {
      const lastReminderDate = moment(eventOverview.lastReminderSent)
      const now = moment()
      const hoursDifference = now.diff(lastReminderDate, 'hours')

      if (hoursDifference < 24) {
        setIsDisabled(true)
      }
    }
  }, [eventOverview, refreshDetail])

  useEffect(() => {
    setGroupedUsers(
      createDepTuplet(
        departments,
        [
          ...(eventOverview?.readUsers || []),
          ...(eventOverview?.unreadUsers || []),
        ],
        filterDepartments,
      ),
    )
  }, [filterDepartments, refreshDetail])

  useEffect(() => {
    if (selected === 'added') {
      handleClick('added')
      setUsersList(presentKids)
    } else if (
      !!eventDetail?.onlyStaff === false &&
      eventDetail?.type === 'activities' &&
      readUsers?.length &&
      readUsers.length >= 0
    ) {
      setSelected('readCount')
      setUsersList(readUsers)
    } else if (
      !!eventDetail?.onlyStaff === false &&
      eventDetail?.type === 'activities' &&
      readUsers?.length === 0
    ) {
      setSelected('unreadCount')
      setUsersList(unReadUsers)
    } else {
      setUsersList(groupedUsers)
    }
  }, [groupedUsers])
  const isTodayAfterEvent = moment().isAfter(
    moment(eventDetail?.eventDate),
    'day',
  )
  return (
    <div className="flex flex-col justify-between h-full relative ">
      <div className={` overflow-y-auto h-full scrollbar-hide  `}>
        <div
          className="flex justify-around items-center py-2 "
          onClick={(e) => e.stopPropagation()}
        >
          {((!!eventDetail?.onlyStaff === true &&
            eventDetail?.type === 'activities') ||
            eventDetail?.type !== 'activities') && (
            <p
              className={getClassNames('allCount')}
              onClick={(e) => {
                e.stopPropagation()
                handleClick('allCount')
                setUsersList(groupedUsers)
              }}
            >
              {' '}
              {t('documents.all')}
              {' ('}
              {groupedUsers?.map((dep) => dep.users).flat().length}
              {')'}
            </p>
          )}
          <p
            className={getClassNames('readCount')}
            onClick={(e) => {
              e.stopPropagation()
              handleClick('readCount')
              setUsersList(readUsers)
            }}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              className={`pr-2 ${selected === 'readCount' && 'text-[#6BBD73]'}`}
            />
            {t('documents.read')} {'('}
            {readUsers?.map((dep) => dep.users).flat().length}
            {')'}
          </p>
          <p
            className={getClassNames('unreadCount')}
            onClick={(e) => {
              e.stopPropagation()
              handleClick('unreadCount')
              setUsersList(unReadUsers)
            }}
          >
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className={`pr-2 ${
                selected === 'unreadCount' && 'text-[#E1A6F0]'
              }`}
            />
            {t('documents.unread')} {'('}
            {unReadUsers?.map((dep) => dep.users).flat().length}
            {')'}
          </p>
          {!!eventDetail?.onlyStaff === false &&
            eventDetail?.type === 'activities' && (
              <p
                className={getClassNames('added')}
                onClick={(e) => {
                  e.stopPropagation()
                  handleClick('added')
                  setUsersList(presentKids)
                }}
              >
                {t('events.added')} {'('}
                {presentKids?.map((dep) => dep.users).flat().length}
                {')'}
              </p>
            )}
        </div>
        {presentKids.length === 0 && selected === 'added' && (
          <div
            className={`flex flex-col h-[70%] text-gray-400 font-bold w-full justify-center`}
          >
            <p className="flex justify-around">{t('events.noChildrenAdded')}</p>
            <p className="flex justify-around text-sm">
              {t('events.circleMenuPosition')}
            </p>
          </div>
        )}
        <div className="flex flex-col ">
          {usersList?.map((group) => (
            <React.Fragment key={eventDetail?.eventTimeId}>
              <h2 className="pl-4 text-sm font-semibold">
                {group.header} ({group.users.length})
              </h2>
              <div key={group.header} className="mb-4 last:mb-24 px-4 w-full">
                <ul className={`flex flex-wrap justify-between`}>
                  {group.users.map((user) => {
                    return (
                      <li key={user.id} className="px-1 w-[49%]">
                        <OverviewUserCard
                          key={user.id}
                          user={user}
                          eventDetail={eventDetail}
                          refreshDetail={refreshDetail}
                          dataAction={
                            selected === 'added' ? 'add-participant' : ''
                          }
                        />
                      </li>
                    )
                  })}
                </ul>
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="flex w-full justify-center absolute bottom-6 bg-gradient-to-t from-[#F6F6F6]">
          {(selected === 'allCount' || selected === 'unreadCount') &&
            isTodayAfterEvent !== true && (
              <Button
                className="min-w-[90%] my-1.5"
                disabled={isDisabled}
                size="sm"
                fontSize="font-bold"
                variant="fullBlue"
                label={isDisabled ? formattedDateTime : t('absence.sendNotice')}
                onClick={async (e) => {
                  e.stopPropagation()
                  try {
                    await sendReminder(
                      eventDetail?.eventTimeId,
                      eventOverview?.unreadUsers.map((user) => user.userId),
                    )
                    refreshDetail()
                  } catch (error) {
                    console.error(error)
                  }
                }}
              />
            )}
        </div>
      </div>
      {selected === 'added' && (
        <div
          className={`flex absolute bottom-6 ${
            isOpen &&
            'backdrop-blur-sm animate-fadeIn ease-in-out absolute bottom-6 w-full h-[95%]'
          }`}
        >
          <CircleMenu
            presentKids={presentKids}
            eventTimeId={eventDetail?.eventTimeId}
            refreshDetail={refreshDetail}
            departments={eventDetail?.departmentIds}
            toggleMenu={toggleMenu}
            isOpen={isOpen}
            openHelpSection={openHelpSection}
            setOpenHelpSection={setOpenHelpSection}
          />
        </div>
      )}
    </div>
  )
}
