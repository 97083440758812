import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import React, { useContext, useRef, useState } from 'react'
import { EventListItemData } from '../Events3'
import { Button } from '../../../components/Button'
import { Event3StoreContext } from '../../../store/event3Store'
import { useTranslation } from 'react-i18next'
import { UserStoreContext } from '../../../store/userStore'
import { observer } from 'mobx-react-lite'
import ModalRegular from '../../../components/ModalRegular'
import Swal from 'sweetalert2'

interface PopupProps {
  eventDetail: EventListItemData | undefined
  onClose: () => void
  isOpen: boolean
  refreshDetail: () => void
}

const EventParticipatingPopUp: React.FC<PopupProps> = observer(
  ({ eventDetail, onClose, isOpen, refreshDetail }) => {
    const { t } = useTranslation()
    const popupRef = useRef<HTMLDivElement>(null)
    const { editBookingToEventTime } = useContext(Event3StoreContext)
    const [message, setMessage] = useState<string>(
      `${eventDetail?.ownParticipantsData?.message ?? ''}`,
    )
    const [extraParticipants, setExtraParticipants] = useState<number>(
      eventDetail?.ownParticipantsData?.extraParticipants ?? 0,
    )
    const [deleteMsg, setDeleteMsg] = useState<boolean>(false)
    const { acceptParticipatingInEvent, declineParticipatingInEvent } =
      useContext(Event3StoreContext)
    const { currentUserRole } = useContext(UserStoreContext)

    const initialLabel =
      eventDetail?.bookingStatus === 'attending'
        ? t('events.decline')
        : eventDetail?.bookingStatus === 'not_replied'
        ? t('general.cancel')
        : t('events.coming')
    const [label, setLabel] = useState(initialLabel)

    const handleChangeParticipants = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      event.stopPropagation()
      const value = event.target.value
      const parsedValue = parseInt(value, 10)
      if (!isNaN(parsedValue)) {
        setExtraParticipants(parsedValue)
      } else {
        setExtraParticipants(0)
      }
    }

    const checkLeftSeat =
      eventDetail?.maxParticipants!! - eventDetail?.statusSummary?.attending!!
    const handleClickOutside = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      event.stopPropagation()
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose()
      }
    }

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      event.stopPropagation()
      setMessage(event.target.value)
    }

    const handleLabel = async () => {
      // console.log(currentUserRole)

      if (label === t('general.cancel')) {
        onClose()
        return
      }
      setLabel((prevLabel) =>
        prevLabel === t('events.coming')
          ? t('events.decline')
          : t('events.coming'),
      )
    }

    const requestEdit = async (eventDetail: EventListItemData | undefined) =>
      await editBookingToEventTime(
        eventDetail?.eventTimeId,
        eventDetail?.ownParticipantsData.id,
        message,
        deleteMsg,
        extraParticipants,
      )

    const handleDone = async (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      event.stopPropagation()
      try {
        const eventTimeId = eventDetail?.eventTimeId
        const userId = currentUserRole?.user?.id ?? ''
        const kidId = currentUserRole?.kid?.id ?? ''
        const ownParticipantsDataId = eventDetail?.ownParticipantsData?.id
        const extraParticipantsCount =
          eventDetail?.ownParticipantsData?.extraParticipants ?? 0
        const currentMessage = eventDetail?.ownParticipantsData?.message ?? ''

        if (!eventTimeId) {
          console.error('Missing required IDs:', { eventTimeId, userId, kidId })
          return
        }

        if (
          (label === t('events.decline') || label === t('general.cancel')) &&
          eventDetail?.bookingStatus !== 'attending'
        ) {
          await acceptParticipatingInEvent(
            eventTimeId,
            [userId],
            [kidId],
            message,
            extraParticipants,
          )
          onClose()
          refreshDetail()
        } else if (label === t('events.coming')) {
          await declineParticipatingInEvent(eventTimeId, [userId], [kidId])
          await editBookingToEventTime(
            eventTimeId,
            ownParticipantsDataId,
            message,
            deleteMsg,
            0,
          )
          setExtraParticipants(0)
          onClose()
          refreshDetail()
        }

        if (extraParticipants > checkLeftSeat) {
          Swal.fire({
            text: `${checkLeftSeat} seat left`,
            icon: 'warning',
            showCancelButton: false,
            focusConfirm: false,
            showConfirmButton: false,
            confirmButtonColor: '#7eb5f4',
            cancelButtonColor: '#d33',
            //confirmButtonText: t('general.ok'),
            toast: true,
            timer: 3000,
            timerProgressBar: true,
            position: 'bottom',
            background: '#fffd8d',
          })
          return
        }

        if (
          extraParticipants !== extraParticipantsCount ||
          message !== currentMessage
        ) {
          await requestEdit(eventDetail)
          onClose()
          refreshDetail()
        }
      } catch (error) {
        console.error('Error during participation handling:', error)
      } finally {
        refreshDetail()
        onClose()
        refreshDetail()
      }
    }

    return (
      <div onClick={(e) => e.stopPropagation()}>
        <ModalRegular
          hideCloseButton
          isOpen={isOpen}
          onClose={onClose}
          className="p-4"
        >
          <h2 className="text-2xl font-bold mb-4 flex justify-center">
            {label === t('general.cancel')
              ? t('events.confirmRegistration')
              : t('events.changeRegistration')}
          </h2>
          {(label === t('events.decline') || label === t('general.cancel')) &&
            eventDetail?.eventFor === 'role_plus' && (
              <div className="p-4">
                <h3 className="font-bold mb-2">
                  {t('events.addParticipants')}
                </h3>
                <div className="flex items-center">
                  <p className="text-sm flex-grow pr-2">
                    {t('events.addParticipantsClarification')}
                  </p>
                  <input
                    type="string"
                    value={extraParticipants ?? ''}
                    placeholder={
                      eventDetail?.ownParticipantsData?.extraParticipants
                        ? `${eventDetail.ownParticipantsData?.extraParticipants}`
                        : '0'
                    }
                    onChange={handleChangeParticipants}
                    className="w-16 h-12 text-center text-lg border border-gray-300 rounded p-2 disabled:"
                  />
                </div>
              </div>
            )}
          <div className="flex justify-start items-center py-1">
            <p className="text-lg mr-2">
              {t('events.messageOnDe/RegistrstionOr')}
            </p>
            {message && (
              <Button
                variant="transparent"
                className="p-1"
                size="sm"
                icon={faTrashCan}
                iconColor="text-redWarning"
                onClick={(e) => {
                  e.stopPropagation()
                  setDeleteMsg(true)
                  setMessage('')
                }}
              />
            )}
          </div>
          <div className="flex items-center p-2 rounded-lg border border-gray-300 bg-gray-100  w-full ">
            <textarea
              placeholder={t('events.leaveAMessage') + '...'}
              className="bg-gray-100 outline-none w-full resize-none "
              value={message}
              onChange={handleChange}
              rows={3}
            />
          </div>
          <div className="flex justify-between mt-3 px-4">
            <Button
              size="lg"
              variant={
                label === t('events.coming') ? 'borderRed' : 'borderGreen'
              }
              label={label}
              onClick={handleLabel}
            />
            <Button
              size="lg"
              variant="fullBlue"
              label={
                label === t('general.cancel') ? 'confirm' : t('general.done')
              }
              onClick={handleDone}
            />
          </div>
        </ModalRegular>
      </div>
    )
  },
)

export default EventParticipatingPopUp
