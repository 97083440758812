import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { Button } from '../../../components/Button'
import { FreeTimesCard } from './FreeTimesCard'
import {
  Event3StoreContext,
  EventTimeSmallInterface,
  FreeTimesToBook,
} from '../../../store/event3Store'
import ModalRegular from '../../../components/ModalRegular'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

interface Props extends React.PropsWithChildren {
  onClose: () => void
  selectedTime?: EventTimeSmallInterface | null
  name?: string
  eventGroupInfoId: string
  setSelectedTime: (value: EventTimeSmallInterface) => void
  onDone: () => void
}

export const RebookTime: React.FC<Props> = observer(
  ({
    onClose,
    selectedTime,
    name,
    eventGroupInfoId,
    setSelectedTime,
    onDone,
  }) => {
    const { freeTimesToBook, getFreeTimesToBook } =
      useContext(Event3StoreContext)

    useEffect(() => {
      getFreeTimesToBook(eventGroupInfoId)
    }, [])

    const { t } = useTranslation()
    console.log('selectedTime', selectedTime)
    return (
      <ModalRegular
        isOpen={true}
        onClose={() => {
          onClose()
          /*setSelectedKid(undefined)
                  setSelectedTime(null)*/
        }}
        hideCloseButton
        className="max-w-[400px]"
      >
        <div className="p-6">
          <div className="flex w-full text-center font-bold text-lg justify-center">
            {t('events.reBook')}
          </div>
          <div className="flex w-full text-center font-bold text-sm justify-center mt-2">
            {name}
          </div>
          {freeTimesToBook?.length ? (
            <FreeTimesCard
              selectedTime={selectedTime}
              onClick={(value) => {
                if (value) {
                  setSelectedTime(value)
                }
              }}
              times={freeTimesToBook.map((fTTB: FreeTimesToBook) => {
                let time: EventTimeSmallInterface = {
                  start_isodate: fTTB.date,
                  stop_isodate: fTTB.date,
                  start_time: fTTB.startTime,
                  end_time: fTTB.endTime,
                  start_date: new Date(fTTB.date!),
                  stop_date: new Date(fTTB.date!),
                  id: fTTB.eventTimeId,
                }
                return time
              })}
            />
          ) : (
            <>Inga tider</>
          )}
          <div className="flex justify-between text-xs mt-6">
            <Button
              label={t('general.cancel')}
              variant="fullWhiteGrayText"
              onClick={() => {
                onClose()
                /*setSelectedKid(undefined)
                        setSelectedTime(null)*/
              }}
            />
            <Button
              label={t('events.confirm')}
              variant="fullBlue"
              disabled={selectedTime ? false : true}
              onClick={() => {
                onDone()
              }}
            />
          </div>
        </div>
      </ModalRegular>
    )
  },
)
