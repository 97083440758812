import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import CustomRadioButton from '../../../components/CustomRadioButton'
import CustomInput from '../../../components/CustomInput'
import { Button } from '../../../components/Button'
import {
  CreateEventParams,
  Event3StoreContext,
} from '../../../store/event3Store'
import ModalRegular from '../../../components/ModalRegular'
import { Location, LocationInterface } from '../../../Models/Location'
import { prop } from 'cheerio/lib/api/attributes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faLocationPin,
  faMapPin,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import { faLocationPen, faLocationPlus } from '@fortawesome/pro-solid-svg-icons'
import { LocationBox } from './LocationBox'
import { Slider } from '../../../components/Slider'
import { UserStoreContext } from '../../../store/userStore'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'

export enum LOCATION_TYPE {
  FREE_TEXT = 'freetext',
  COORD = 'coord',
  ADDRESS = 'address',
  SAVED = 'saved',
}

export const useEventPlaceSelections = () => {
  const { t } = useTranslation()

  return [
    { value: LOCATION_TYPE.ADDRESS, label: t('events.addAdress') },
    { value: LOCATION_TYPE.FREE_TEXT, label: t('events.freeTextPlace') },
    { value: LOCATION_TYPE.COORD, label: t('events.coordinates') },
    { value: LOCATION_TYPE.SAVED, label: t('events.chooseSavedPlace') },
  ]
}

interface Props {
  value: string
  onChange: (e: CreateEventParams) => void
  newEvent: CreateEventParams
  onDone: () => void // hämta värdena och visa från riktig db
  onSave: () => void // spara värdena
  onClose: () => void
  locations: Location[]
}

const initialNewLocationValues: LocationInterface = {
  id: undefined,
  type: undefined,
  shared: undefined,
  name: undefined,
  lat: undefined,
  long: undefined,
  city: undefined,
  address: undefined,
  zipcode: undefined,
}

export const SavedLocationSlider: React.FC<{
  locations: Location[]
}> = ({ locations }) => {
  const [saveLocationShared, setSavedLocationShared] = useState(true)
  const { currentUserRole } = useContext(UserStoreContext)
  const [selectedLocation, setSelectedLocation] =
    useState<LocationInterface | null>()
  const { archiveLocation, fetchLocations, shareLocation } =
    useContext(Event3StoreContext)

  const { schoolId } = useParams()

  return (
    <div>
      {selectedLocation ? (
        <>
          <LocationBox
            location={selectedLocation}
            onSaveLocationModal={() => {}}
            hideOnSave={true}
            onRemove={() => {
              if (selectedLocation.id && schoolId) {
                archiveLocation(selectedLocation.id).finally(() => {
                  fetchLocations([schoolId]).finally(() => {
                    setSelectedLocation(null)
                  })
                })
              }
            }}
          />
          <div className="flex mt-2 items-center justify-center gap-x-2">
            <div className="mb-2 font-semibold">
              <>{t('events.sharePlace')}</>
            </div>
            <Slider
              disabled={
                selectedLocation.ownerId === currentUserRole?.id ? false : true
              }
              id={0}
              onSliderClick={() => {
                if (selectedLocation.id && schoolId) {
                  shareLocation(
                    selectedLocation.id,
                    selectedLocation.shared === 'private'
                      ? 'shared'
                      : 'private',
                  ).finally(() => {
                    fetchLocations([schoolId]).finally(() => {
                      setSelectedLocation({
                        ...selectedLocation,
                        shared:
                          selectedLocation.shared === 'private'
                            ? 'shared'
                            : 'private',
                      })
                      //setSelectedLocation(null)
                    })
                  })
                }
              }}
              onBoxClick={() => {}}
              value={selectedLocation.shared === 'private' ? false : true}
            />
          </div>
        </>
      ) : (
        <div className="p-2 text-center font-semibold text-gray-400">
          <>{t('events.noPlaceChoosen')}</>
        </div>
      )}
      <div className={'flex relative pt-2'}>
        <div>
          <div
            className="absolute left-4 top-[45px] z-20 bg-eventsMain h-1 transition-all duration-500 ease-in-out"
            style={{
              width: 'calc(50%)',
              left: saveLocationShared ? 'calc(0%)' : 'calc(50%)',
            }}
          ></div>
        </div>
        <div
          className={classNames(
            'flex-1 flex relative justify-center  z-10 items-center transition-all duration-300 ease-in-out border-b font-medium hover:bg-blue-50  h-[40px] borde-b cursor-pointer',
            saveLocationShared && '',
          )}
          onClick={() => {
            setSavedLocationShared(true)
          }}
        >
          <p
            className={classNames(
              saveLocationShared && 'text-eventsMain font-medium',
            )}
          >
            <>{t('events.sharedPlaces')}</>
          </p>
        </div>
        <div
          className={classNames(
            'flex-1 flex justify-center items-center transition duration-200 ease-in border-b  font-medium hover:bg-blue-50 h-[40px] cursor-pointer',
            !saveLocationShared && ' border-b-eventsMain',
          )}
          onClick={() => {
            setSavedLocationShared(false)
          }}
        >
          <p
            className={classNames(
              !saveLocationShared && 'text-eventsMain font-medium ',
            )}
          >
            <>{t('events.myPlaces')}</>
          </p>
        </div>
      </div>

      <div className="flex flex-col p-4 gap-y-2">
        {locations
          .filter((loc) => {
            if (saveLocationShared) {
              return loc.shared && loc.shared === 'shared'
            } else {
              return (
                loc.shared &&
                loc.shared === 'private' &&
                loc.owner?.id === currentUserRole?.id
              )
            }
          })
          .map((location) => {
            return (
              <div
                className={classNames(
                  'rounded-xl border p-3 flex justify-between',
                  selectedLocation?.id === location.id
                    ? 'border-orange-300'
                    : 'border-gray-700',
                )}
                onClick={() => {
                  if (selectedLocation?.id === location.id) {
                    setSelectedLocation(null)
                  } else {
                    setSelectedLocation(location.asJson())
                  }

                  //setNewLocationDone(true)
                  /*props.onChange({
                        ...props.newEvent,
                        location: location.asJson(),
                      })*/
                }}
              >
                <div className="flex flex-row w-full gap-x-2">
                  <div>
                    <FontAwesomeIcon
                      icon={faMapPin}
                      className="text-eventsMain"
                    />
                  </div>
                  <div> {location.name}</div>
                </div>
                <div
                  className={classNames(
                    'w-6 h-6 rounded-full border border-orange-300 justify-center flex items-center',
                    selectedLocation?.id === location.id ? 'bg-orange-300' : '',
                  )}
                >
                  {selectedLocation?.id === location.id && (
                    <FontAwesomeIcon color="#ffffff" icon={faCheck} />
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export const CreateLocation: React.FC<Props> = observer((props: Props) => {
  const eventPlaceSelections = useEventPlaceSelections()
  const nameInputRef = useRef<HTMLInputElement>(null)
  const streetInputRef = useRef<HTMLInputElement>(null)
  const zipInputRef = useRef<HTMLInputElement>(null)
  const cityInputRef = useRef<HTMLInputElement>(null)
  const [openModal, setOpenModal] = useState(false)
  const [locationType, setLocationType] = useState<LOCATION_TYPE>(
    props.newEvent.locationType,
  )
  const [newLocation, setNewLocation] = useState<LocationInterface>()
  const [saveLocationShared, setSavedLocationShared] = useState(true)
  const [locationsToShow, setLocationsToShow] = useState<Location[]>([])
  const [newLocationDone, setNewLocationDone] = useState(false)
  const [saveLocationModal, setSaveLocationModal] = useState(false)
  const [saveShareToSchool, setSaveShareToSchool] = useState(false)
  const [showEditLocationsModal, setShowEditLocationModal] = useState(false)
  const { schoolId } = useParams()

  const { saveLocation, fetchLocations } = useContext(Event3StoreContext)

  const { currentUserRole } = useContext(UserStoreContext)
  const { t } = useTranslation()

  useEffect(() => {
    // Lägg till filter här på vad som ska visas
    setLocationsToShow(props.locations)
  }, props.locations)
  return (
    <>
      <div className="flex justify-end relative">
        <Button
          icon={faLocationPen}
          className="rounded-full absolute top-[-15px] right-[-15px]"
          onClick={() => {
            setShowEditLocationModal(true)
          }}
        />
      </div>
      <ModalRegular
        title={t('events.editPlace')}
        isOpen={showEditLocationsModal}
        onClose={() => {
          setShowEditLocationModal(false)
        }}
        //className="p-10"
        closeColor="white"
        bgColor="bg-eventsMain p-4"
        hideCloseButton
      >
        <div className="px-10 py-2">
          <SavedLocationSlider locations={props.locations} />
          <div className="flex justify-center p-4">
            <Button
              size="sm"
              label={t('general.done')}
              variant={'fullBlue'}
              onClick={async () => {
                setShowEditLocationModal(false)
              }}
            />
          </div>
        </div>
      </ModalRegular>
      <CustomRadioButton
        title=""
        options={eventPlaceSelections}
        onChange={(value) => {
          //props.onChange({ ...props.newEvent, locationType: value })
          if (value === LOCATION_TYPE.SAVED) {
            props.onChange({ ...props.newEvent, locationType: value })
          } else {
            setOpenModal(true)
          }
          setLocationType(value)
          setNewLocation({ ...initialNewLocationValues, type: value })
          setNewLocationDone(false)
        }}
        value={props.value}
      />
      <ModalRegular
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
      >
        <div className="p-10 flex flex-col gap-4">
          {locationType !== LOCATION_TYPE.SAVED && (
            <CustomInput
              required
              label={t('documents.name')}
              ref={nameInputRef}
              value={newLocation?.name}
              onChange={(value) => {
                setNewLocation({ ...newLocation, name: value })
                /*props.onChange({
                ...props.newEvent,
                location: { ...props.newEvent.location, name: value },
              })*/
              }}
            />
          )}
          {locationType === LOCATION_TYPE.COORD && (
            <div className="flex flex-col gap-y-4">
              <div>
                <div className="flex flex-row gap-x-2 ml-2">
                  {(!newLocation?.lat || newLocation.lat === 0) && (
                    <div className=" text-red-500 ">*</div>
                  )}
                  <label className="text-sm font-bold mb-2">
                    <>{t('events.latitude')}</>
                  </label>
                </div>
                <input
                  type="number"
                  inputMode="numeric"
                  placeholder={t('events.exLatPlaceholder')}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={
                    newLocation?.lat ? String(newLocation?.lat) : undefined
                  }
                  onChange={(value) => {
                    setNewLocation({
                      ...newLocation,
                      lat: Number(value.target.value),
                    })
                  }}
                  /*onChange={(value) => {
                props.onChange({
                  ...props.newEvent,
                  location: { ...props.newEvent.location, lat: Number(value) },
                })
              }}*/
                />
              </div>

              <div>
                <div className="flex flex-row gap-x-2 ml-2">
                  {(!newLocation?.long || newLocation.long === 0) && (
                    <div className=" text-red-500 ">*</div>
                  )}
                  <label className="text-sm font-bold mb-2">
                    <>{t('events.longitude')}</>
                  </label>
                </div>

                <input
                  placeholder={t('events.exLongPlaceholder')}
                  inputMode="numeric"
                  type="number"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={
                    newLocation?.long ? String(newLocation?.long) : undefined
                  }
                  onChange={(value) => {
                    setNewLocation({
                      ...newLocation,
                      long: Number(value.target.value),
                    })
                  }}
                />
              </div>
            </div>
          )}
          {locationType === LOCATION_TYPE.FREE_TEXT && <></>}
          {locationType === LOCATION_TYPE.ADDRESS && (
            <>
              <CustomInput
                label={t('contactList.adress')}
                required
                ref={streetInputRef}
                value={newLocation?.address}
                onChange={(value) => {
                  setNewLocation({ ...newLocation, address: value })
                }}
                /*onChange={(value) => {
                props.onChange({
                  ...props.newEvent,
                  location: { ...props.newEvent.location, address: value },
                })
              }}*/
              />
              <CustomInput
                label={t('admin.areaCode')}
                onlyNumbers
                required
                ref={zipInputRef}
                value={newLocation?.zipcode}
                onChange={(value) => {
                  setNewLocation({ ...newLocation, zipcode: value })
                }}
                /*value={props.newEvent.location?.zipcode}
              onChange={(value) => {
                props.onChange({
                  ...props.newEvent,
                  location: { ...props.newEvent.location, zipcode: value },
                })
              }}*/
              />
              <CustomInput
                label={t('admin.city')}
                required
                ref={cityInputRef}
                value={newLocation?.city}
                onChange={(value) => {
                  setNewLocation({ ...newLocation, city: value })
                }}
                /*value={props.newEvent.location?.city}
              onChange={(value) => {
                props.onChange({
                  ...props.newEvent,
                  location: { ...props.newEvent.location, city: value },
                })
              }}*/
              />
            </>
          )}
          <div className="flex flex-row justify-between">
            <Button
              size="sm"
              variant={'borderRed'}
              label={t('general.cancel')}
              onClick={() => {
                setNewLocation(undefined)
                setOpenModal(false)
              }}
            />
            <Button
              size="sm"
              label={t('general.done')}
              variant={'fullBlue'}
              onClick={async () => {
                /*await props.onChange({
              ...props.newEvent,
              location: newLocation,
              locationType: locationType,
            })*/
                if (locationType === LOCATION_TYPE.ADDRESS) {
                  if (
                    !newLocation?.name ||
                    !newLocation.address ||
                    !newLocation.zipcode ||
                    !newLocation.city
                  ) {
                    Swal.fire({
                      text: t('events.addInfoLocationText'),
                      icon: 'error',
                      showCancelButton: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                      cancelButtonText: t('general.cancel'),
                    })
                    return
                  }
                } else if (locationType === LOCATION_TYPE.FREE_TEXT) {
                  if (!newLocation?.name) {
                    Swal.fire({
                      text: t('events.addNameLocationText'),
                      icon: 'error',
                      showCancelButton: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                      cancelButtonText: t('general.cancel'),
                    })
                    return
                  }
                } else if (locationType === LOCATION_TYPE.COORD) {
                  if (
                    !newLocation?.name ||
                    !newLocation.lat ||
                    !newLocation.long
                  ) {
                    Swal.fire({
                      text: t('events.addLongLatLocationText'),
                      icon: 'error',
                      showCancelButton: false,
                      confirmButtonColor: '#7eb5f4',
                      cancelButtonColor: '#d33',
                      confirmButtonText: t('general.ok'),
                      cancelButtonText: t('general.cancel'),
                    })
                    return
                  }
                }
                props.onChange({
                  ...props.newEvent,
                  locationType: locationType,
                })
                setOpenModal(false)
                setNewLocationDone(true)
              }}
            />
          </div>
        </div>
      </ModalRegular>
      {props.value === LOCATION_TYPE.SAVED && (
        <div>
          <div className={'flex relative pt-2'}>
            <div>
              <div
                className="absolute left-4 top-[45px] z-20 bg-eventsMain h-1 transition-all duration-500 ease-in-out"
                style={{
                  width: 'calc(50%)',
                  left: saveLocationShared ? 'calc(0%)' : 'calc(50%)',
                }}
              ></div>
            </div>
            <div
              className={classNames(
                'flex-1 flex relative justify-center  z-10 items-center transition-all duration-300 ease-in-out border-b font-medium hover:bg-blue-50  h-[40px] borde-b cursor-pointer',
                saveLocationShared && '',
              )}
              onClick={() => {
                setSavedLocationShared(true)
              }}
            >
              <p
                className={classNames(
                  saveLocationShared && 'text-eventsMain font-medium',
                )}
              >
                <>{t('events.sharedPlaces')}</>
              </p>
            </div>
            <div
              className={classNames(
                'flex-1 flex justify-center items-center transition duration-200 ease-in border-b  font-medium hover:bg-blue-50 h-[40px] cursor-pointer',
                !saveLocationShared && ' border-b-eventsMain',
              )}
              onClick={() => {
                setSavedLocationShared(false)
              }}
            >
              <p
                className={classNames(
                  !saveLocationShared && 'text-eventsMain font-medium ',
                )}
              >
                <div>
                  {' '}
                  <>{t('events.myPlaces')}</>
                </div>
              </p>
            </div>
          </div>

          <div className="flex flex-col p-4 gap-y-2">
            {props.locations
              .filter((loc) => {
                if (saveLocationShared) {
                  return loc.shared && loc.shared === 'shared'
                } else {
                  return (
                    loc.shared &&
                    loc.shared === 'private' &&
                    loc.owner?.id === currentUserRole?.id
                  )
                }
              })
              .map((location) => {
                return (
                  <div
                    className={classNames(
                      'rounded-xl border p-3 flex justify-between',
                      newLocation?.id === location.id
                        ? 'border-orange-300'
                        : 'border-gray-700',
                    )}
                    onClick={() => {
                      setNewLocation(location.asJson())
                      setNewLocationDone(true)
                      /*props.onChange({
                        ...props.newEvent,
                        location: location.asJson(),
                      })*/
                    }}
                  >
                    <div className="flex flex-row w-full gap-x-2">
                      <div>
                        <FontAwesomeIcon
                          icon={faMapPin}
                          className="text-eventsMain"
                        />
                      </div>
                      <div> {location.name}</div>
                    </div>
                    <div
                      className={classNames(
                        'w-6 h-6 rounded-full border border-orange-300 justify-center flex items-center',
                        newLocation?.id === location.id ? 'bg-orange-300' : '',
                      )}
                    >
                      {newLocation?.id === location.id && (
                        <FontAwesomeIcon color="#ffffff" icon={faCheck} />
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      )}
      {newLocation && saveLocationModal && (
        <ModalRegular
          isOpen={saveLocationModal}
          onClose={() => {
            setSaveLocationModal(false)
          }}
          className="p-10"
        >
          <div className="flex flex-col gap-4">
            <div className="font-semibold">
              <>{t('general.saveLocation')}</>
            </div>
            <LocationBox
              hideOnSave={true}
              hideRemove={true}
              location={newLocation}
              onSaveLocationModal={() => setSaveLocationModal(true)}
              onRemove={() => {
                setNewLocationDone(false)
                setNewLocation({ ...newLocation, type: LOCATION_TYPE.SAVED })
                setLocationType(LOCATION_TYPE.SAVED)
                props.onChange({
                  ...props.newEvent,
                  locationType: LOCATION_TYPE.SAVED,
                })
              }}
            />
            <div className="flex justify-start items-center gap-x-2">
              <div className="mb-2 font-semibold">
                <>{t('events.sharePlace')}</>
              </div>
              <Slider
                id={0}
                onSliderClick={() => {
                  setSaveShareToSchool(!saveShareToSchool)
                }}
                onBoxClick={() => {}}
                value={saveShareToSchool}
              />
            </div>
            <div className="flex justify-between">
              <Button
                variant="borderRed"
                size="sm"
                label={t('general.cancel')}
                onClick={() => {
                  setSaveLocationModal(false)
                }}
              />
              <Button
                label={t('documents.save')}
                size="sm"
                variant="fullBlue"
                onClick={async () => {
                  if (currentUserRole) {
                    await saveLocation(
                      newLocation,
                      [currentUserRole?.school_pointer.id],
                      saveShareToSchool ? 'shared' : 'private',
                    ).finally(() => {
                      setSaveLocationModal(false)
                      if (schoolId) {
                        fetchLocations([schoolId])
                      }
                    })
                  }
                }}
              />
            </div>
          </div>
        </ModalRegular>
      )}
      {newLocationDone &&
        newLocation &&
        props.value !== LOCATION_TYPE.SAVED && (
          <LocationBox
            location={newLocation}
            onSaveLocationModal={() => setSaveLocationModal(true)}
            onRemove={() => {
              setNewLocationDone(false)
              setNewLocation({ ...newLocation, type: LOCATION_TYPE.SAVED })
              setLocationType(LOCATION_TYPE.SAVED)
              props.onChange({
                ...props.newEvent,
                locationType: LOCATION_TYPE.SAVED,
              })
            }}
          />
        )}
      <div className="mt-2 flex justify-between">
        <Button
          size="sm"
          variant={'borderRed'}
          label={t('general.cancel')}
          onClick={() => {
            props.onClose()
          }}
        />
        {newLocationDone && (
          <Button
            label={t('events.addPlace')}
            size="sm"
            variant="fullBlue"
            onClick={() => {
              props.onChange({ ...props.newEvent, location: newLocation })
              props.onClose()
            }}
          />
        )}
      </div>
    </>
  )
})
