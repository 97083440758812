import classNames from 'classnames'
import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { Event3StoreContext, IsoDate } from '../../../store/event3Store'

interface Props extends React.PropsWithChildren {
  type: 'events3'
  date?: IsoDate
}

export const EventDots: React.FC<Props> = observer(({ type, date }) => {
  if (type === 'events3') {
    const eventTypeColor = (type: string) => {
      switch (type) {
        case 'activities':
          return '#6BBD73'
        case 'meeting':
          return '#ffc27e'
        case 'info':
          return '#7eb5f4'
        case 'important':
          return '#f26d6d'
        case 'booking':
          return '#E1A7F1'
        default:
          return '#57d945'
      }
    }
    const { allEvents } = useContext(Event3StoreContext)
    const eventOnDate = allEvents?.filter((event) => event.eventDate === date)
    let typeInMap: string[] = []

    return (
      <div className="absolute bottom-2 left-0 w-full flex justify-center gap-x-[3px]">
        {eventOnDate?.map((event) => {
          if (!typeInMap.includes(event.type)) {
            typeInMap.push(event.type)
            return (
              <div
                className={classNames(
                  'w-[5px] h-[5px] bg-black rounded-full',
                  //eventTypeColor(event.type),
                )}
                style={{ backgroundColor: eventTypeColor(event.type) }}
                key={event.eventTimeId}
              ></div>
            )
          } else {
            return <React.Fragment key={event.eventTimeId}></React.Fragment>
          }
        })}
      </div>
    )
  }
  return <></>
})
