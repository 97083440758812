import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { successSwal } from '../../settings/MyConnections'

type Content =
  | string
  | {
      address: string
      zipcode: string
      city: string
    }

interface InfoCardProps {
  title: string
  content: Content
  icon: IconDefinition
}

const ContactDetailsCard: React.FC<InfoCardProps> = ({
  title,
  content,
  icon,
}) => {
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(
        typeof content === 'string'
          ? content
          : `${content.address + ' ' + content.zipcode + ' ' + content.city}`,
      )
      successSwal('copied')
    } catch (err) {
      alert('Failed to copy email!')
    }
  }
  return (
    <div
      className="flex items-center justify-between p-3 bg-white shadow rounded-3xl m-2 cursor-pointer"
      onClick={(e) => {
        e.stopPropagation()
        copyToClipboard()
      }}
    >
      <div>
        <h2 className="text-blue-600 font-semibold text-sm">{title}</h2>
        {title !== 'Address' && typeof content === 'string' && (
          <p className="text-gray-500 text-sm">{content}</p>
        )}

        {title === 'Address' && typeof content === 'object' && (
          <div className="space-y-2 text-sm">
            <p className="">{content.address}</p>
            <p className="">
              {content.zipcode} {content.city}
            </p>
          </div>
        )}
      </div>
      <div className="text-gray-400">
        <FontAwesomeIcon icon={icon} size="lg" className="text-blue-600" />
      </div>
    </div>
  )
}

export default ContactDetailsCard
