import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import CustomRadioButton from '../../../components/CustomRadioButton'
import { Button } from '../../../components/Button'
import {
  CreateEventParams,
  EVENT3_POST_TO,
  EVENT3_TYPE,
  EVENT3_USER_FOR,
} from '../../../store/event3Store'
import { useEventTypeSelections } from '../CreateEvent3'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Slider } from '../../../components/Slider'
import {
  faCalendarDay,
  faCalendarHeart,
  faCalendarLines,
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'

export enum LOCATION_TYPE {
  FREE_TEXT = 'freetext',
  COORD = 'coord',
  ADDRESS = 'address',
  SAVED = 'saved',
}

interface Props {
  newEvent: CreateEventParams
  onChange: (e: CreateEventParams) => void
  onDone: () => void // hämta värdena och visa från riktig db
  onSave: () => void // spara värdena
}

interface Props2 {
  newEvent: CreateEventParams
  onChange: (e: CreateEventParams) => void
  closeModal?: () => void
  allowMultiSchools?: boolean
  postToMultiSchoolsMode?: boolean
}

export const TypeOfEventSelections: React.FC<Props2> = (props: Props2) => {
  const eventTypeSelections = useEventTypeSelections()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isRolePlusSelected, setIsRolePlusSelected] = useState(false)
  const [extraParticipants, setExtraParticipants] = useState<string>('')
  const { t } = useTranslation()

  const handleChangeParticipants = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.stopPropagation()
    const value = event.target.value
    const parsedValue = parseInt(value, 10)

    if (!isNaN(parsedValue)) {
      setExtraParticipants(value)
      props.newEvent.maxParticipants = parsedValue
    } else {
      setExtraParticipants('')
    }
  }
  console.log(props.newEvent.eventType)

  return (
    <>
      <div className="flex flex-col rounded-2xl animate-fadeIn bg-red-30">
        <CustomRadioButton
          options={eventTypeSelections}
          onChange={(value) => {
            if (
              value === EVENT3_TYPE.MEETING ||
              value === EVENT3_TYPE.ACTIVITIES
            ) {
              props.onChange({
                ...props.newEvent,
                eventFor: undefined,
                eventType: value,
              })
            } else if (value === EVENT3_TYPE.BOOKING) {
              props.onChange({
                ...props.newEvent,
                eventType: value,
                onlyStaff: false,
                postTo: EVENT3_POST_TO.ALL,
              })
            } else {
              props.onChange({
                ...props.newEvent,
                eventType: value,
              })
            }
          }}
          value={props.newEvent.eventType}
          sliderBgColor={props.newEvent.eventType}
        />
      </div>
      {/*
        OBS. Might use this later, do not remove 

      props.newEvent.eventType === EVENT3_TYPE.BOOKING && (
        <div className="flex flex-col gap-y-2 gap-x-2 pl-2">
          <div className="flex gap-2 flex-col sm:flex-row w-fit sm:w-full ">
            <Button
              variant={
                props.newEvent.eventFor === EVENT3_USER_FOR.KID
                  ? 'fullGreenExport'
                  : 'fullWhiteDarkText'
              }
              size="sm"
              label={t('general.kids')}
              onClick={() => {
                props.onChange({
                  ...props.newEvent,
                  eventFor: EVENT3_USER_FOR.KID,
                })
                setIsRolePlusSelected(false)
              }}
            />
            <Button
              variant={
                props.newEvent.eventFor === EVENT3_USER_FOR.ROLE
                  ? 'fullGreenExport'
                  : 'fullWhiteDarkText'
              }
              size="sm"
              label={t('events.userActivity')}
              onClick={() => {
                props.onChange({
                  ...props.newEvent,
                  eventFor: EVENT3_USER_FOR.ROLE,
                })
                setIsRolePlusSelected(false)
              }}
            />
          </div>
        </div>
            )*/}
      {(props.newEvent.eventType === EVENT3_TYPE.MEETING ||
        props.newEvent.eventType === EVENT3_TYPE.ACTIVITIES) && (
        <div
          onClick={(e) => {
            e.stopPropagation()
          }}
          className={`flex flex-1 flex-col  border-l-4 my-3 ml-4 mr-2 animate-fadeIn ${
            props.newEvent.eventType === EVENT3_TYPE.MEETING
              ? 'border-[#FFC27E]'
              : 'border-[#6BBD73]'
          }`}
        >
          <div className="flex items-center gap-x-2 justify-between mb-2 ">
            <div className="flex flex-col ">
              <label className="font-bold pl-2">
                <>{t('events.demandAnswer')}</>
              </label>
              {props.newEvent.eventType === EVENT3_TYPE.ACTIVITIES && (
                <p className="pl-2 font-semibold text-xs">
                  {t('events.attendees')}
                </p>
              )}
            </div>
            <Slider
              id={
                props.newEvent.eventType === EVENT3_TYPE.MEETING
                  ? 3 /* 3 means that the bgcolor of slide should have meeting bgcolor */
                  : 1
              }
              value={props.newEvent.eventFor ? true : false}
              onSliderClick={() => {
                if (props.newEvent.eventFor) {
                  props.onChange({ ...props.newEvent, eventFor: undefined })
                } else {
                  props.onChange({
                    ...props.newEvent,
                    eventFor:
                      props.newEvent.eventType === EVENT3_TYPE.MEETING
                        ? EVENT3_USER_FOR.ROLE_PLUS
                        : EVENT3_USER_FOR.KID,
                  })
                }
              }}
              onBoxClick={() => {}}
            />
          </div>
          {props.newEvent.eventType === EVENT3_TYPE.ACTIVITIES &&
            props.newEvent.eventFor && (
              <div className="flex flex-col gap-y-2 gap-x-2 pl-2 animate-fadeIn">
                <div className="flex gap-2 flex-col sm:flex-row w-fit sm:w-full ">
                  <Button
                    variant={
                      props.newEvent.eventFor === EVENT3_USER_FOR.KID
                        ? 'fullGreenExport'
                        : 'fullWhiteGrayText'
                    }
                    size="sm"
                    label={t('general.kids')}
                    onClick={() => {
                      props.onChange({
                        ...props.newEvent,
                        eventFor: EVENT3_USER_FOR.KID,
                      })
                      setIsRolePlusSelected(false)
                    }}
                  />
                  <Button
                    variant={
                      props.newEvent.eventFor === EVENT3_USER_FOR.ROLE
                        ? 'fullGreenExport'
                        : 'fullWhiteGrayText'
                    }
                    size="sm"
                    label={t('events.userActivity')}
                    onClick={() => {
                      props.onChange({
                        ...props.newEvent,
                        eventFor: EVENT3_USER_FOR.ROLE,
                      })
                      setIsRolePlusSelected(false)
                    }}
                  />
                  <Button
                    variant={
                      props.newEvent.eventFor === EVENT3_USER_FOR.ROLE_PLUS
                        ? 'fullGreenExport'
                        : 'fullWhiteGrayText'
                    }
                    size="sm"
                    label={t('events.userPlusActivity')}
                    onClick={() => {
                      props.onChange({
                        ...props.newEvent,
                        eventFor: EVENT3_USER_FOR.ROLE_PLUS,
                      })
                      setIsRolePlusSelected(true)
                    }}
                  />
                </div>
                {props.newEvent.eventFor === EVENT3_USER_FOR.ROLE_PLUS && (
                  <div className="text-sm font-medium animate-fadeIn">
                    <>{t('events.userPlusText')}</>
                  </div>
                )}
              </div>
            )}
          {props.newEvent.eventFor && (
            <div className="p-2 animate-fadeIn">
              <div className="flex items-center">
                <p className="flex-grow pr-2 font-bold">
                  {t('events.maximumNumParticipants')}
                </p>
                <input
                  type="string"
                  value={extraParticipants ?? ''}
                  placeholder={t('events.unlimited')}
                  onChange={handleChangeParticipants}
                  className="w-44 h-12 text-md border border-gray-200 rounded text-center font-semibold placeholder:text-gray-300 focus:outline-none"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export const TypeOfEvent: React.FC<Props> = observer((props: Props) => {
  const [openSelections, setOpenSelections] = useState(false)
  const getTypeOfEvent = (type: string) => {
    switch (type) {
      case EVENT3_TYPE.INFO:
        return (
          <>
            <FontAwesomeIcon
              className="text-[#7eb5f4]"
              icon={faCalendarLines}
            />{' '}
            {t('events.information')}
          </>
        )
      case EVENT3_TYPE.IMPORTANT:
        return (
          <>
            <FontAwesomeIcon
              className="text-[#f26d6d]"
              icon={faCalendarLines}
            />{' '}
            {t('events.extraImportantInfo')}
          </>
        )
      case EVENT3_TYPE.BOOKING:
        return (
          <>
            <FontAwesomeIcon className="text-[#a5d6ea]" icon={faCalendarDay} />{' '}
            {t('events.bookAMetting')}
          </>
        )
      case EVENT3_TYPE.MEETING:
        return (
          <>
            <FontAwesomeIcon className="text-[#ffa948]" icon={faCalendarDay} />{' '}
            {t('events.groupMeeting')}
          </>
        )
      case EVENT3_TYPE.ACTIVITIES:
        return (
          <>
            <FontAwesomeIcon
              className="text-[#57d945]"
              icon={faCalendarHeart}
            />{' '}
            {t('events.activity')}
          </>
        )
      default:
        return ''
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isRolePlusSelected, setIsRolePlusSelected] = useState(false)

  return (
    <div
      className="w-full"
      onClick={() => {
        setOpenSelections(!openSelections)
      }}
    >
      <div className=" flex flex-col gap-y-2">
        <div className="flex flex-col gap-y-2">
          <p className="font-bold">
            {' '}
            <>{t('events.typeOfEvent')}:</>
          </p>
          <div className="flex flex-row gap-x-2 items-center font-medium">
            {' '}
            {getTypeOfEvent(props.newEvent.eventType)}
          </div>
        </div>
        {(props.newEvent.eventType === EVENT3_TYPE.MEETING ||
          props.newEvent.eventType === EVENT3_TYPE.ACTIVITIES) && (
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
            className="flex flex-1 flex-col"
          >
            <div className="flex items-center gap-x-2 justify-between">
              <div className="flex flex-col">
                <label className="font-semibold">
                  <>{t('events.demandAnswer')}</>
                </label>
              </div>
              <Slider
                id={0}
                value={props.newEvent.eventFor ? true : false}
                onSliderClick={() => {
                  if (props.newEvent.eventFor) {
                    props.onChange({ ...props.newEvent, eventFor: undefined })
                  } else {
                    props.onChange({
                      ...props.newEvent,
                      eventFor: EVENT3_USER_FOR.ROLE_PLUS,
                    })
                  }
                }}
                onBoxClick={() => {}}
              />
            </div>
            {props.newEvent.eventType === EVENT3_TYPE.ACTIVITIES &&
              props.newEvent.eventFor && (
                <div className="flex flex-col gap-y-2">
                  <div className="flex gap-2 flex-col sm:flex-row w-fit sm:w-full justify-between">
                    <Button
                      variant={
                        props.newEvent.eventFor === EVENT3_USER_FOR.KID
                          ? 'fullGreenExport'
                          : 'fullWhiteGrayText'
                      }
                      disabled={
                        props.newEvent.postTo === EVENT3_POST_TO.ONLY_STAFF
                          ? true
                          : false
                      }
                      size="xs"
                      label={t('general.kids')}
                      onClick={() => {
                        props.onChange({
                          ...props.newEvent,
                          eventFor: EVENT3_USER_FOR.KID,
                        })
                        setIsRolePlusSelected(false)
                      }}
                    />
                    <Button
                      variant={
                        props.newEvent.eventFor === EVENT3_USER_FOR.ROLE
                          ? 'fullGreenExport'
                          : 'fullWhiteGrayText'
                      }
                      disabled={
                        props.newEvent.postTo === EVENT3_POST_TO.ONLY_STAFF
                          ? true
                          : false
                      }
                      size="xs"
                      label={t('events.userActivity')}
                      onClick={() => {
                        props.onChange({
                          ...props.newEvent,
                          eventFor: EVENT3_USER_FOR.ROLE,
                        })
                        setIsRolePlusSelected(false)
                      }}
                    />
                    <Button
                      variant={
                        props.newEvent.eventFor === EVENT3_USER_FOR.ROLE_PLUS
                          ? 'fullGreenExport'
                          : 'fullWhiteGrayText'
                      }
                      size="xs"
                      label={t('events.userPlusActivity')}
                      onClick={() => {
                        props.onChange({
                          ...props.newEvent,
                          eventFor: EVENT3_USER_FOR.ROLE_PLUS,
                        })
                        setIsRolePlusSelected(true)
                      }}
                    />
                  </div>
                  {props.newEvent.eventFor === EVENT3_USER_FOR.ROLE_PLUS && (
                    <div className="text-sm font-medium animate-fadeIn">
                      <>{t('events.userPlusText')}</>
                    </div>
                  )}
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  )
})
