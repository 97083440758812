import {
  faEnvelope,
  faLocationDot,
  faPhone,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { IKidRelation, User } from './OverviewUsersList'
import { UserRole } from '../../../Models/UserRole'
import ContactDetailsCard from './ContactDetailsCard'
import ModalRegular from '../../../components/ModalRegular'

interface PopupProps {
  user: User | IKidRelation | undefined
  isUserContactDetailsPopUpVisible: boolean
  contact: UserRole | undefined
  onClose: () => void
}

const OverviewUserContactDetailsPopUp: React.FC<PopupProps> = observer(
  ({ contact, user, isUserContactDetailsPopUpVisible, onClose }) => {
    const popupRef = useRef<HTMLDivElement>(null)

    const handleClickOutside = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      event.stopPropagation()
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose()
      }
    }

    const phonePrimary = contact?.alternative_contact
      ? contact?.alternative_contact?.attributes?.phone_number
      : contact?.attributes?.user?.attributes?.user_extra?.attributes
          ?.primary_phone
    const phoneSecondary =
      contact?.attributes?.user?.attributes?.user_extra?.attributes
        ?.secondary_phone
    const email =
      contact?.attributes?.user?.attributes?.user_extra?.attributes?.email

    const address = contact?.alternative_contact
      ? contact?.alternative_contact?.attributes?.address
      : contact?.attributes?.user?.attributes?.user_extra?.attributes
          ?.street_address
    const zipcode = contact?.alternative_contact
      ? contact?.alternative_contact?.attributes?.zip_code
      : contact?.attributes?.user?.attributes?.user_extra?.attributes?.zip_code
    const city = contact?.alternative_contact
      ? contact?.alternative_contact?.attributes?.city
      : contact?.attributes?.user?.attributes?.user_extra?.attributes?.city

    const addressDetails = { address, zipcode, city }

    return (
      <ModalRegular
        isOpen={isUserContactDetailsPopUpVisible!!}
        /* bgColor="bg-eventsMain p-4" */
        closeColor="white"
        className={'h-[400px] !w-[450px] px-4 overflow-hidden'}
        classNameTitle="mb-4 w-full text-black !important flex justify-center font-semibold"
        onClose={onClose}
      >
        <div className="flex flex-col items-center">
          <img
            className={`w-16 h-16 rounded-full object-cover `}
            src={user?.thumbnail || '/images/placeholder-image.png'}
            alt=""
          />
          <p className=" text-gray-900 font-bold mt-2">{user?.name}</p>
        </div>

        <div className="flex flex-col p-4 h-full overflow-y-auto">
          <ContactDetailsCard
            title="Phone"
            content={phonePrimary}
            icon={faPhone}
          />

          {phoneSecondary && (
            <ContactDetailsCard
              title="Phone"
              content={phoneSecondary}
              icon={faPhone}
            />
          )}

          <ContactDetailsCard title="Email" content={email} icon={faEnvelope} />
          <ContactDetailsCard
            title="Address"
            content={addressDetails}
            icon={faLocationDot}
          />
        </div>
      </ModalRegular>
    )
  },
)

export default OverviewUserContactDetailsPopUp
