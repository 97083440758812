import {
  faFilePdf,
  faImage,
  faTrash,
  faX,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../components/Button'
import { faFilePlus } from '@fortawesome/pro-solid-svg-icons'
import ModalRegular from '../../../components/ModalRegular'
import { CreateEventParams } from '../../../store/event3Store'
import Swal from 'sweetalert2'

export const AddFileModalNew: React.FC<{
  newEvent: CreateEventParams
  onChange: (e: CreateEventParams) => void
}> = ({ newEvent, onChange }) => {
  const { t } = useTranslation()

  useEffect(() => {}, [newEvent])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false)
  const [myFiles, setMyFiles] = useState([] as any)
  const onDrop = (files: any) => {
    if (
      files.find(
        (file: any) =>
          file.type.includes('image/png') ||
          file.type.includes('image/jpeg') ||
          //!file.type.includes('audio/mp4') ||
          file.type.includes('application/pdf'),
      )
    ) {
    } else {
      Swal.fire({
        text: t('general.wrongFileFormat'),
        icon: 'warning',
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#7eb5f4',
        cancelButtonColor: '#d33',
        confirmButtonText: t('general.ok'),
      })
    }
    files = files.filter(
      (file: any) =>
        file.type.includes('image/png') ||
        file.type.includes('image/jpeg') ||
        //file.type.includes('audio/mp4') ||
        file.type.includes('application/pdf'),
    )
    // eslint-disable-next-line array-callback-return
    files.map((file: any) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        setMyFiles(
          files.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              base64Image: e?.target?.result,
            }),
          ),
        )
      }
      reader.readAsDataURL(file)
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    /*
    //TODO: ACCEPT ONLY CERTAIN file types
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'application/msword': ['.doc'],
      'text/plain': ['.txt'],
    },*/
  })

  const files = myFiles.map((file: any, i: number) => {
    const div: any = []
    div.push(
      <div
        key={file?.path}
        onClick={() => {
          const newFiles = [...myFiles]
          newFiles.splice(newFiles.indexOf(file), 1)
          setMyFiles(newFiles)
        }}
      >
        {file.path}
        <FontAwesomeIcon
          icon={faTrash}
          className={'pl-2 text-xs cursor-pointer'}
        />
      </div>,
    )
    return div
  })

  const [showModal, setShowModal] = useState(false)

  return (
    <div className="w-full" onClick={(e) => e.stopPropagation()}>
      <div className="flex flex-col gap-y-2 w-full">
        <div className="font-semibold flex items-center justify-between">
          <div className="font-semibold flex items-center gap-x-1">
            <FontAwesomeIcon
              icon={faFilePlus}
              className="text-blue-300 text-xl p-1"
            />
            <>{t('documents.files') + ':'}</>
          </div>
          <Button
            label={t('events.add')}
            variant="remindStyle"
            size="xs"
            onClick={() => {
              setShowModal(true)
            }}
          />
        </div>

        <div
          className={`flex w-full gap-4 pl-2 overflow-x-auto ${
            newEvent.files?.length!! > 0 && 'h-[80px]'
          } items-center`}
        >
          {newEvent.files?.length ? (
            newEvent.files?.map((file: any) => {
              return (
                <div className="relative ">
                  <div className="absolute -top-2 -right-2 to z-10 scale-75">
                    <Button
                      variant="fullRed"
                      size="xs2"
                      icon={faX}
                      onClick={(e) => {
                        e.stopPropagation()
                        onChange({
                          ...newEvent,
                          files:
                            newEvent.files?.filter(
                              (f: any) => f.base64Image !== file.base64Image,
                            ) ?? [],
                        })
                      }}
                    />
                  </div>
                  <a
                    href={file.preview}
                    target="_blank"
                    className="relative"
                    rel="noreferrer"
                  >
                    {file.type?.startsWith('image/') ||
                    file.mime_type?.startsWith('image/') ? (
                      <div className="w-16 h-16  shrink-0">
                        <img
                          src={file.preview || file.file}
                          alt="Preview"
                          className="rounded object-cover w-full h-full"
                        />
                      </div>
                    ) : (
                      <>
                        <div
                          className={`flex flex-col border-2 justify-center items-center rounded-md h-16 w-16 mx-1  border-red-300 cursor-pointer text-red-500 p-1`}
                        >
                          <FontAwesomeIcon
                            size="xl"
                            icon={
                              file.type === 'application/pdf'
                                ? faFilePdf
                                : faImage
                            }
                          />
                          <p className="text-2xs text-center w-[95%] line-clamp-2 leading-tight mt-1">
                            {file.name}
                          </p>
                        </div>
                      </>
                    )}
                  </a>
                </div>
              )
            })
          ) : (
            <>{t('events.noFilesSelected')}</>
          )}
        </div>
      </div>
      <ModalRegular
        //title={t('documents.saveFile')}
        isOpen={showModal}
        //bgColor="bg-eventsMain p-4"
        hideCloseButton
        closeColor="white"
        className={'max-w-2xl'}
        onClose={() => {
          setShowModal(false)
        }}
      >
        <div className={'p-5 pt-7'}>
          <div className="container mt-6">
            <div
              {...getRootProps({
                className:
                  'dropzone flex flex-row p-10 item-center justify-center bg-gray-50 border rounded-lg ',
              })}
            >
              <input {...getInputProps()} />
              <p className={' text-center mr-1'}>
                {t('documents.dragAndDropTitle')}
              </p>{' '}
              <p className=" text-center cursor-pointer hover:text-blue-500 text-blue-300">
                {t('documents.chooseFileDrop')}
              </p>
            </div>
            <div className={'mt-6 mb-6'}>
              <ul>{files}</ul>
            </div>

            <div></div>

            <div className={'flex w-full justify-between mt-3'}>
              <Button
                label={t('general.cancel')}
                variant="danger"
                onClick={(e) => {
                  e.stopPropagation()
                  setShowModal(false)
                }}
              />
              <Button
                loading={loading}
                label={t('documents.saveFile')}
                variant="primary"
                disabled={myFiles?.length === 0}
                size="md"
                onClick={async (e) => {
                  e.stopPropagation()
                  /*setLoading(true)*/
                  const files: any = [...(newEvent.files ?? [])]
                  for (const file of myFiles) {
                    files.push(file)
                  }

                  onChange({
                    ...newEvent,
                    files: files,
                  })
                  setShowModal(false)
                  setMyFiles([])

                  /* const file = myFiles[0]
                    const parseFile = new Parse.File('tyrafile', file)
                    const f = await parseFile.save()
  
                    await addFile({
                      file: f,
                      type: type,
                      name: name,
                      kidId: kidId,
                      depId: depId,
                      withParent: withParent,
                      sign: checked,
                      mimeType: myFiles[0]?.type,
                      userRoleId: savedUserRoleId ?? '',
                      folderId: folderId,
                      sendPush: false,
                    }).finally(() => {
                      setLoading(false)
                      setMyFiles([])
                      setName('')
                    })
                    setShowModal(false)
                    onFileAdded()*/
                }}
              />
            </div>
          </div>
        </div>
      </ModalRegular>
    </div>
  )
}
