import React, { useContext, useEffect, useState } from 'react'
import { t } from 'i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisVertical,
  faPen,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import ModalRegular from '../../components/ModalRegular'
import { Category } from '../../components/CategoryBox'
import CategoryBox from '../../components/CategoryBox'
import Swal from 'sweetalert2'
import { Button } from '../../components/Button'
import { ButtonDropdown } from '../../components/Dropdown'
import { BoxStoreContext } from '../../store/boxStore'
import { Kid } from '../../Models/Kid'
import { Loading } from '../../components/Loading'
import { CustomPresetBox } from './components/CustomPresetBox'
import { PresetBox } from './components/PresetBox'
import {
  faPeoplePants,
  faShoePrints,
  faSocks,
  faTshirt,
  faUserTie,
} from '@fortawesome/pro-solid-svg-icons'
import { UserStoreContext } from '../../store/userStore'

interface BoxProps {
  isOpen: boolean
  onClose: () => void
  kidId: string
}

const Box: React.FC<BoxProps> = ({ isOpen, onClose, kidId }) => {
  const { saveCategory, deleteCategory, getBoxItems, boxItems } =
    useContext(BoxStoreContext)
  const [selectedButton, setSelectedButton] = useState(null)
  const [loading, setLoading] = useState(false)
  const handleClick = (Button: any) => {
    setSelectedButton((prevState) => {
      if (prevState === Button) {
        return null
      } else {
        return Button
      }
    })
  }
  const { readOnly } = useContext(UserStoreContext)
  const [sliderValue, setSliderValue] = useState(1)

  useEffect(() => {
    getBoxItems(kidId)
  }, [kidId])

  const handleSliderClick = (newValue: any) => {
    setSliderValue(newValue)
    if (newValue === 0) {
      handleClick('slut')
    } else if (newValue === 1) {
      handleClick('fa')
    } else {
      handleClick('manga')
    }
  }
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }
  const [categories, setCategories] = useState<Category[]>([])
  const [inputValue, setInputValue] = useState('')
  const addCategory = async () => {
    if (inputValue.trim() === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: t('box.warningCategory'),
      })
      return
    }

    const newCategory = {
      key: Date.now(),
      title: inputValue,
    }

    setLoading(true)
    try {
      await saveCategory(newCategory, kidId)
      await getBoxItems(kidId)
    } catch (error) {
      console.error('Error adding category:', error)
    } finally {
      setLoading(false)
      setInputValue('')
    }
  }

  const removeCategory = async (boxId: string) => {
    try {
      setLoading(true)
      await deleteCategory(boxId)
      await getBoxItems(kidId)
    } catch (error) {
      console.error('Error removing category:', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: t('box.warningDeleteCategory'),
      })
    } finally {
      setLoading(false)
    }
  }

  const presets = [
    { id: 1, name: 'Socks', icon: faSocks },
    { id: 2, name: 'Shirts', icon: faTshirt },
    { id: 3, name: 'Pants', icon: faPeoplePants },
    { id: 4, name: 'Trousers', icon: faUserTie },
    { id: 5, name: 'Shoes', icon: faShoePrints },
  ]

  const handlePresetClick = (preset: any) => {
    setInputValue(preset.name)
  }

  const handleCustomImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader()
      reader.onload = (event) => {
        setCustomImageSrc(event.target!.result as string)
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const [customImageSrc, setCustomImageSrc] = useState<string | null>(null)
  const [customInputValue, setCustomInputValue] = useState('')
  return (
    <ModalRegular
      isOpen={isOpen}
      onClose={onClose}
      bgColor="bg-boxMain p-4 flex justify-center"
      title={t('menu.box')}
      closeColor="white"
    >
      {loading && <Loading />}
      <div className="flex flex-wrap w-full h-auto  p-4 justify-between gap-y-4 ">
        {!boxItems || boxItems.length < 1 ? (
          <div className="w-full text-center text-gray-500">
            <>{t('documents.empty')}</>
          </div>
        ) : (
          boxItems &&
          boxItems.map((boxItem) => (
            <CategoryBox
              key={boxItem.id}
              boxItem={boxItem}
              removeCategory={removeCategory}
              onSave={() => getBoxItems(kidId)}
            />
          ))
        )}

        {/*Presets & CustomPresets*/}
        {/* <div className="flex gap-4 mb-4">
          {presets.map((preset, index) => (
            <PresetBox
              key={preset.id}
              icon={preset.icon}
              onClick={() => handlePresetClick(preset)}
            />
          ))}
          <CustomPresetBox
            imageSrc={customImageSrc}
            inputValue={inputValue}
            setInputValue={setInputValue}
            customInputValue={customInputValue}
            setCustomInputValue={setCustomInputValue}
            onClick={() => {
              setInputValue('')
            }}
            onImageChange={(e) => handleCustomImageChange(e)}
          />
        </div>
          */}
        {!readOnly?.box && (
          <div className="w-full h-full flex flex-shrink items-center">
            <input
              className="border w-full rounded-2xl text-xs sm:text-sm m-2 pl-4 h-9 focus:outline-none focus:ring-eventsMain focus:border-eventsMain"
              placeholder={t('box.writeNewCategory')}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value)
              }}
            />

            <div className="min-w-[128px] flex items-end justify-end">
              {' '}
              <Button
                size="sm"
                label={t('box.addCategory')}
                onClick={() => addCategory()}
              />
            </div>
          </div>
        )}
      </div>
    </ModalRegular>
  )
}

export default Box
