import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import moment from 'moment'
import { title } from 'process'
import React, { useState } from 'react'
import { Button } from '../../../components/Button'

interface Props extends React.PropsWithChildren {
  onClick?: () => void
  active?: boolean
  label?: string
  value?: string
}

export const StepButton: React.FC<Props> = ({
  children,
  onClick,
  active,
  label,
  value,
}) => {
  return (
    <div
      className={classNames(
        'flex justify-between p-4 w-full text-sm  items-center rounded-2xl bg-white shadow-sm ',
        active ? ' border-l-[6px] border-blue-400' : '',
      )}
    >
      {children}
      <div className="flex flex-row">
        <b>{label}</b> {value}
      </div>
    </div>
  )
}
