import { FC, useState } from 'react'

type SliderProps = {
  id: number
  sliderBgColor?: string
  onSliderClick: (id: number) => void
  onBoxClick: (id: number) => void
  value?: boolean
  disabled?: boolean
}

export const Slider: FC<SliderProps> = ({
  id,
  sliderBgColor,
  onSliderClick,
  onBoxClick,
  value = false,
  disabled = false,
}) => {
  const handleSliderClick = () => {
    if (!disabled) {
      onSliderClick(id)
    }
  }

  const handleBoxClick = () => {
    if (!disabled) {
      onBoxClick(id)
    }
  }

  const sliderStyle = {
    opacity: disabled ? 0.6 : 1,
    cursor: disabled ? 'not-allowed' : 'pointer',
    '--button-bg-color': sliderBgColor as React.CSSProperties,
  }
  const sliderClasses = `slider-two ${disabled ? 'slider-disabled' : ''}`
  return (
    <div>
      <div className="" onClick={handleBoxClick} style={sliderStyle}></div>
      <div data-value={id} onClick={handleSliderClick} style={sliderStyle}>
        <div
          className={` ${id === 3 ? 'slider-two-meeting' : 'slider-two'}`}
          data-value={value ? '2' : '1'}
          style={sliderStyle}
        ></div>
      </div>
    </div>
  )
}
