import { useContext, useState } from 'react'
import { UserStoreContext } from '../../../store/userStore'
import { EventListItemData } from '../Events3'
import { EVENT3_TYPE, Event3StoreContext } from '../../../store/event3Store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCopy,
  faEyeSlash,
  faPen,
  faTrash,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../../components/Button'
import { useTranslation } from 'react-i18next'
import EditEventPopUp from './EditEventPopUp'
import { ButtonDropdown } from '../../../components/Dropdown'
import { observer } from 'mobx-react'

export const SettingsDetailView: React.FC<{
  setOpenSettings: (value: boolean) => void
  openSttings: boolean
  fetchedEventDetail?: EventListItemData
  handleExpaneded: () => void
}> = observer(
  ({ setOpenSettings, openSttings, fetchedEventDetail, handleExpaneded }) => {
    const { currentUserRole } = useContext(UserStoreContext)
    const { deleteEvent, setEventsAsUnRead } = useContext(Event3StoreContext)
    const [deleteConfirmation, setDeletConfirmation] = useState<boolean>(false)
    const [deleteRecurring, setDeleteRecurring] = useState<boolean>(false)
    const [editMode, setEditEventMode] = useState<boolean>(false)
    const { t } = useTranslation()

    const toggleSettings = () => {
      setOpenSettings(!openSttings)
    }

    const toggleEditMode = () => {
      setEditEventMode(!editMode)
    }
    const handleDeletReccuring = () => {
      setDeleteRecurring(!deleteRecurring)
    }

    const handleDeleteEvent = () => {
      setDeletConfirmation(!deleteConfirmation)
    }

    return (
      <>
        {currentUserRole?.role_type && currentUserRole?.role_type > 350 ? (
          <div
            className={`absolute right-9 top-6  mx-3 origin-top divide-y  w-[200px] ${
              fetchedEventDetail?.times[0].end_time && 'top-9'
            }`}
            style={{
              display: openSttings ? 'block' : 'none',
            }}
          >
            <ButtonDropdown.Menu>
              <ButtonDropdown.Section>
                <>
                  <ButtonDropdown.Button
                    icon={faPen}
                    label={t('blog.edit')}
                    className="text-blue-300"
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleEditMode()
                    }}
                  />
                  {fetchedEventDetail?.type !== EVENT3_TYPE.BOOKING && (
                    <>
                      <ButtonDropdown.Button
                        icon={faCopy}
                        label={t('documents.copy')}
                        className="text-green-300"
                      />

                      <ButtonDropdown.Button
                        icon={faEyeSlash}
                        label="Set as unread"
                        className="text-purple-300"
                        onClick={(e) => {
                          e.stopPropagation()
                          setEventsAsUnRead(
                            fetchedEventDetail?.eventGroupInfoId,
                          )
                          handleExpaneded()
                        }}
                      />
                      <ButtonDropdown.Button
                        icon={faTrash}
                        label={t('absence.delete')}
                        className="text-red-300"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleDeleteEvent()
                        }}
                      />
                    </>
                  )}
                </>
              </ButtonDropdown.Section>
            </ButtonDropdown.Menu>
          </div>
        ) : (
          <div
            className="absolute right-9 top-5  z-50 mx-3 origin-top divide-y  w-[200px]"
            style={{
              display: openSttings ? 'block' : 'none',
            }}
          >
            <ButtonDropdown.Menu>
              <ButtonDropdown.Section>
                <ButtonDropdown.Button
                  icon={faEyeSlash}
                  label="Set as unread"
                  className="text-purple-300"
                  onClick={(e) => {
                    e.stopPropagation()
                    setEventsAsUnRead(fetchedEventDetail?.eventGroupInfoId)
                  }}
                />
              </ButtonDropdown.Section>
            </ButtonDropdown.Menu>
          </div>
        )}
        {deleteConfirmation && (
          <div
            className="w-full h-[290px] flex items-center  justify-center"
            onClick={(e) => {
              e.stopPropagation()
              setDeletConfirmation(!deleteConfirmation)
            }}
          >
            <div className="bg-white h-[60%] my-auto w-3/5 h-5/5 p-2 rounded-lg shadow-lg sm:h-3/ sm:w-1/2 z-50">
              <div className="flex justify-end">
                <FontAwesomeIcon
                  icon={faXmark}
                  size="lg"
                  className="text-gray-600 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation()
                    setDeletConfirmation(!deleteConfirmation)
                  }}
                />
              </div>
              <div className="flex flex-col">
                <p className="flex justify-center items-center py-1 font-bold">
                  {t('events.deleteEvent')}?
                </p>
                {fetchedEventDetail?.isRecurring && (
                  <label className="flex items-center space-x-2 justify-center">
                    <span className="font-semibold py-2">
                      {t('events.deleteAllRecurring')}
                    </span>
                    <input
                      type="checkbox"
                      checked={deleteRecurring}
                      onChange={handleDeletReccuring}
                      onClick={(e) => e.stopPropagation()}
                      className="form-checkbox h-4 w-4 text-indigo-600"
                    />
                  </label>
                )}
                <div className="flex justify-center items-end pt-5 gap-4">
                  <Button
                    size="sm"
                    variant="borderRed"
                    label={t('general.cancel')}
                    onClick={async (e) => {
                      e.stopPropagation()
                      setDeletConfirmation(!deleteConfirmation)
                    }}
                  />
                  <Button
                    size="sm"
                    variant="borderGreen"
                    label={t('general.yes') + '  👍'}
                    onClick={async (e) => {
                      e.stopPropagation()
                      deleteEvent(
                        fetchedEventDetail?.eventTimeId,
                        fetchedEventDetail?.eventGroupInfoId,
                        deleteRecurring,
                      )
                      handleExpaneded()
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {editMode && fetchedEventDetail && (
          <EditEventPopUp
            fetchedEventDetail={fetchedEventDetail}
            toggleEditMode={toggleEditMode}
          />
        )}
      </>
    )
  },
)
